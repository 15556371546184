/* eslint-disable @typescript-eslint/naming-convention */
import { Box, Modal } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { useGetInvoiceTemplate } from 'api/queries/invoice';
import { useCallback, useEffect, useState } from 'react';
import { useAppStore } from 'store/store';
import { Invoice } from 'utils/invoice';
import { UpdateButton } from './update-button';
import { Registration } from 'api/models/registration';
import { RegistrationService } from 'api/service/registration-service';

interface PreviewModalProps {
  form: UseFormReturnType<any, (values: any) => any>;
  registration?: Registration;
  opened: boolean;
  toggle: () => void;
}

export function PreviewModal({ form, registration, opened, toggle }: PreviewModalProps) {
  const { camp } = useAppStore();
  const { data: template } = useGetInvoiceTemplate();
  const [preview, setPreview] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const toggleIsLoading = () => setIsLoading((l) => !l);

  const updateFunction = useCallback(async () => {
    if (camp && registration) {
      return RegistrationService.updateRegistrationWithMappedProduct(camp, registration, form);
    }
  }, [camp, registration, form]);

  useEffect(() => {
    if (camp && registration && template && opened) {
      const newPreview = new Invoice(camp, registration, template, camp.concentrations, form, undefined, camp.closedOn);
      newPreview.preview = newPreview.preview.replaceAll('<td>', '<td style="white-space: pre-line">');
      setPreview(newPreview.preview);
    }
  }, [template, registration, camp, form, opened]);

  return (
    <Modal size="calc(100vw - 15rem)" opened={opened} withCloseButton onClose={toggle}>
      <div id="preview" dangerouslySetInnerHTML={{ __html: preview }} />
      <Box mt={20} display="flex" sx={{ justifyContent: 'flex-end' }}>
        {registration?.billingInfo && (
          <Box mr={10}>
            <UpdateButton
              updateFunction={updateFunction}
              isLoading={isLoading}
              toggleLoading={toggleIsLoading}
              form={form}
              camp={camp}
              registration={registration}
              close={toggle}
              withSendInvoice
              color="blue"
            />
          </Box>
        )}
        <UpdateButton updateFunction={updateFunction} isLoading={isLoading} toggleLoading={toggleIsLoading} form={form} camp={camp} registration={registration} close={toggle} />
      </Box>
    </Modal>
  );
}
