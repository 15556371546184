import React, { ReactNode } from 'react';
import { ActionIcon, Box, Header as MantineHeader, Text } from '@mantine/core';
import { BrandFacebook, BrandInstagram, PhoneCall, Mail } from 'tabler-icons-react';
import { useTranslation } from 'react-i18next';
import { useHeaderStyles } from './header.styles';
import { useMediaQuery } from '@mantine/hooks';

interface HeaderProps {
  phones: string[];
  emails: string[];

  facebook: string;
  instagram: string;

  height: any;
  children?: ReactNode;
}

export function Header({ phones, emails, facebook, instagram, height, children }: HeaderProps) {
  const { t } = useTranslation();
  const { classes } = useHeaderStyles();
  const matches = useMediaQuery('(min-width: 764px)');

  return (
    <MantineHeader className={classes.container} height={height}>
      <Box className={classes.box}>
        <Box className={classes.infoBox}>
          {children && <Box mr={15}>{children}</Box>}
          <Box display="flex" sx={{ alignItems: 'center' }}>
            {matches && (
              <>
                <Text weight={600}>{t('Common.Phone')}</Text>
                <Box ml={20} display="flex" sx={{ flexFlow: 'column' }}>
                  {phones.map((x) => (
                    <Text size="xs" className={classes.link} onClick={() => window.open(`tel:${x}`)}>
                      {x}
                    </Text>
                  ))}
                </Box>
              </>
            )}
          </Box>
          <Box ml={20} display="flex" sx={{ alignItems: 'center' }}>
            {matches && (
              <>
                <Text weight={600}>{t('Common.Email')}</Text>
                <Box ml={20} display="flex" sx={{ flexFlow: 'column' }}>
                  {emails.map((x) => (
                    <Text size="xs" className={classes.link} onClick={() => window.open(`mailto:${x}`)}>
                      {x}
                    </Text>
                  ))}
                </Box>
              </>
            )}
          </Box>
        </Box>

        <Box className={classes.socials}>
          <Box mr={20}>
            <ActionIcon onClick={() => window.open(facebook, '_blank')}>
              <BrandFacebook size={30} />
            </ActionIcon>
          </Box>
          <Box>
            <ActionIcon onClick={() => window.open(instagram, '_blank')}>
              <BrandInstagram size={30} />
            </ActionIcon>
          </Box>
        </Box>
      </Box>
    </MantineHeader>
  );
}
