import { ParentDto } from 'api/dto';
import { ParentTitle } from 'component/registration-step/types';

export interface IParent {
  id?: string;
  title: ParentTitle;
  firstName: string;
  lastName: string;

  cellphone: string;
  phone?: string;

  address: string;
  email: string;
}

export class Parent {
  id?: string;
  title: ParentTitle;
  firstName: string;
  lastName: string;

  cellphone: string;
  phone?: string;

  address: string;
  email: string;

  constructor(parent: ParentDto) {
    if (!parent) {
      this.id = '';
      this.title = ParentTitle.father;
      this.firstName = '';
      this.lastName = '';
      this.cellphone = '';
      this.phone = '';
      this.address = '';
      this.email = '';
      return;
    }

    this.id = parent.id;
    this.title = parent.title;
    this.firstName = parent.firstName;
    this.lastName = parent.lastName;
    this.cellphone = parent.cellphone;
    this.phone = parent.phone;
    this.address = parent.address;
    this.email = parent.email;
  }
}
