import { Box, Button, Group, Text, Select, ActionIcon, LoadingOverlay } from '@mantine/core';
import { Calendar } from '@mantine/dates';
import { StepLayout } from 'layout/step.layout';
import { useFormStyles } from './form.styles';
import { useEffect, useMemo, useState } from 'react';
import { CustomColumnSizing, Table } from 'component/table/table';
import { ColumnDef } from '@tanstack/react-table';
import { UseFormReturnType } from '@mantine/form';
import { Child } from './types';
import { DaycareDto, Period } from 'api/dto/daycare';
import dayjs from 'dayjs';
import { X } from 'tabler-icons-react';
import { useGetDaycareDays } from 'api/queries/daycare';
import { addFamily } from 'api/queries';
import axios from 'axios';
import { environment } from 'config/environment';
import { DateTime, DateTimeFormat } from 'utils/datetime';
import { useTranslation } from 'react-i18next';
import { DaycareRegistration } from 'api/dto/daycare-registration';
import { useNavigate } from 'react-router-dom';
import { showNotification } from '@mantine/notifications';
import maxBy from 'lodash/maxBy';
import { deleteEmptyStringProperties } from 'api/utils/object';

const baseColumns: (ColumnDef<{ [key: string]: any }, string | number | unknown> & CustomColumnSizing)[] = [
  {
    id: 'id',
    accessorKey: 'id',
  },
  {
    id: 'date',
    accessorKey: 'date',
    header: 'Date',
    width: '20%',
    cell: (r) => DateTime.fromISOString(r.row.original.date, true).toFormat(DateTimeFormat.Human),
  },
];

export function DaycareCart({ form }: { form: UseFormReturnType<any, (values: any) => any> }) {
  const { t } = useTranslation();
  const { classes } = useFormStyles();
  const navigate = useNavigate();
  const [date, setDate] = useState<DaycareDto | undefined>(undefined);
  const [selectedDays, setSelectedDays] = useState<any[]>([]);
  const children: Child[] = useMemo(() => form.values.family.children, [form]);
  const { data: days, isLoading } = useGetDaycareDays();
  const [loadingOverlayVisible, setLoadingOverlayVisible] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const childrenKeys = useMemo(() => children.map((child: Child) => child.firstName + child.lastName), [children]);

  const removeRow = (id: string) => {
    setSelectedDays((old) => old.filter((d) => d.id !== id));
  };

  const columns = useMemo(() => {
    const array = [...baseColumns];

    children.forEach((child: Child) => {
      array.push({
        id: child.firstName + child.lastName,
        accessorKey: child.firstName + child.lastName,
        header: child.firstName + ' ' + child.lastName,
        enableSorting: false,
        cell: (r) => (
          <Select
            w="200px"
            value={r.row.original[child.firstName + child.lastName]}
            onChange={(e) => {
              setSelectedDays((old) => {
                const index = old.findIndex((d) => d.id === r.row.original.id);
                const newData = [...old];
                newData[index][child.firstName + child.lastName] = e;
                return newData;
              });
            }}
            data={[{ label: t('DaycareRegistration.Period.NONE'), value: null }, ...r.row.original.periods.map((p: Period) => ({ label: t(`DaycareRegistration.Period.${p}`), value: p }))]}
          />
        ),
      });
    });

    array.push({
      id: 'action',
      width: '200px',
      accessorKey: 'period',
      header: 'Action',
      enableSorting: false,
      cell: (r) => (
        <ActionIcon size="sm" onClick={() => removeRow(r.row.original.id)}>
          <X />
        </ActionIcon>
      ),
    });

    return array;
  }, [children, t]);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    setLoadingOverlayVisible(false);
  }, [isLoading]);

  const minDate = dayjs().utc(true).toDate();
  const maxDateString = maxBy(days, (x) => x.date)?.date;
  const maxDate = maxDateString ? dayjs(maxDateString).add(1, 'day').toDate() : undefined;

  return (
    <StepLayout>
      <LoadingOverlay visible={loadingOverlayVisible} />
      <Box className={classes.boxContainer}>
        <Group mt={20} position="center">
          <Text size="lg">Journées disponibles pour le service de garde</Text>
        </Group>

        <Group position="center">
          <Calendar
            mt={40}
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
            }}
            minDate={minDate}
            maxDate={maxDate}
            size="md"
            value={date ? DateTime.fromISOString(date.date, true).toUTCDate() : undefined}
            amountOfMonths={3}
            onChange={(e) => {
              const isoDate = dayjs(e).utc(true).toISOString();
              const dayDto = days?.find((d) => d.date === isoDate);
              setDate(dayDto);
            }}
            excludeDate={(d) => {
              const utcDay = dayjs(d).utc(true).toDate();
              const isExcluded = selectedDays.map((d) => d.date).includes(utcDay.toISOString());
              if (isExcluded) {
                return true;
              }

              const includedDays = days?.map((d) => d.date) || [];
              return !includedDays.includes(utcDay.toISOString());
            }}
          />
        </Group>

        <Group mt={20} position="right">
          <Button
            disabled={!date}
            onClick={() => {
              setSelectedDays((old) => [...old, { ...date, ...childrenKeys }]);
              setDate(undefined);
            }}
          >
            Ajouter une journée
          </Button>
        </Group>
      </Box>
      <Box className={classes.boxContainer}>
        <Table<any> hiddenColumns={{ id: false }} columns={columns} data={selectedDays} searchable={false} defaultSort={{ id: 'date', desc: false }} hidePagination />
      </Box>
      <Group position="right">
        <Button
          loading={isSubmitting}
          disabled={!selectedDays.length}
          ml={10}
          variant="outline"
          size="sm"
          color="green"
          onClick={async () => {
            setLoadingOverlayVisible(true);
            setIsSubmitting(true);
            try {
              const { family, taxCreditInfos } = form.values;
              const addedFamily = await addFamily(family);
              if (!addedFamily.id) {
                setLoadingOverlayVisible(false);
                setIsSubmitting(false);
                return;
              }

              const registration = new DaycareRegistration(addedFamily, taxCreditInfos, selectedDays);
              deleteEmptyStringProperties(registration);
              await axios.post(environment.restApi.resourceUrl.daycare + '/registrations', registration);
              navigate('/daycare-registration/success');
            } catch (error) {
              showNotification({
                title: 'Ooops!',
                // eslint-disable-next-line quotes
                message: "Une erreur s'est produite. Veuillez recommencer ou contacter le support",
                color: 'red',
                sx: {
                  padding: 10,
                  paddingLeft: 20,
                },
              });
            }
            setIsSubmitting(false);
            setLoadingOverlayVisible(false);
          }}
        >
          Envoyer l'inscription
        </Button>
      </Group>
    </StepLayout>
  );
}
