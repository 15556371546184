import { ActionIcon, Box, Button, Checkbox, MultiSelect, Radio, Select, SimpleGrid, Text, Textarea, TextInput } from '@mantine/core';
import { StepLayout } from 'layout/step.layout';
import { useTranslation } from 'react-i18next';
import {
  LegalAuthorizationInfosProps,
  ChildInfoProps,
  Drugs,
  FullInfoFormStep,
  ParentInfoProps,
  TermsAndConditionsProps,
  EmergencyContactsProps,
  ParentTitle,
  LinkWithChildren,
  EnglishLevel,
  Gender,
} from './types';
import { DatePicker } from '@mantine/dates';
import { UserPlus as Plus, X } from 'tabler-icons-react';
import { useMemo } from 'react';
import { useFormStyles } from './form.styles';
import { LabelWithAsterisk } from 'component/label/labelWithAsterisk';
import { PhoneInput } from 'component/input/phone-input';
import { withProtocol } from 'utils/url';
import { nanoid } from 'nanoid';

interface FullInformationProps {
  form: FullInfoFormStep;
}

export function DaycareFullInformation({ form }: FullInformationProps) {
  const { t } = useTranslation();
  const { classes } = useFormStyles();

  const parents = useMemo(
    () =>
      form.values.family.parents.map((_, index) => (
        <ParentInfo key={`parent${index}`} getInputProps={form.getInputProps} parentIndex={index} withRemove={index !== 0} remove={() => form.removeListItem('family.parents', index)} />
      )),
    [form]
  );

  const children = useMemo(
    () =>
      form.values.family.children.map((child, index) => (
        <ChildInfo
          key={`child${index}`}
          set={form.setFieldValue}
          withRemove={form.values.family.children.length > 1}
          child={child}
          remove={() => form.removeListItem('family.children', index)}
          form={form as any}
          childIndex={index}
        />
      )),
    [form]
  );

  const emergencyContact = useMemo(
    () =>
      form.values.family.emergencyContacts.map((_, index) => <EmergencyContacts key={`emergency-contact-${index}`} count={children.length} getInputProps={form.getInputProps} contactIndex={index} />),
    [children, form]
  );

  return (
    <StepLayout>
      <>
        {parents.length != 2 && (
          <Button disabled={parents.length > 1} leftIcon={<Plus size={20} />} mb={20} variant="outline" color="pink" onClick={() => form.insertListItem('family.parents', {})}>
            {t('parent:AddParent')}
          </Button>
        )}
        <Text mb={20} size="lg">
          {t('parent:Infos.GuardianInformations')}
        </Text>
        <SimpleGrid
          mb={20}
          breakpoints={[
            { minWidth: 991, cols: parents.length > 1 ? 2 : 1, spacing: 'xl' },
            { maxWidth: 990, cols: 1, spacing: 'xs' },
          ]}
        >
          {parents}
        </SimpleGrid>

        <Text mb={20} size="lg">
          {t('child:Infos.ChildrenInfos')}
        </Text>
        <Button
          disabled={children.length > 3}
          leftIcon={<Plus size={20} />}
          mb={20}
          variant="outline"
          color="pink"
          onClick={() => form.insertListItem('family.children', { tempId: nanoid(), gender: Gender.GENDER_NEUTRAL })}
        >
          {t('child:AddChild')}
        </Button>

        <SimpleGrid
          breakpoints={[
            { minWidth: 991, cols: children.length > 1 ? 2 : 1, spacing: 'xl' },
            { maxWidth: 990, cols: 1, spacing: 'xs' },
          ]}
        >
          {children}
        </SimpleGrid>

        <Text mb={20} size="lg">
          {t('parent:LegalAuthorizations')}
        </Text>

        <AuthorizationInfos
          set={form.setFieldValue}
          add={form.insertListItem}
          remove={form.removeListItem}
          count={children.length}
          legalAuthorizations={form.values.family.legalAuthorizations}
          getInputProps={form.getInputProps}
        />

        <Text sx={{ display: 'flex' }} mb={10} size="lg">
          {t('parent:EmergencyContacts')}
          <Text ml={10} color="red">
            {t('parent:EmergencyContactsOther')}
          </Text>
        </Text>

        <Text mb={20} size="sm" italic color="gray">
          {t('parent:EmergencyExplanations')}
        </Text>

        <SimpleGrid
          cols={2}
          mb={20}
          breakpoints={[
            { maxWidth: 2000, cols: 2, spacing: 'xl' },
            { maxWidth: 990, cols: 1, spacing: 'xs' },
          ]}
        >
          {emergencyContact}
        </SimpleGrid>

        <Text mb={20} size="lg">
          {t('parent:TermsAndConditions')}
        </Text>

        <TermsAndConditions terms={form.values.family.termsAndConditions} count={children.length} getInputProps={form.getInputProps} />

        <Text mb={20} size="lg">
          {t('parent:Comments')}
        </Text>

        <Box className={classes.boxContainer}>
          <Textarea label={t('parent:SendComments')} minRows={5} {...form.getInputProps('family.comments')} />
        </Box>
      </>
    </StepLayout>
  );
}

function ParentInfo({ getInputProps, parentIndex, withRemove, remove }: ParentInfoProps) {
  const { t } = useTranslation();
  const { classes } = useFormStyles();
  return (
    <Box
      className={classes.boxContainer}
      sx={{
        position: 'relative',
      }}
    >
      {withRemove && (
        <ActionIcon
          sx={{
            position: 'absolute',
            top: 5,
            right: 10,
          }}
          onClick={remove}
        >
          <X size={20} />
        </ActionIcon>
      )}
      <Radio.Group mb={10} label={t('parent:Infos.Title')} required {...getInputProps(`family.parents.${parentIndex}.title`)}>
        <Radio value={ParentTitle.mother} label={t('parent:Type.Mother')} />
        <Radio value={ParentTitle.father} label={t('parent:Type.Father')} />
        <Radio value={ParentTitle.tutor} label={t('parent:Type.Tutor')} />
      </Radio.Group>
      <SimpleGrid mb={10} cols={2}>
        <TextInput required label={t('Infos.FirstName')} {...getInputProps(`family.parents.${parentIndex}.firstName`)} />
        <TextInput required label={t('Infos.LastName')} {...getInputProps(`family.parents.${parentIndex}.lastName`)} />
      </SimpleGrid>

      <SimpleGrid mb={10} cols={2}>
        <PhoneInput required label={t('parent:Infos.Cellphone')} {...getInputProps(`family.parents.${parentIndex}.cellphone`)} />
        <PhoneInput label={t('parent:Infos.Phone')} {...getInputProps(`family.parents.${parentIndex}.phone`)} />
      </SimpleGrid>

      <Textarea minRows={3} maxRows={5} label={t('parent:Infos.Address')} withAsterisk {...getInputProps(`family.parents.${parentIndex}.address`)} />
      <TextInput required type="email" mt={10} label={t('parent:Infos.Email')} {...getInputProps(`family.parents.${parentIndex}.email`)} />
    </Box>
  );
}

function ChildInfo({ child, set, remove, withRemove, form, childIndex }: ChildInfoProps) {
  const { t } = useTranslation();
  const { classes } = useFormStyles();

  return (
    <Box
      sx={{
        position: 'relative',
      }}
      className={classes.boxContainer}
      pt={30}
    >
      {withRemove && (
        <ActionIcon
          sx={{
            position: 'absolute',
            top: 5,
            right: 10,
          }}
          onClick={remove}
        >
          <X size={20} />
        </ActionIcon>
      )}

      <SimpleGrid mb={10} cols={2}>
        <TextInput
          required
          label={t('Infos.FirstName')}
          {...form.getInputProps(`family.children.${childIndex}.firstName`)}
          onChange={(e) => {
            const name = e.target.value;
            if (name) {
              set(`family.children.${childIndex}.firstName`, name);
            }
          }}
        />
        <TextInput
          required
          label={t('Infos.LastName')}
          {...form.getInputProps(`family.children.${childIndex}.lastName`)}
          onChange={(e) => {
            const name = e.target.value;
            if (name) {
              set(`family.children.${childIndex}.lastName`, name);
            }
          }}
        />
      </SimpleGrid>

      <SimpleGrid mb={10} cols={2}>
        <DatePicker
          withAsterisk
          maxDate={new Date()}
          label={t('child:Infos.BirthDate')}
          {...form.getInputProps(`family.children.${childIndex}.birthDate`)}
          onChange={(e) => {
            set(`family.children.${childIndex}.birthDate`, e?.toISOString());
          }}
        />
      </SimpleGrid>

      <SimpleGrid mb={10} cols={2}>
        <TextInput required label={t('child:Infos.HealthInsuranceNumber')} {...form.getInputProps(`family.children.${childIndex}.healthInsuranceNumber`)} />
        <DatePicker
          withAsterisk
          label={t('child:Infos.HealthInsuranceExpiryDate')}
          {...form.getInputProps(`family.children.${childIndex}.healthInsuranceExpiryDate`)}
          onChange={(e) => set(`family.children.${childIndex}.healthInsuranceExpiryDate`, e?.toISOString())}
        />
      </SimpleGrid>

      <Radio.Group mb={10} label={t('child:Infos.EnglishLevel.Detail')} required {...form.getInputProps(`family.children.${childIndex}.englishLevel`)}>
        <Radio value={EnglishLevel.BEGINNER} label={t('child:Infos.EnglishLevel.Beginner')} />
        <Radio value={EnglishLevel.BEGINNER_INTERMEDIATE} label={t('child:Infos.EnglishLevel.Beginner-Intermediate')} />
        <Radio value={EnglishLevel.INTERMEDIATE} label={t('child:Infos.EnglishLevel.Intermediate')} />
        <Radio value={EnglishLevel.INTERMEDIATE_ADVANCED} label={t('child:Infos.EnglishLevel.Intermediate-Advanced')} />
        <Radio value={EnglishLevel.ADVANCED} label={t('child:Infos.EnglishLevel.Advanced')} />
      </Radio.Group>

      <Radio.Group
        mb={10}
        label={t('child:Infos.HasAllergies')}
        required
        {...form.getInputProps(`family.children.${childIndex}.hasAllergies`)}
        value={child.hasAllergies === undefined ? undefined : child.hasAllergies ? 'true' : 'false'}
        onChange={(e) => set(`family.children.${childIndex}.hasAllergies`, e === 'true' ? true : false)}
      >
        <Radio value="true" label={t('Common.Yes')} />
        <Radio value="false" label={t('Common.No')} />
      </Radio.Group>

      {child.hasAllergies && <Textarea mb={10} withAsterisk minRows={3} label={t('child:Infos.Which_female')} {...form.getInputProps(`family.children.${childIndex}.allergiesExplanations`)} />}

      {child.hasAllergies && (
        <Radio.Group
          mb={10}
          label={t('child:Infos.Epipen')}
          required
          {...form.getInputProps(`family.children.${childIndex}.hasEpipen`)}
          value={child.hasEpipen === undefined ? undefined : child.hasEpipen ? 'true' : 'false'}
          onChange={(e) => set(`family.children.${childIndex}.hasEpipen`, e === 'true' ? true : false)}
        >
          <Radio value="true" label={t('Common.Yes')} />
          <Radio value="false" label={t('Common.No')} />
        </Radio.Group>
      )}

      {child.hasEpipen && (
        <Radio.Group
          mb={10}
          label={t('child:Infos.EpipenAuthorization')}
          required
          {...form.getInputProps(`family.children.${childIndex}.epipenAuthorization`)}
          value={child.epipenAuthorization === undefined ? undefined : child.epipenAuthorization ? 'true' : 'false'}
          onChange={(e) => set(`family.children.${childIndex}.epipenAuthorization`, e === 'true' ? true : false)}
        >
          <Radio value="true" label={t('Common.Yes')} />
          <Radio value="false" label={t('Common.No')} />
        </Radio.Group>
      )}

      <Radio.Group
        mb={10}
        label={t('child:Infos.Drugs')}
        required
        {...form.getInputProps(`family.children.${childIndex}.takesDrugs`)}
        value={child.takesDrugs === undefined ? undefined : child.takesDrugs ? 'true' : 'false'}
        onChange={(e) => set(`family.children.${childIndex}.takesDrugs`, e === 'true' ? true : false)}
      >
        <Radio value="true" label={t('Common.Yes')} />
        <Radio value="false" label={t('Common.No')} />
      </Radio.Group>

      {child.takesDrugs && <Textarea withAsterisk minRows={3} label={t('child:Infos.Which_male')} {...form.getInputProps(`family.children.${childIndex}.drugsExplanations`)} />}

      <Radio.Group
        mb={10}
        label={t('child:Infos.RestrictedActivities')}
        required
        {...form.getInputProps(`family.children.${childIndex}.hasRestrictedConcentrations`)}
        value={child.hasRestrictedConcentrations === undefined ? undefined : child.hasRestrictedConcentrations ? 'true' : 'false'}
        onChange={(e) => set(`family.children.${childIndex}.hasRestrictedConcentrations`, e === 'true' ? true : false)}
      >
        <Radio value="true" label={t('Common.Yes')} />
        <Radio value="false" label={t('Common.No')} />
      </Radio.Group>

      {child.hasRestrictedConcentrations && (
        <Textarea mb={10} withAsterisk minRows={3} label={t('child:Infos.Which_female')} {...form.getInputProps(`family.children.${childIndex}.restrictedConcentrationsExplanations`)} />
      )}

      <Radio.Group
        mb={10}
        label={t('child:Infos.Plan')}
        required
        {...form.getInputProps(`family.children.${childIndex}.hasSpecificDevelopmentPlan`)}
        value={child.hasSpecificDevelopmentPlan === undefined ? undefined : child.hasSpecificDevelopmentPlan ? 'true' : 'false'}
        onChange={(e) => set(`family.children.${childIndex}.hasSpecificDevelopmentPlan`, e === 'true' ? true : false)}
      >
        <Radio value="true" label={t('Common.Yes')} />
        <Radio value="false" label={t('Common.No')} />
      </Radio.Group>

      {child.hasSpecificDevelopmentPlan && (
        <Textarea mb={10} withAsterisk minRows={3} label={t('child:Infos.Details')} {...form.getInputProps(`family.children.${childIndex}.specificDevelopmentPlanDetails`)} />
      )}
    </Box>
  );
}

function AuthorizationInfos({ legalAuthorizations, set, add, remove, count, getInputProps }: LegalAuthorizationInfosProps) {
  const { t } = useTranslation();
  const { classes } = useFormStyles();

  const drugs = useMemo(() => Object.values(Drugs).map((d) => ({ label: t(`parent:Drugs.Type.${d}`), value: d })), [t]);

  return (
    <Box className={classes.boxContainer}>
      {legalAuthorizations.selectedDrugs && legalAuthorizations.selectedDrugs.includes(Drugs.other) && (
        <Textarea mb={10} withAsterisk minRows={3} label={t('parent:Drugs.SpecifyOthers')} {...getInputProps('family.legalAuthorizations.otherDrugs')} />
      )}

      <Radio.Group
        mb={10}
        label={t('parent:Authorization.ReturnHomeAlone', { count })}
        required
        {...getInputProps('family.legalAuthorizations.canReturnHomeAlone')}
        value={legalAuthorizations.canReturnHomeAlone === undefined ? undefined : legalAuthorizations.canReturnHomeAlone ? 'true' : 'false'}
        onChange={(e) => set('family.legalAuthorizations.canReturnHomeAlone', e === 'true' ? true : false)}
      >
        <Radio value="true" label={t('Common.Yes')} />
        <Radio value="false" label={t('Common.No')} />
      </Radio.Group>

      <Box mb={20}>
        <Text mb={10} size="sm">
          {t('parent:Authorization.PeopleAuthorized', { count: legalAuthorizations.authorizedPeople.length })}
        </Text>
        <Button mb={10} size="xs" onClick={() => add('family.legalAuthorizations.authorizedPeople', {})} disabled={legalAuthorizations.authorizedPeople.length >= 2}>
          {t('parent:Authorization.AddPeople')}
        </Button>

        {legalAuthorizations.authorizedPeople?.map((a, index) => (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <ActionIcon mr={10} onClick={() => remove('family.legalAuthorizations.authorizedPeople', index)}>
              <X size={20} />
            </ActionIcon>
            <SimpleGrid mb={10} cols={3}>
              <TextInput required label={t('Infos.FirstName')} {...getInputProps(`family.legalAuthorizations.authorizedPeople.${index}.firstName`)} />
              <TextInput required label={t('Infos.LastName')} {...getInputProps(`family.legalAuthorizations.authorizedPeople.${index}.lastName`)} />
              <Select
                required
                label={t('contact:Infos.LinkWithChildren', { count })}
                data={[
                  { label: t('parent:Link.GrandParent')!, value: LinkWithChildren.GRAND_PARENT },
                  { label: t('parent:Link.StepParent')!, value: LinkWithChildren.STEP_PARENT },
                  { label: t('parent:Link.UncleAunt')!, value: LinkWithChildren.UNCLE_AUNT },
                  { label: t('parent:Link.Sibling')!, value: LinkWithChildren.SIBLING },
                  { label: t('parent:Link.Friend')!, value: LinkWithChildren.FRIEND },
                  { label: t('parent:Link.Other')!, value: LinkWithChildren.OTHER },
                ]}
                {...getInputProps(`family.legalAuthorizations.authorizedPeople.${index}.link`)}
              />
            </SimpleGrid>
          </Box>
        ))}
      </Box>

      <Box mb={30}>
        <Text mb={10} size="sm">
          {t('parent:Authorization.PeopleUnauthorized', { count: legalAuthorizations.unauthorizedPeople.length })}
        </Text>
        <Button mb={10} size="xs" onClick={() => add('family.legalAuthorizations.unauthorizedPeople', {})} disabled={legalAuthorizations.unauthorizedPeople.length >= 2}>
          {t('parent:Authorization.AddPeople')}
        </Button>

        {legalAuthorizations.unauthorizedPeople?.map((a, index) => (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <ActionIcon mr={10} onClick={() => remove('family.legalAuthorizations.unauthorizedPeople', index)}>
              <X size={20} />
            </ActionIcon>
            <SimpleGrid cols={2}>
              <TextInput required label={t('Infos.FirstName')} {...getInputProps(`family.legalAuthorizations.unauthorizedPeople.${index}.firstName`)} />
              <TextInput required label={t('Infos.LastName')} {...getInputProps(`family.legalAuthorizations.unauthorizedPeople.${index}.lastName`)} />
            </SimpleGrid>
          </Box>
        ))}
      </Box>

      <Radio.Group
        mb={10}
        label={t('parent:Authorization.SharePhoto', { count })}
        required
        {...getInputProps('family.legalAuthorizations.isAuthorizedToSharePhotos')}
        value={legalAuthorizations.isAuthorizedToSharePhotos === undefined ? undefined : legalAuthorizations.isAuthorizedToSharePhotos ? 'true' : 'false'}
        onChange={(e) => set('family.legalAuthorizations.isAuthorizedToSharePhotos', e === 'true' ? true : false)}
      >
        <Radio value="true" label={t('Common.Accept')} />
        <Radio value="false" label={t('Common.Deny')} />
      </Radio.Group>
    </Box>
  );
}

function TermsAndConditions({ getInputProps, terms, count }: TermsAndConditionsProps) {
  const { t } = useTranslation();

  const { classes } = useFormStyles();

  return (
    <Box className={classes.boxContainer}>
      <Checkbox
        mb={10}
        label={<LabelWithAsterisk>{t('parent:Authorization.FirstCare', { count })} </LabelWithAsterisk>}
        {...getInputProps('family.termsAndConditions.isAuthorizedToAdministerFirstCare')}
        checked={terms.isAuthorizedToAdministerFirstCare}
      />
      <Checkbox
        mb={10}
        label={<LabelWithAsterisk>{t('parent:Authorization.Collaboration', { count })} </LabelWithAsterisk>}
        {...getInputProps('family.termsAndConditions.hasToCollaborateWithManagement')}
        checked={terms.hasToCollaborateWithManagement}
      />
      <Checkbox
        mb={10}
        label={
          <LabelWithAsterisk className={classes.terms}>
            <Text>{t('parent:Authorization.TermsAndConditions')}</Text>
            <Text
              ml={5}
              underline
              sx={{
                color: 'blue',
                cursor: 'pointer',
              }}
              onClick={() => window.open(`${withProtocol(window.location.host)}/#/terms-and-conditions`, '_blank')}
            >
              {t('parent:Authorization.TermsAndConditionsLink')}
            </Text>
          </LabelWithAsterisk>
        }
        {...getInputProps('family.termsAndConditions.hasReadAndAcceptedTermsAndConditions')}
        checked={terms.hasReadAndAcceptedTermsAndConditions}
      />
      <Checkbox
        mb={10}
        label={<LabelWithAsterisk>{t('parent:Authorization.AllInfos')}</LabelWithAsterisk>}
        {...getInputProps('family.termsAndConditions.allInfosAreTruthful')}
        checked={terms.allInfosAreTruthful}
      />
    </Box>
  );
}

function EmergencyContacts({ getInputProps, count, contactIndex }: EmergencyContactsProps) {
  const { t } = useTranslation();

  const { classes } = useFormStyles();

  return (
    <Box className={classes.boxContainer}>
      <SimpleGrid mb={10} cols={2}>
        <TextInput required label={t('Infos.FirstName')} {...getInputProps(`family.emergencyContacts.${contactIndex}.firstName`)} />
        <TextInput required label={t('Infos.LastName')} {...getInputProps(`family.emergencyContacts.${contactIndex}.lastName`)} />
      </SimpleGrid>

      <Select
        required
        mb={20}
        label={t('contact:Infos.LinkWithChildren', { count })}
        data={[
          { label: t('parent:Link.GrandParent')!, value: LinkWithChildren.GRAND_PARENT },
          { label: t('parent:Link.StepParent')!, value: LinkWithChildren.STEP_PARENT },
          { label: t('parent:Link.UncleAunt')!, value: LinkWithChildren.UNCLE_AUNT },
          { label: t('parent:Link.Sibling')!, value: LinkWithChildren.SIBLING },
          { label: t('parent:Link.Friend')!, value: LinkWithChildren.FRIEND },
          { label: t('parent:Link.Other')!, value: LinkWithChildren.OTHER },
        ]}
        {...getInputProps(`family.emergencyContacts.${contactIndex}.link`)}
      />
      <SimpleGrid mb={10} cols={2}>
        <PhoneInput required label={t('contact:Infos.Cellphone')} {...getInputProps(`family.emergencyContacts.${contactIndex}.cellphone`)} />
        <PhoneInput label={t('contact:Infos.Phone')} {...getInputProps(`family.emergencyContacts.${contactIndex}.phone`)} />
      </SimpleGrid>
    </Box>
  );
}
