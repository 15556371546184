import { AuthClass, Auth } from '@aws-amplify/auth/lib-esm/Auth';
import { AuthOptions } from '@aws-amplify/auth/lib-esm/types';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Logger } from '@aws-amplify/core';

const amplify: { Auth: AuthClass | null; isConfigured: boolean } = { Auth: null, isConfigured: false };

export interface CognitoInfos {
  CognitoUserPoolId: string;
  CognitoAppClientID: string;
  CognitoIdentityPoolID: string;
}

export const configureAmplify = (cognitoInfos: CognitoInfos, awsConfig: AuthOptions) => {
  if (!cognitoInfos) {
    return;
  }

  if (amplify.isConfigured) {
    return amplify.Auth!;
  }

  awsConfig.userPoolId = cognitoInfos.CognitoUserPoolId;
  awsConfig.userPoolWebClientId = cognitoInfos.CognitoAppClientID;
  // uncomment to have every logs from amplify log into the console
  // Logger.LOG_LEVEL = 'DEBUG';

  Auth.configure(awsConfig);

  amplify.Auth = Auth;
  amplify.isConfigured = true;

  return Auth;
};
