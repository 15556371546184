import ReactGA from 'react-ga4';
import { environment } from 'config/environment';

const init = () => {
  ReactGA.initialize(environment.googleAnalyticsTrackingCode);
};

export default {
  init,
};
