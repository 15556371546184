import { RegistrationDto } from 'api/dto';
import { ChildrenChosenProduct } from './children-chosen-product';
import { Payment } from './payment';
import { TaxCreditInfo } from './tax-credit-info';
import { BillingInfo } from './billing-info';
import { Child } from './child';

export class Registration {
  id: string;
  familyId: string;
  campId: string;
  childrenChosenProducts: ChildrenChosenProduct[];
  totalAmount: number;
  payments: Payment[];
  taxCreditInfos: TaxCreditInfo[];
  billingInfo?: BillingInfo;
  registeredAt?: string;

  constructor(registration: RegistrationDto, children: Child[]) {
    this.id = registration.id;
    this.familyId = registration.familyId;
    this.campId = registration.campId;
    this.childrenChosenProducts = registration.childrenChosenProducts.map((c) => new ChildrenChosenProduct(c, children));
    this.totalAmount = registration.totalAmount;
    this.payments = registration.payments.map((p) => new Payment(p));
    this.taxCreditInfos = registration.taxCreditInfos.map((t) => new TaxCreditInfo(t));
    this.billingInfo = registration.billingInfo ? new BillingInfo(registration.billingInfo) : undefined;
    this.registeredAt = registration.registeredAt;
  }

  calculateCustomPayments() {
    return this.payments.filter((p) => p.isCustomPayment).reduce((prev, acc) => acc.amount + prev, 0) || 0;
  }

  calculateManagementFees(fees: number) {
    const paymentsWithManagementFees = this.payments.filter((p) => p.hasManagementFees).length;
    return paymentsWithManagementFees * fees;
  }
}
