/* eslint-disable @typescript-eslint/require-await */
/* eslint-disable no-console */
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

const isDev = process.env.NODE_ENV === 'development';

export const requestInterceptor = async (request: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
  if (isDev) {
    const { url, data, method, params } = request;
    console.log({ requestUrl: url, data, requestMethod: method, params });
  }

  return {
    ...request,
  };
};

export const responseInterceptor = async (response: AxiosResponse): Promise<AxiosResponse> => {
  if (isDev) {
    console.log({ responseData: response.data });
  }
  return {
    ...response,
  };
};

const logErrorForDev = (error: AxiosError): void => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    const { data, status, headers } = error.response;
    console.log({ data, status, headers });
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.log({ requestError: error.request });
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log('Error', error.message);
  }
  console.log(error.config);
};

export const errorInterceptor = (error: AxiosError): Promise<void> => {
  if (isDev) {
    logErrorForDev(error);
  }
  return Promise.reject(error);
};
