import { UseFormReturnType } from '@mantine/form';
import { GetInputProps, SetFieldValue } from '@mantine/form/lib/types';
import { PeopleDto } from 'api/dto/authorization';
import { TaxCreditDto } from 'api/dto/tax-credit-info';
import { ChildCareTime } from 'api/enum/child-care-time';

export enum Steps {
  infos = 0,
  schedule = 1,
  menu = 2,
  tax = 3,
  payment = 4,
}

export enum City {
  victoriaville = 'victoriaville',
  drummondville = 'drummondville',
}

export enum ParentTitle {
  mother = 'MOTHER',
  father = 'FATHER',
  tutor = 'TUTOR',
}

export enum LinkWithChildren {
  GRAND_PARENT = 'GRAND_PARENT',
  STEP_PARENT = 'STEP_PARENT',
  UNCLE_AUNT = 'UNCLE_AUNT',
  SIBLING = 'SIBLING',
  FRIEND = 'FRIEND',
  OTHER = 'OTHER',
}

export enum EnglishLevel {
  BEGINNER = 'BEGINNER',
  BEGINNER_INTERMEDIATE = 'BEGINNER_INTERMEDIATE',
  INTERMEDIATE = 'INTERMEDIATE',
  INTERMEDIATE_ADVANCED = 'INTERMEDIATE_ADVANCED',
  ADVANCED = 'ADVANCED',
}

export enum Gender {
  GIRL = 'GIRL',
  BOY = 'BOY',
  GENDER_NEUTRAL = 'GENDER_NEUTRAL',
}

export interface Family {
  parents: Parent[];
  children: Child[];
  legalAuthorizations: LegalAuthorization;
  termsAndConditions: TermsAndConditions;
  emergencyContacts: EmergencyContact[];
}

export interface CompleteForm {
  camp: string;
  family: Family;
  schedule: {
    items: Product[];
  };
  menu: Menu[];
  taxCreditInfos: TaxCreditDto[];
  modifications?: Record<string, any>;
}

export type FullInfoFormStep = UseFormReturnType<
  {
    camp: string;
    family: Family;
  },
  (values: { camp: string; family: Family }) => {
    camp: string;
    family: Family;
  }
>;

export type CartFormStep = UseFormReturnType<
  {
    schedule: {
      items: Product[];
    };
    family: {
      children: Child[];
    };
    menu: Menu[];
    taxCreditInfos: TaxCreditDto[];
  },
  (values: {
    schedule: {
      items: Product[];
    };
    family: {
      children: Child[];
    };
    taxCreditInfos: TaxCreditDto[];
  }) => {
    schedule: {
      items: Product[];
    };
    family: {
      children: Child[];
    };
    taxCreditInfos: TaxCreditDto[];
  }
>;

export interface Menu {
  productId: string;
  date: string;
  mainCourse: string;
  child: Child;
}

export type MenuFormStep = UseFormReturnType<
  {
    menu: Menu[];
    family: {
      children: Child[];
    };
    schedule: Record<string, unknown>;
  },
  (values: {
    menu: Menu[];
    family: {
      children: Child[];
    };
    schedule: Record<string, unknown>;
  }) => {
    menu: Menu[];
    schedule: Record<string, unknown>;
    family: {
      children: Child[];
    };
  }
>;

export interface ParentInfoProps {
  parentIndex: number;
  getInputProps: GetInputProps<{
    parents: Parent[];
  }>;
  withRemove: boolean;
  remove: () => void;
}

export interface Parent {
  title: ParentTitle;
  firstName: string;
  lastName: string;

  cellphone: string;
  phone?: string;

  address: string;
  email: string;
}

export interface ChildInfoProps {
  child: Child;
  set: SetFieldValue<{
    camp: string;
    family: {
      parents: Parent[];
      children: Child[];
      legalAuthorizations: LegalAuthorization;
      termsAndConditions: TermsAndConditions;
      emergencyContacts: EmergencyContact[];
    };
  }>;
  withRemove: boolean;
  remove: () => void;
  childIndex: number;
  form: UseFormReturnType<
    {
      schedule: {
        items: Product[];
      };
      family: {
        children: Child[];
      };
    },
    (values: {
      schedule: {
        items: Product[];
      };
      family: {
        children: Child[];
      };
    }) => {
      schedule: {
        items: Product[];
      };
      family: {
        children: Child[];
      };
    }
  >;
}

export interface Child {
  id?: string;
  tempId?: string;
  firstName: string;
  lastName: string;

  birthDate: string;
  age: number;

  healthInsuranceNumber: string;
  healthInsuranceExpiryDate: string;

  englishLevel: EnglishLevel;
  gender: Gender;

  hasAllergies: boolean;
  allergiesExplanations?: string;

  hasEpipen?: boolean;

  takesDrugs: boolean;
  drugsExplanations?: string;

  hasRestrictedConcentrations: boolean;
  restrictedConcentrationsExplanations?: string;

  hasSpecificDevelopmentPlan: boolean;
  specificDevelopmentPlanDetails?: string;

  epipenAuthorization?: boolean;
}

export interface LegalAuthorizationInfosProps {
  legalAuthorizations: LegalAuthorization;
  count: number;
  getInputProps: GetInputProps<{
    legalAuthorizations: LegalAuthorization;
  }>;
  add: (list: string, item: any) => void;
  remove: (list: string, index: number) => void;
  set: SetFieldValue<{
    camp: string;
    family: Family;
  }>;
}

export interface TermsAndConditionsProps {
  count: number;
  terms: TermsAndConditions;
  getInputProps: GetInputProps<{
    termsAndConditions: TermsAndConditions;
  }>;
}

export interface EmergencyContactsProps {
  contactIndex: number;
  count: number;
  getInputProps: GetInputProps<{
    emergencyContacts: EmergencyContact[];
  }>;
}

export interface TaxCreditInfo {
  firstName: string;
  lastName: string;
  socialNumber: string;
  email: string;
  address: string;
  city: string;
  province: string;
  postalCode: string;
  cellphone: string;
  phone?: string;
}

export type TaxCreditStep = UseFormReturnType<
  {
    taxCreditInfos: TaxCreditInfo[];
  },
  (values: { taxCreditInfos: TaxCreditInfo[] }) => {
    taxCreditInfos: TaxCreditInfo[];
  }
>;

export interface LegalAuthorization {
  canAdministerDrugs: boolean;
  selectedDrugs?: string[];
  otherDrugs?: string;

  hasChildcare: boolean;
  childcareTime?: ChildCareTime;

  canReturnHomeAlone?: boolean;

  authorizedPeople: PeopleDto[];
  unauthorizedPeople: PeopleDto[];

  isAuthorizedToSharePhotos?: boolean;
}

export interface TermsAndConditions {
  needsToCommunicateModificationsHealth: boolean;
  isAuthorizedToAdministerFirstCare: boolean;
  hasToCollaborateWithManagement: boolean;
  hasReadAndAcceptedTermsAndConditions: boolean;
  allInfosAreTruthful: boolean;
}

export enum Drugs {
  acetaminophen = 'acetaminophen',
  antihistamine = 'antihistamine',
  coughSyrup = 'coughSyrup',
  antiemetic = 'antiemetic',
  antiInflammatory = 'antiInflammatory',
  antibioticCream = 'antibioticCream',
  other = 'other',
}

export enum ProductType {
  WEEKLY = 'WEEKLY',
  ALL_SUMMER = 'ALL_SUMMER',
  PART_TIME = 'PART_TIME',
}

export enum ProductExtra {
  GLUTEN_FREE = 'glutenFree',
  VEGETARIAN = 'vegetarian',
}

export interface Concentration {
  id: string;
  title: string;
  price: number;
}

export interface WeekRange {
  tempId: string;
  days: string[];
  concentrationId?: string;
}

export interface Product {
  id: string;
  type: ProductType;
  child: Child;
  nameEn: string;
  nameFr: string;
  pricePerDay: number;
  weeksRange?: WeekRange[];
  subtotal?: number;
  extras?: Partial<Record<ProductExtra, number>>;
}

export interface ChosenProduct {
  id: string;
  weeksRange: WeekRange[];
}

export interface EmergencyContact {
  id?: string;
  familyId?: string;
  firstName: string;
  lastName: string;
  link: LinkWithChildren;
  cellphone: string;
  phone?: string;
}

export interface Difference {
  id: string;
  amount: number;
}

export type InvoiceCart = UseFormReturnType<
  {
    schedule: {
      items: Product[];
    };
    family: {
      children: (Child & { id: string })[];
    };
    menu: Menu[];
    modifications: {
      note?: string;
      amount: number;
      hasManagementFees?: boolean;
    };
  },
  (values: {
    schedule: {
      items: Product[];
    };
    family: {
      children: (Child & { id: string })[];
    };
    menu: Menu[];
    modifications: {
      note?: string;
      amount: number;
    };
  }) => {
    schedule: {
      items: Product[];
    };
    menu: Menu[];
    family: {
      children: (Child & { id: string })[];
    };
    modifications: {
      note?: string;
      amount: number;
    };
  }
>;
