import { encryptData } from 'utils/crypto';
import { Period } from './daycare';
import { DaycareChildrenChosenDay } from './daycare-children-chosen-day';
import { TaxCreditDto } from './tax-credit-info';
import { Family } from 'api/models/family';

export interface IDaycareRegistration {
  familyId: string;
  childrenChosenDays: DaycareChildrenChosenDay[];
}

export class DaycareRegistration implements IDaycareRegistration {
  familyId: string;
  taxCreditInfos: TaxCreditDto[];
  childrenChosenDays: DaycareChildrenChosenDay[];

  constructor(family: Family, taxCreditInfos: TaxCreditDto[], selectedDays: Record<string, unknown>[]) {
    this.familyId = family.id!;
    this.childrenChosenDays = [];

    const childrenIds: Record<string, string> = {};

    for (const child of family.children) {
      const id = `${child.firstName}${child.lastName}`;
      childrenIds[id] = child.id;
    }

    Object.entries(childrenIds).forEach(([id, childId]) => {
      const childrenChosenDays: DaycareChildrenChosenDay = {
        childId,
        chosenDays: [],
      };
      selectedDays.forEach((day) => {
        if (day[id]) {
          const date = day.date as string;
          childrenChosenDays.chosenDays.push({
            period: day[id] as Period,
            date: date.substring(0, date.indexOf('T')),
          });
        }
      });

      this.childrenChosenDays.push(childrenChosenDays);
    });

    for (const taxCreditInfo of taxCreditInfos) {
      taxCreditInfo.socialNumber = encryptData(taxCreditInfo.socialNumber);
    }
    this.taxCreditInfos = taxCreditInfos;
  }
}
