import { ProductDto, ProductType } from 'api/dto';
import { getWeek, toDateWithoutTimezone } from 'utils/date';

export interface IWeek {
  startDate: string;
  endDate: string;
  days: string[];
  concentrationId?: string;
}

export class Week {
  startDate: string;
  endDate: string;
  days: string[];
  concentrationId?: string;

  constructor(week: IWeek) {
    this.startDate = week.startDate;
    this.endDate = week.endDate;
    this.days = week.days;
    this.concentrationId = week.concentrationId;
  }

  isFullWeek(startDate: string, endDate: string, closedOn?: string[]): boolean {
    return this.days.length === getWeek(toDateWithoutTimezone(this.days[0]), startDate, endDate, closedOn).days.length;
  }

  toDto(): IWeek {
    return {
      startDate: this.startDate,
      endDate: this.endDate,
      days: this.days,
      concentrationId: this.concentrationId,
    };
  }
}

export interface IProduct {
  id: string;
  campId: string;
  nameEn: string;
  nameFr: string;
  pricePerDay: number;
  type: ProductType;
}

export class Product {
  id: string;
  campId: string;
  nameEn: string;
  nameFr: string;
  pricePerDay: number;
  type: ProductType;

  constructor(product: ProductDto) {
    this.id = product.id;
    this.campId = product.campId;
    this.nameEn = product.nameEn;
    this.nameFr = product.nameFr;
    this.pricePerDay = product.pricePerDay;
    this.type = product.type;
  }

  get isFullTimeProduct(): boolean {
    return this.type === ProductType.WEEKLY;
  }

  get isPartTimeProduct(): boolean {
    return this.type === ProductType.PART_TIME;
  }
}
