import { Box } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { ReactNode } from 'react';

interface StepLayoutProps {
  children: ReactNode;
}

export function StepLayout({ children }: StepLayoutProps) {
  const matches = useMediaQuery('(min-width: 764px)');

  return (
    <Box
      sx={
        matches
          ? {
              marginLeft: '5%',
              marginRight: '5%',
            }
          : {}
      }
      mt={40}
    >
      {children}
    </Box>
  );
}
