import { ChildrenChosenProductDto } from 'api/dto';
import { Child } from './child';

export interface IChildrenChosenProduct {
  childId: string;
  chosenProduct: ChosenProduct;
  menuOverrides?: MenuOverride[];
  extras?: Record<string, number>;
}

export type ChosenProduct = {
  productId: string;
  weeksRange: WeekRange[];
};

export type WeekRange = {
  days: string[];
  concentrationId?: string;
};

export type MenuOverride = {
  date: string;
  mainCourse: string;
};

export class ChildrenChosenProduct {
  childId: string;
  child: Child;
  chosenProduct: ChosenProduct;
  menuOverrides?: MenuOverride[];
  extras?: Record<string, number>;

  constructor(childrenChosenProduct: ChildrenChosenProductDto, children: Child[]) {
    this.childId = childrenChosenProduct.childId;
    this.chosenProduct = childrenChosenProduct.chosenProduct;
    this.menuOverrides = childrenChosenProduct.menuOverrides;
    this.extras = childrenChosenProduct.extras;

    const child = children.find((c) => c.id === childrenChosenProduct.childId);
    if (!child) {
      throw new Error('Child not found');
    }
    this.child = child;
  }

  static toDto(childrenChosenProduct: ChildrenChosenProduct): ChildrenChosenProductDto {
    return {
      childId: childrenChosenProduct.childId,
      chosenProduct: childrenChosenProduct.chosenProduct,
      menuOverrides: childrenChosenProduct.menuOverrides,
      extras: childrenChosenProduct.extras,
    };
  }
}
