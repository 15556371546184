import { Box, Button, Group, Modal, TextInput } from '@mantine/core';
import { RegistrationDto } from 'api/dto/registration';
import { environment } from 'config/environment';
import axios from 'config/axios';
import { useState } from 'react';

interface SendInvoiceModalProps {
  registration?: RegistrationDto;
  opened: boolean;
  toggle: () => void;
}

export function SendInvoiceModal({ opened, registration, toggle }: SendInvoiceModalProps) {
  const [email, setEmail] = useState(registration?.billingInfo?.email);
  const sendInvoice = async () => {
    if (registration) {
      await axios.post(`${environment.restApi.resourceUrl.camps}/${registration.campId}${environment.restApi.resourceUrl.registrations}/${registration.id}/send-invoice`, {
        email,
      });
    }

    toggle();
  };

  return (
    <Modal title="Facture" opened={opened} withCloseButton onClose={toggle}>
      <Box>
        <TextInput
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          label="Veuillez entrer l'adresse courriel qui recevra la facture"
          defaultValue={registration?.billingInfo?.email}
        />
      </Box>

      <Group mt={10} position="right">
        <Button color="green" onClick={() => sendInvoice()}>
          Envoyer la facture
        </Button>
      </Group>
    </Modal>
  );
}
