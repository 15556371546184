import { Input } from '@mantine/core';
import InputMask from 'react-input-mask';

export function PhoneInput({ label, ...props }: any) {
  return (
    <Input.Wrapper label={label} {...props}>
      <Input component={InputMask} mask="999-999-9999" {...props} />
    </Input.Wrapper>
  );
}
