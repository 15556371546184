import { PaymentDto, PaymentStatus, PaymentType } from 'api/dto';

export class Payment {
  invoiceId?: string;
  type: PaymentType;
  status: PaymentStatus;
  amount: number;
  paidOn?: string;
  createdOn: string;
  refundAmount?: number;
  note?: string;
  hasManagementFees: boolean;

  get isCustomPayment(): boolean {
    return this.type === PaymentType.CUSTOM;
  }

  constructor(data: PaymentDto) {
    this.invoiceId = data.invoiceId;
    this.type = data.type;
    this.status = data.status;
    this.amount = data.amount;
    this.paidOn = data.paidOn;
    this.createdOn = data.createdOn;
    this.refundAmount = data.refundAmount;
    this.note = data.note;
    this.hasManagementFees = data.hasManagementFees;
  }
}
