import { MantineThemeOverride } from '@mantine/core';

export const theme: MantineThemeOverride = {
  fontFamily: 'Mulish',
  datesLocale: 'fr',
  radius: {
    xs: 6,
    sm: 8,
    md: 10,
    lg: 15,
    xl: 20,
  },
  fontSizes: {
    xs: 12,
    sm: 14,
    md: 18,
    lg: 24,
    xl: 35,
  },
  spacing: {
    xs: 3,
    sm: 6,
    md: 12,
    lg: 16,
    xl: 26,
  },
  breakpoints: {
    xs: 350,
    sm: 490,
    md: 764,
    lg: 1280,
    xl: 2000,
  },
};
