/* eslint-disable @typescript-eslint/naming-convention */
import { createStyles } from '@mantine/core';

interface TableStyleProps {
  hasFilters: boolean;
}

export const useTableStyles = createStyles((theme, { hasFilters }: TableStyleProps, getRef) => ({
  search: {
    flex: 1,

    display: 'flex',
    alignItems: 'center',

    padding: '.6875rem 1.375rem .6875rem 0.9rem',

    maxHeight: '2.75rem',
    background: theme.white,
    boxShadow: '0px .0625rem .125rem rgba(133, 133, 133, 0.2), 0px .125rem .25rem rgba(133, 133, 133, 0.2)',
    borderRadius: '2px',

    [`.${getRef('searchInput')}`]: {
      flex: 1,
      fontSize: '.75rem',

      '.mantine-TextInput-input, .mantine-TextInput-root': {
        fontSize: '.75rem',
        width: '100%',
        paddingLeft: '14px',
      },
    },

    [`.${getRef('result')}`]: {
      flex: 0,
      width: '5rem',
      fontSize: '.75rem',
      color: theme.colors.blue[4],
    },

    '.mantine-TextInput-root': {
      minHeight: '1.125rem',
      height: '1.125rem',

      '.mantine-TextInput-wrapper': {
        minHeight: '1.125rem',
        height: '1.125rem',
      },

      input: {
        border: 'none',
        minHeight: '1.125rem',
        height: '1.125rem',

        display: 'flex',
        justifyContent: 'center',
      },
    },
  },

  searchInput: {
    ref: getRef('searchInput'),
    display: 'flex',
    alignItems: 'center',
  },

  result: {
    ref: getRef('result'),
    display: 'flex',
  },

  export: {
    height: '44px',
    backgroundColor: theme.white,
    color: theme.colors.blue,
    boxShadow: '0rem .0625rem .125rem rgba(133, 133, 133, 0.2), 0rem .125rem .25rem rgba(133, 133, 133, 0.2)',

    paddingLeft: '1.0625rem',
    paddingRight: '.745rem',
    justifyContent: 'space-between !important',

    width: '12.8125rem',
    fontSize: '.75rem',
    fontWeight: 600,
    lineHeight: '.945rem',
    marginLeft: '.625rem',
    borderRadius: '.375rem',

    '&:hover': {
      color: theme.colors.blue,
      svg: {
        path: {
          fill: theme.colors.blue,
        },
      },
    },

    '&:active': {
      backgroundColor: theme.colors.blue,
      color: theme.white,
      svg: {
        path: {
          fill: theme.white,
        },
      },
    },
  },

  container: {
    marginTop: hasFilters ? '2.4375rem' : '1.4375rem',
    border: '1px solid #cecece',
    borderRadius: '.625rem',
    overflow: 'auto',

    table: {
      borderCollapse: 'collapse',

      '&[data-striped] tbody tr:nth-of-type(odd)': {
        backgroundColor: theme.white,
      },
      '&[data-striped] tbody tr:nth-of-type(even)': {
        backgroundColor: theme.colors.gray[0],
      },

      [`.${getRef('header')}`]: {
        maxHeight: '3.6875rem',
        height: '3.6875rem',

        [`.${getRef('headerText')}`]: {
          fontWeight: 600,
          fontSize: '.6875rem',
          lineHeight: '.6875rem',
          color: theme.colors.gray[6],
          letterSpacing: '0.02em',
        },

        th: {
          padding: 0,

          ':first-of-type': {
            padding: '0 1.0625rem',
          },
        },
      },

      tr: {
        maxHeight: '4rem',
        height: '4rem',

        td: {
          [`&.${getRef('text')}`]: {
            padding: 0,
            fontWeight: 600,
            fontSize: '.6875rem',
            lineHeight: '.6875rem',
            color: theme.black,
          },

          ':first-of-type': {
            padding: '0 1.0625rem',
          },

          ':last-of-type': {
            padding: '0 1.0625rem 0 0',
          },
        },
      },
    },
  },

  header: {
    ref: getRef('header'),
    backgroundColor: theme.white,
  },
  headerText: {
    ref: getRef('headerText'),
  },

  text: {
    ref: getRef('text'),
  },

  pagination: {
    zIndex: 101,
    marginTop: '1.875rem',
    marginLeft: '1.5625rem',

    display: 'flex',
    justifyContent: 'space-between',
  },

  footerBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '&.start': {
      gap: '1.8rem',
    },

    '.active': {
      '&:hover': {
        color: theme.colors.blue[2],
      },
      '&:active': {
        color: theme.colors.blue[3],
      },
    },

    '.mantine-InputWrapper-root': {
      '.mantine-Select-dropdown': {
        '*': {
          overflow: 'hidden',
        },

        '.mantine-Select-item': {
          maxWidth: '4.25rem',
        },
      },
    },

    '.mantine-Input-wrapper': {
      width: '4.8125rem',
      height: '2.5rem',

      input: {
        border: 'none',
        boxShadow: '0rem .0625rem .125rem rgba(133, 133, 133, 0.2), 0rem .125rem .25rem rgba(133, 133, 133, 0.2)',
        color: theme.colors.blue[4],
        letterSpacing: '0.02em',
        fontSize: '.875rem',
        fontWeight: 600,
        width: '100%',
        height: '100%',
        paddingLeft: '.625rem',
      },

      svg: {
        color: theme.colors.gray[1],
      },
    },
  },

  footerText: {
    fontSize: '.875rem',
    fontWeight: 600,
    lineHeight: '1.3125rem',
    color: theme.colors.gray,
  },

  footerTextButton: {
    cursor: 'pointer',
    WebkitTouchCallout: 'none' /* iOS Safari */,
    WebkitUserSelect: 'none' /* Safari */,
    KhtmlUserSelect: 'none' /* Konqueror HTML */,
    MozUserSelect: 'none' /* Old versions of Firefox */,
    MsUserSelect: 'none' /* Internet Explorer/Edge */,
    UserSelect: 'none',
  },
}));
