import { UseFormReturnType } from '@mantine/form';
import { GetInputProps, SetFieldValue } from '@mantine/form/lib/types';
import { PeopleDto } from 'api/dto/authorization';
import { ChildCareTime } from 'api/enum/child-care-time';
import { IChild } from 'api/models/child';
import { IParent } from 'api/models/parent';
import { IWeek } from 'api/models/product';
import { ITaxCreditInfo } from 'api/models/tax-credit-info';

export enum Steps {
  infos = 0,
  schedule = 1,
  menu = 2,
  tax = 3,
  payment = 4,
}

export enum DaycareSteps {
  infos = 0,
  tax = 1,
}

export enum City {
  victoriaville = 'victoriaville',
  drummondville = 'drummondville',
}

export enum ParentTitle {
  mother = 'MOTHER',
  father = 'FATHER',
  tutor = 'TUTOR',
}

export enum LinkWithChildren {
  GRAND_PARENT = 'GRAND_PARENT',
  STEP_PARENT = 'STEP_PARENT',
  UNCLE_AUNT = 'UNCLE_AUNT',
  SIBLING = 'SIBLING',
  FRIEND = 'FRIEND',
  OTHER = 'OTHER',
}

export enum EnglishLevel {
  BEGINNER = 'BEGINNER',
  BEGINNER_INTERMEDIATE = 'BEGINNER_INTERMEDIATE',
  INTERMEDIATE = 'INTERMEDIATE',
  INTERMEDIATE_ADVANCED = 'INTERMEDIATE_ADVANCED',
  ADVANCED = 'ADVANCED',
}

export enum Gender {
  GIRL = 'GIRL',
  BOY = 'BOY',
  GENDER_NEUTRAL = 'GENDER_NEUTRAL',
}

export interface Family {
  parents: IParent[];
  children: IChild[];
  legalAuthorizations: LegalAuthorization;
  termsAndConditions: TermsAndConditions;
  emergencyContacts: EmergencyContact[];
}

export interface CompleteForm {
  camp: string;
  family: Family;
  schedule: {
    items: Record<string, Item>;
  };
  menu: Menu[];
  taxCreditInfos: ITaxCreditInfo[];
  modifications?: Record<string, any>;
}

export type FullInfoFormStep = UseFormReturnType<
  {
    camp: string;
    family: Family;
  },
  (values: { camp: string; family: Family }) => {
    camp: string;
    family: Family;
  }
>;

export type ScheduleFormStep = UseFormReturnType<
  {
    schedule: {
      items: Record<string, Item>;
    };
    family: {
      children: IChild[];
    };
    menu: Menu[];
    taxCreditInfos: ITaxCreditInfo[];
  },
  (values: {
    schedule: {
      items: Record<string, Item>;
    };
    family: {
      children: IChild[];
    };
    taxCreditInfos: ITaxCreditInfo[];
  }) => {
    schedule: {
      items: Record<string, Item>;
    };
    family: {
      children: IChild[];
    };
    taxCreditInfos: ITaxCreditInfo[];
  }
>;

export interface Menu {
  date: string;
  mainCourse: string;
  child: IChild;
}

export type MenuFormStep = UseFormReturnType<
  {
    menu: Menu[];
    family: {
      children: IChild[];
    };
    schedule: {
      items: Record<string, Item>;
    };
  },
  (values: {
    menu: Menu[];
    family: {
      children: IChild[];
    };
    schedule: {
      items: Record<string, Item>;
    };
  }) => {
    menu: Menu[];
    schedule: {
      items: Record<string, Item>;
    };
    family: {
      children: IChild[];
    };
  }
>;

export interface ParentInfoProps {
  parentIndex: number;
  getInputProps: GetInputProps<{
    parents: IParent[];
  }>;
  withRemove: boolean;
  remove: () => void;
}

export interface ChildInfoProps {
  child: IChild;
  set: SetFieldValue<{
    camp: string;
    family: {
      parents: IParent[];
      children: IChild[];
      legalAuthorizations: LegalAuthorization;
      termsAndConditions: TermsAndConditions;
      emergencyContacts: EmergencyContact[];
    };
  }>;
  withRemove: boolean;
  remove: () => void;
  childIndex: number;
  form: UseFormReturnType<
    {
      schedule: {
        items: Record<string, Item>;
      };
      family: {
        children: IChild[];
      };
    },
    (values: {
      schedule: {
        items: Record<string, Item>;
      };
      family: {
        children: IChild[];
      };
    }) => {
      schedule: {
        items: Record<string, Item>;
      };
      family: {
        children: IChild[];
      };
    }
  >;
}

export interface LegalAuthorizationInfosProps {
  legalAuthorizations: LegalAuthorization;
  count: number;
  getInputProps: GetInputProps<{
    legalAuthorizations: LegalAuthorization;
  }>;
  add: (list: string, item: any) => void;
  remove: (list: string, index: number) => void;
  set: SetFieldValue<{
    camp: string;
    family: Family;
  }>;
}

export interface TermsAndConditionsProps {
  count: number;
  terms: TermsAndConditions;
  getInputProps: GetInputProps<{
    termsAndConditions: TermsAndConditions;
  }>;
}

export interface EmergencyContactsProps {
  contactIndex: number;
  count: number;
  getInputProps: GetInputProps<{
    emergencyContacts: EmergencyContact[];
  }>;
}

export type TaxCreditStep = UseFormReturnType<
  {
    taxCreditInfos: ITaxCreditInfo[];
  },
  (values: { taxCreditInfos: ITaxCreditInfo[] }) => {
    taxCreditInfos: ITaxCreditInfo[];
  }
>;

export interface LegalAuthorization {
  canAdministerDrugs: boolean;
  selectedDrugs?: string[];
  otherDrugs?: string;

  hasChildcare: boolean;
  childcareTime?: ChildCareTime;

  canReturnHomeAlone?: boolean;

  authorizedPeople: PeopleDto[];
  unauthorizedPeople: PeopleDto[];

  isAuthorizedToSharePhotos?: boolean;
}

export interface TermsAndConditions {
  needsToCommunicateModificationsHealth: boolean;
  isAuthorizedToAdministerFirstCare: boolean;
  hasToCollaborateWithManagement: boolean;
  hasReadAndAcceptedTermsAndConditions: boolean;
  allInfosAreTruthful: boolean;
}

export enum Drugs {
  acetaminophen = 'acetaminophen',
  antihistamine = 'antihistamine',
  coughSyrup = 'coughSyrup',
  antiemetic = 'antiemetic',
  antiInflammatory = 'antiInflammatory',
  antibioticCream = 'antibioticCream',
  other = 'other',
}

export enum ProductExtra {
  GLUTEN_FREE = 'glutenFree',
  VEGETARIAN = 'vegetarian',
}

export interface Concentration {
  id: string;
  title: string;
  price: number;
}

export interface Item {
  id: string;
  child: IChild;
  weeks: Record<string, IWeek>;
  extras?: Partial<Record<ProductExtra, number>>;
}

export interface EmergencyContact {
  id?: string;
  familyId?: string;
  firstName: string;
  lastName: string;
  link: LinkWithChildren;
  cellphone: string;
  phone?: string;
}

export interface Difference {
  id: string;
  amount: number;
}

export type InvoiceSchedule = UseFormReturnType<
  {
    schedule: {
      items: Record<string, Item>;
    };
    family: {
      children: (IChild & { id: string })[];
    };
    menu: Menu[];
    modifications: {
      note?: string;
      amount: number;
      hasManagementFees?: boolean;
    };
    taxCreditInfos: ITaxCreditInfo[];
  },
  (values: {
    schedule: {
      items: Record<string, Item>;
    };
    family: {
      children: (IChild & { id: string })[];
    };
    menu: Menu[];
    modifications: {
      note?: string;
      amount: number;
      hasManagementFees?: boolean;
    };
    taxCreditInfos: ITaxCreditInfo[];
  }) => {
    schedule: {
      items: Record<string, Item>;
    };
    menu: Menu[];
    family: {
      children: (IChild & { id: string })[];
    };
    modifications: {
      note?: string;
      amount: number;
      hasManagementFees?: boolean;
    };
    taxCreditInfos: ITaxCreditInfo[];
  }
>;
