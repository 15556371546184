import { Button, Group, Modal } from '@mantine/core';
import { DateRangePicker } from '@mantine/dates';
import { generateMenuReport } from 'api/queries/registration';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime, DateTimeFormat } from 'utils/datetime';

interface MenuModalProps {
  campId?: string;
  opened: boolean;
  toggle: () => void;
}

export function MenuModal({ campId, opened, toggle }: MenuModalProps) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [range, setRange] = useState<[Date | null, Date | null]>([null, null]);

  async function handleGenerate() {
    setIsLoading(true);
    const start = DateTime.fromDate(range[0]!).toFormat(DateTimeFormat.YearMonthAndDay);
    const end = DateTime.fromDate(range[1]!).toFormat(DateTimeFormat.YearMonthAndDay);

    await generateMenuReport(campId!, start, end);
    toggle();
    setIsLoading(false);
  }

  return (
    <Modal opened={opened} onClose={toggle} title="Menu">
      <DateRangePicker label={t('admin:Report.SelectDateRange')} onChange={(e) => setRange(e)} value={range} allowSingleDateInRange />
      <Group position="right" mt="md">
        <Button loading={isLoading} onClick={handleGenerate}>
          Générer
        </Button>
      </Group>
    </Modal>
  );
}
