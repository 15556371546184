import { createStyles } from '@mantine/core';

export const useBandStyles = createStyles(() => ({
  container: {
    padding: '35px',
    backgroundColor: '#425789',
    fontFamily: 'Barrio',
    fontSize: 'clamp(35px, 10vw, 60px)',
    textTransform: 'uppercase',
    color: '#fff',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
