import axios from 'config/axios';
import { useQuery } from '@tanstack/react-query';
import { environment } from 'config/environment';
import { showNotification } from '@mantine/notifications';
import { InvoiceDto } from 'api/dto';

export const useGetInvoices = (registrationId: string) =>
  useQuery<InvoiceDto[]>([registrationId, 'invoices'], async () => {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    const { data } = await axios.get<{ items: InvoiceDto[] }>(`${environment.restApi.resourceUrl.registrations}/${registrationId}/invoices`);
    return data.items;
  });

export const useGetInvoiceTemplate = () =>
  useQuery<string>(['invoice-template'], async () => {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    const { data } = await axios.get<string>(`${environment.restApi.resourceUrl.invoices}/template`);
    return data;
  });

export async function sendInvoice(registrationId: string, campId: string, email: string) {
  try {
    await axios.post(`${environment.restApi.resourceUrl.camps}/${campId}${environment.restApi.resourceUrl.registrations}/${registrationId}/send-invoice`, {
      email,
    });
  } catch (error) {
    showNotification({
      title: 'Ooops!',
      // eslint-disable-next-line quotes
      message: "Une erreur s'est produite. Veuillez recommencer ou contacter le support",
      color: 'red',
      sx: {
        padding: 10,
        paddingLeft: 20,
      },
    });
  }
}
