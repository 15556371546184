import { Box, Button, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { Band } from 'component/band/band';
import { useAuth } from 'hooks/useAuth';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface FormValues {
  email: string;
  password: string;
}

export const validatePassword = (value: string) => /^.{8,999}$/.test(value) && /[0-9]/.test(value) && /[a-z]/.test(value) && /[A-Z]/.test(value);

export default function Login() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const form = useForm<FormValues>({
    initialValues: {
      email: '',
      password: '',
    },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
      password: (value) => (validatePassword(value) ? null : 'Le mot de passe doit contenir une lettre majuscule, minuscule, un nombre et au moins 8 caractères.'),
    },
    validateInputOnChange: true,
  });
  const { login } = useAuth();

  const submit = async (values: FormValues) => {
    setLoading(true);
    try {
      await login(values.email, values.password);
      navigate('/admin/registrations');
    } catch (error) {
      // do nothing
    }
    setLoading(false);
  };

  return (
    <>
      <Band text={t('admin:Authentication.Login')} />

      <form onSubmit={form.onSubmit(submit)}>
        <Box mt={50} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Box w={300}>
            <TextInput type="email" required label={t('admin:Authentication.Email')} {...form.getInputProps('email')} />
            <TextInput mb={15} required type="password" label={t('admin:Authentication.Password')} {...form.getInputProps('password')} />
            <Box>
              <Button disabled={loading} loading={loading} type="submit" color="blue" variant="outline">
                {t('admin:Authentication.SignIn')}
              </Button>
            </Box>
          </Box>
        </Box>
      </form>
    </>
  );
}
