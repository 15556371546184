import { Camp } from 'api/models/camp';
import { StateCreator } from 'zustand';

export interface CampSlice {
  camp?: Camp;
  selectCamp: (camp?: Camp) => void;
}

export const createCampSlice: StateCreator<CampSlice, [], [], CampSlice> = (set) => ({
  selectCamp: (camp) =>
    set(() => {
      if (camp) {
        window.localStorage.setItem('chosen-camp', camp.id);
      }
      return { camp };
    }),
});
