import { Text } from '@mantine/core';
import { StepLayout } from 'layout/step.layout';
import { useTranslation } from 'react-i18next';
import { ScheduleFormStep } from './types';
import { ReactNode } from 'react';
import { ScheduleTabs } from 'component/schedule/schedule-tabs';
import { Registration } from 'api/models/registration';

interface ScheduleProps {
  form: ScheduleFormStep;
  children?: ReactNode;
  showPartialTotal?: boolean;
  registration?: Registration;
}

export function Schedule({ form, children: childrenNodes, showPartialTotal, registration }: ScheduleProps) {
  const { t } = useTranslation();

  return (
    <StepLayout>
      <Text mb={20} size="lg">
        {t('schedule:Availability')}
      </Text>

      <Text mb={20} color="red" italic>
        {t('schedule:PleaseChoose')}
      </Text>

      <ScheduleTabs form={form} isEditable showPartialTotal={showPartialTotal} registration={registration} />

      {childrenNodes}
    </StepLayout>
  );
}
