import { Box } from '@mantine/core';
import React from 'react';
import { useBandStyles } from './band.styles';

interface BandProps {
  text: string;
}

export function Band({ text }: BandProps) {
  const { classes } = useBandStyles();
  return <Box className={classes.container}>{text}</Box>;
}
