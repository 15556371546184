import { createRoot } from 'react-dom/client';
import 'config/i18n';
import { Center, Group, Loader, MantineProvider } from '@mantine/core';
import { App } from './app';
import './index.scss';
import { theme } from 'theme/theme';
import { HashRouter } from 'react-router-dom';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from 'api/query-client';
import { setDefaultUrl } from 'config/axios';
import { environment } from 'config/environment';
import { NotificationsProvider } from '@mantine/notifications';
import { ProvideAuth } from 'hooks/useAuth';
import { useCognitoInfo } from 'api/queries/cognito-infos';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

const container = document.getElementById('root');
const root = createRoot(container!);
setDefaultUrl(environment.restApi.baseUrl);

if (!environment.isLocal) {
  Sentry.init({
    dsn: environment.sentry.dsn,
    integrations: [new BrowserTracing()],
    maxBreadcrumbs: 50,
    debug: !environment.isProd,
    environment: environment.envName,
    tracesSampleRate: environment.isProd ? 1 : 0.25,
    release: import.meta.env.VITE_PLUGIN_SENTRY_CONFIG.release,
  });
}

const IndexWrapper = () => {
  const { data: cognito, isLoading: isLoadingCognito } = useCognitoInfo();

  if (isLoadingCognito) {
    return (
      <Group
        sx={{
          height: '100vh',
          justifyContent: 'center',
        }}
      >
        <Center>
          <Loader size="xl" />
        </Center>
      </Group>
    );
  }

  return (
    <HashRouter>
      <ProvideAuth infos={cognito}>
        <App />
      </ProvideAuth>
    </HashRouter>
  );
};

root.render(
  <MantineProvider theme={theme}>
    <NotificationsProvider>
      <QueryClientProvider client={queryClient}>
        <IndexWrapper />
      </QueryClientProvider>
    </NotificationsProvider>
  </MantineProvider>
);
