import { ActionIcon, Anchor, Box, Button, Checkbox, MultiSelect, NumberInput, Radio, Select, SimpleGrid, Text, Textarea, TextInput } from '@mantine/core';
import { StepLayout } from 'layout/step.layout';
import { useTranslation } from 'react-i18next';
import {
  LegalAuthorizationInfosProps,
  ChildInfoProps,
  Drugs,
  FullInfoFormStep,
  ParentInfoProps,
  TermsAndConditionsProps,
  EmergencyContactsProps,
  ParentTitle,
  LinkWithChildren,
  EnglishLevel,
  Gender,
  ScheduleFormStep,
} from './types';
import { DatePicker } from '@mantine/dates';
import { UserPlus as Plus, X } from 'tabler-icons-react';
import { useMemo } from 'react';
import { useFormStyles } from './form.styles';
import dayjs from 'dayjs';
import { useAddFamily, useGetCamps } from 'api/queries';
import { ChildCareTime } from 'api/enum/child-care-time';
import { LabelWithAsterisk } from 'component/label/labelWithAsterisk';
import { useAppStore } from 'store/store';
import { environment } from 'config/environment';
import { PhoneInput } from 'component/input/phone-input';
import { CampStatus } from 'api/dto/camp';
import { useSearchParams } from 'react-router-dom';
import { nanoid } from 'nanoid';

interface FullInformationProps {
  form: FullInfoFormStep & ScheduleFormStep;
}

export function FullInformation({ form }: FullInformationProps) {
  const { t } = useTranslation();
  const { data: camps } = useGetCamps();
  const mutation = useAddFamily();
  const { camp, selectCamp } = useAppStore();
  const { classes } = useFormStyles();
  const [params] = useSearchParams();

  const parents = useMemo(
    () =>
      form.values.family.parents.map((_, index) => (
        <ParentInfo key={`parent${index}`} getInputProps={form.getInputProps} parentIndex={index} withRemove={index !== 0} remove={() => form.removeListItem('family.parents', index)} />
      )),
    [form]
  );

  const children = useMemo(
    () =>
      form.values.family.children.map((child, index) => (
        <ChildInfo
          key={`child${index}`}
          set={form.setFieldValue}
          withRemove={form.values.family.children.length > 1}
          child={child}
          remove={() => {
            const schedule = form.values.schedule;
            delete schedule.items[child.tempId];

            form.setValues({ ...form.values, schedule });
            form.removeListItem('family.children', index);
          }}
          form={form as any}
          childIndex={index}
        />
      )),
    [form]
  );

  const emergencyContact = useMemo(
    () =>
      form.values.family.emergencyContacts.map((_, index) => <EmergencyContacts key={`emergency-contact-${index}`} count={children.length} getInputProps={form.getInputProps} contactIndex={index} />),
    [children, form]
  );

  return (
    <StepLayout>
      <Box
        mb={20}
        sx={{
          maxWidth: 400,
        }}
      >
        {environment.isDev && (
          <Button
            sx={{
              position: 'absolute',
              top: 170,
              right: 150,
            }}
            size="sm"
            color="blue"
            variant="outline"
            onClick={() => mutation.mutate(form.values.family as any)}
          >
            Send family infos
          </Button>
        )}
        <Select
          required
          label={t('schedule:ScheduleInfos', { count: children.length })}
          sx={{
            label: {
              marginBottom: 10,
            },
          }}
          {...form.getInputProps('camp')}
          data={camps?.filter((c) => !c.isInactive).map((c) => ({ label: c.nameFr, value: c.id })) || []}
          value={camp?.id}
          onChange={(id) => {
            if (id) {
              form.setFieldValue('camp', id);
              selectCamp(camps?.find((c) => c.id === id));
            }
          }}
        />
      </Box>

      {camp?.status == CampStatus.FULL && !params.get('bypass') ? (
        <Box mb={20} p={10} sx={{ border: '1px solid #cecece', borderRadius: '6px' }}>
          <Text size="md" weight="bolder" italic>
            {t('camp:Full')}
          </Text>
          <Anchor size="md" weight="bolder" italic href={camp.waitingListUrl} target="_blank">
            {t('camp:WaitingList')}
          </Anchor>
        </Box>
      ) : (
        <>
          {parents.length != 2 && (
            <Button disabled={parents.length > 1} leftIcon={<Plus size={20} />} mb={20} variant="outline" color="pink" onClick={() => form.insertListItem('family.parents', {})}>
              {t('parent:AddParent')}
            </Button>
          )}
          <Text mb={20} size="lg">
            {t('parent:Infos.GuardianInformations')}
          </Text>
          <SimpleGrid
            mb={20}
            breakpoints={[
              { minWidth: 991, cols: parents.length > 1 ? 2 : 1, spacing: 'xl' },
              { maxWidth: 990, cols: 1, spacing: 'xs' },
            ]}
          >
            {parents}
          </SimpleGrid>

          <Text mb={20} size="lg">
            {t('child:Infos.ChildrenInfos')}
          </Text>
          <Button
            disabled={children.length > 3}
            leftIcon={<Plus size={20} />}
            mb={20}
            variant="outline"
            color="pink"
            onClick={() => {
              const tempId = nanoid();

              const schedule = form.values.schedule;
              schedule.items[tempId] = { id: tempId, child: { tempId } as any, weeks: {} };
              form.setValues({ ...form.values, schedule });

              form.insertListItem('family.children', { tempId });
            }}
          >
            {t('child:AddChild')}
          </Button>

          <SimpleGrid
            breakpoints={[
              { minWidth: 991, cols: children.length > 1 ? 2 : 1, spacing: 'xl' },
              { maxWidth: 990, cols: 1, spacing: 'xs' },
            ]}
          >
            {children}
          </SimpleGrid>

          <Text mb={20} size="lg">
            {t('parent:LegalAuthorizations')}
          </Text>

          <AuthorizationInfos
            set={form.setFieldValue}
            add={form.insertListItem}
            remove={form.removeListItem}
            count={children.length}
            legalAuthorizations={form.values.family.legalAuthorizations}
            getInputProps={form.getInputProps}
          />

          <Text sx={{ display: 'flex' }} mb={10} size="lg">
            {t('parent:EmergencyContacts')}
            <Text ml={10} color="red">
              {t('parent:EmergencyContactsOther')}
            </Text>
          </Text>

          <Text mb={20} size="sm" italic color="gray">
            {t('parent:EmergencyExplanations')}
          </Text>

          <SimpleGrid
            cols={2}
            mb={20}
            breakpoints={[
              { maxWidth: 2000, cols: 2, spacing: 'xl' },
              { maxWidth: 990, cols: 1, spacing: 'xs' },
            ]}
          >
            {emergencyContact}
          </SimpleGrid>

          <Text mb={20} size="lg">
            {t('parent:TermsAndConditions')}
          </Text>

          <TermsAndConditions terms={form.values.family.termsAndConditions} count={children.length} getInputProps={form.getInputProps} />

          <Text mb={20} size="lg">
            {t('parent:Comments')}
          </Text>

          <Box className={classes.boxContainer}>
            <Textarea label={t('parent:SendComments')} minRows={5} {...form.getInputProps('family.comments')} />
          </Box>
        </>
      )}
    </StepLayout>
  );
}

function ParentInfo({ getInputProps, parentIndex, withRemove, remove }: ParentInfoProps) {
  const { t } = useTranslation();
  const { classes } = useFormStyles();
  return (
    <Box
      className={classes.boxContainer}
      sx={{
        position: 'relative',
      }}
    >
      {withRemove && (
        <ActionIcon
          sx={{
            position: 'absolute',
            top: 5,
            right: 10,
          }}
          onClick={remove}
        >
          <X size={20} />
        </ActionIcon>
      )}
      <Radio.Group mb={10} label={t('parent:Infos.Title')} required {...getInputProps(`family.parents.${parentIndex}.title`)}>
        <Radio value={ParentTitle.mother} label={t('parent:Type.Mother')} />
        <Radio value={ParentTitle.father} label={t('parent:Type.Father')} />
        <Radio value={ParentTitle.tutor} label={t('parent:Type.Tutor')} />
      </Radio.Group>
      <SimpleGrid mb={10} cols={2}>
        <TextInput required label={t('Infos.FirstName')} {...getInputProps(`family.parents.${parentIndex}.firstName`)} />
        <TextInput required label={t('Infos.LastName')} {...getInputProps(`family.parents.${parentIndex}.lastName`)} />
      </SimpleGrid>

      <SimpleGrid mb={10} cols={2}>
        <PhoneInput required label={t('parent:Infos.Cellphone')} {...getInputProps(`family.parents.${parentIndex}.cellphone`)} />
        <PhoneInput label={t('parent:Infos.Phone')} {...getInputProps(`family.parents.${parentIndex}.phone`)} />
      </SimpleGrid>

      <Textarea minRows={3} maxRows={5} label={t('parent:Infos.Address')} withAsterisk {...getInputProps(`family.parents.${parentIndex}.address`)} />
      <TextInput required type="email" mt={10} label={t('parent:Infos.Email')} {...getInputProps(`family.parents.${parentIndex}.email`)} />
    </Box>
  );
}

function ChildInfo({ child, set, remove, withRemove, form, childIndex }: ChildInfoProps) {
  const { t } = useTranslation();
  const { classes } = useFormStyles();

  return (
    <Box
      sx={{
        position: 'relative',
      }}
      className={classes.boxContainer}
      pt={30}
    >
      {withRemove && (
        <ActionIcon
          sx={{
            position: 'absolute',
            top: 5,
            right: 10,
          }}
          onClick={remove}
        >
          <X size={20} />
        </ActionIcon>
      )}

      <Box mb={10}>
        <Anchor color="red" size={20} href="https://forms.gle/KRXmgD8m5VgVczGg8" target="_blank">
          {t('child:Infos.BuyTShirt')}
        </Anchor>
      </Box>

      <SimpleGrid mb={10} cols={2}>
        <TextInput
          required
          label={t('Infos.FirstName')}
          {...form.getInputProps(`family.children.${childIndex}.firstName`)}
          onChange={(e) => {
            const name = e.target.value;
            set(`family.children.${childIndex}.firstName`, name || '');
            const item = form.values.schedule.items[child.tempId];
            if (item) {
              set(`schedule.items.${child.tempId}.child.firstName`, name || '');
            }
          }}
        />
        <TextInput
          required
          label={t('Infos.LastName')}
          {...form.getInputProps(`family.children.${childIndex}.lastName`)}
          onChange={(e) => {
            const name = e.target.value;
            set(`family.children.${childIndex}.lastName`, name || '');
            const item = form.values.schedule.items[child.tempId];
            if (item) {
              set(`schedule.items.${child.tempId}.child.lastName`, name || '');
            }
          }}
        />
      </SimpleGrid>

      <SimpleGrid mb={10} cols={2}>
        <DatePicker
          withAsterisk
          label={t('child:Infos.BirthDate')}
          {...form.getInputProps(`family.children.${childIndex}.birthDate`)}
          value={child.birthDate ? dayjs(child.birthDate).toDate() : undefined}
          initialMonth={dayjs().set('day', 24).set('month', 5).subtract(3, 'y').toDate()}
          maxDate={dayjs().set('day', 24).set('month', 5).subtract(3, 'y').toDate()}
          onChange={(birthDate) => {
            set(`family.children.${childIndex}.birthDate`, birthDate?.toISOString());
            if (birthDate) {
              // Age as of June 24
              const june = dayjs().set('month', 5).set('day', 24);
              const age = dayjs(june).diff(birthDate, 'year');
              set(`family.children.${childIndex}.age`, age);
            }
          }}
        />
        <NumberInput min={3} max={12} disabled label={t('child:Infos.Age')} {...form.getInputProps(`family.children.${childIndex}.age`)} />
      </SimpleGrid>

      <SimpleGrid mb={10} cols={2}>
        <TextInput required label={t('child:Infos.HealthInsuranceNumber')} {...form.getInputProps(`family.children.${childIndex}.healthInsuranceNumber`)} />
        <DatePicker
          withAsterisk
          label={t('child:Infos.HealthInsuranceExpiryDate')}
          {...form.getInputProps(`family.children.${childIndex}.healthInsuranceExpiryDate`)}
          value={child.healthInsuranceExpiryDate ? dayjs(child.healthInsuranceExpiryDate).toDate() : undefined}
          onChange={(e) => set(`family.children.${childIndex}.healthInsuranceExpiryDate`, e?.toISOString())}
        />
      </SimpleGrid>

      <Radio.Group mb={10} label={t('child:Infos.EnglishLevel.Detail')} required {...form.getInputProps(`family.children.${childIndex}.englishLevel`)}>
        <Radio value={EnglishLevel.BEGINNER} label={t('child:Infos.EnglishLevel.Beginner')} />
        <Radio value={EnglishLevel.BEGINNER_INTERMEDIATE} label={t('child:Infos.EnglishLevel.Beginner-Intermediate')} />
        <Radio value={EnglishLevel.INTERMEDIATE} label={t('child:Infos.EnglishLevel.Intermediate')} />
        <Radio value={EnglishLevel.INTERMEDIATE_ADVANCED} label={t('child:Infos.EnglishLevel.Intermediate-Advanced')} />
        <Radio value={EnglishLevel.ADVANCED} label={t('child:Infos.EnglishLevel.Advanced')} />
      </Radio.Group>

      <Radio.Group mb={10} label={t('child:Infos.Gender')} required {...form.getInputProps(`family.children.${childIndex}.gender`)}>
        <Radio value={Gender.BOY} label={t('child:Infos.GenderType.Boy')} />
        <Radio value={Gender.GIRL} label={t('child:Infos.GenderType.Girl')} />
        <Radio value={Gender.GENDER_NEUTRAL} label={t('child:Infos.GenderType.GenderNeutral')} />
      </Radio.Group>

      <Radio.Group
        mb={10}
        label={t('child:Infos.HasAllergies')}
        required
        {...form.getInputProps(`family.children.${childIndex}.hasAllergies`)}
        value={child.hasAllergies === undefined ? undefined : child.hasAllergies ? 'true' : 'false'}
        onChange={(e) => set(`family.children.${childIndex}.hasAllergies`, e === 'true' ? true : false)}
      >
        <Radio value="true" label={t('Common.Yes')} />
        <Radio value="false" label={t('Common.No')} />
      </Radio.Group>

      {child.hasAllergies && <Textarea mb={10} withAsterisk minRows={3} label={t('child:Infos.Which_female')} {...form.getInputProps(`family.children.${childIndex}.allergiesExplanations`)} />}

      {child.hasAllergies && (
        <Radio.Group
          mb={10}
          label={t('child:Infos.Epipen')}
          required
          {...form.getInputProps(`family.children.${childIndex}.hasEpipen`)}
          value={child.hasEpipen === undefined ? undefined : child.hasEpipen ? 'true' : 'false'}
          onChange={(e) => set(`family.children.${childIndex}.hasEpipen`, e === 'true' ? true : false)}
        >
          <Radio value="true" label={t('Common.Yes')} />
          <Radio value="false" label={t('Common.No')} />
        </Radio.Group>
      )}

      {child.hasEpipen && (
        <Radio.Group
          mb={10}
          label={t('child:Infos.EpipenAuthorization')}
          required
          {...form.getInputProps(`family.children.${childIndex}.epipenAuthorization`)}
          value={child.epipenAuthorization === undefined ? undefined : child.epipenAuthorization ? 'true' : 'false'}
          onChange={(e) => set(`family.children.${childIndex}.epipenAuthorization`, e === 'true' ? true : false)}
        >
          <Radio value="true" label={t('Common.Yes')} />
          <Radio value="false" label={t('Common.No')} />
        </Radio.Group>
      )}

      <Radio.Group
        mb={10}
        label={t('child:Infos.Drugs')}
        required
        {...form.getInputProps(`family.children.${childIndex}.takesDrugs`)}
        value={child.takesDrugs === undefined ? undefined : child.takesDrugs ? 'true' : 'false'}
        onChange={(e) => set(`family.children.${childIndex}.takesDrugs`, e === 'true' ? true : false)}
      >
        <Radio value="true" label={t('Common.Yes')} />
        <Radio value="false" label={t('Common.No')} />
      </Radio.Group>

      {child.takesDrugs && <Textarea withAsterisk minRows={3} label={t('child:Infos.Which_male')} {...form.getInputProps(`family.children.${childIndex}.drugsExplanations`)} />}

      <Radio.Group
        mb={10}
        label={t('child:Infos.RestrictedActivities')}
        required
        {...form.getInputProps(`family.children.${childIndex}.hasRestrictedConcentrations`)}
        value={child.hasRestrictedConcentrations === undefined ? undefined : child.hasRestrictedConcentrations ? 'true' : 'false'}
        onChange={(e) => set(`family.children.${childIndex}.hasRestrictedConcentrations`, e === 'true' ? true : false)}
      >
        <Radio value="true" label={t('Common.Yes')} />
        <Radio value="false" label={t('Common.No')} />
      </Radio.Group>

      {child.hasRestrictedConcentrations && (
        <Textarea mb={10} withAsterisk minRows={3} label={t('child:Infos.Which_female')} {...form.getInputProps(`family.children.${childIndex}.restrictedConcentrationsExplanations`)} />
      )}

      <Radio.Group
        mb={10}
        label={t('child:Infos.Plan')}
        required
        {...form.getInputProps(`family.children.${childIndex}.hasSpecificDevelopmentPlan`)}
        value={child.hasSpecificDevelopmentPlan === undefined ? undefined : child.hasSpecificDevelopmentPlan ? 'true' : 'false'}
        onChange={(e) => set(`family.children.${childIndex}.hasSpecificDevelopmentPlan`, e === 'true' ? true : false)}
      >
        <Radio value="true" label={t('Common.Yes')} />
        <Radio value="false" label={t('Common.No')} />
      </Radio.Group>

      {child.hasSpecificDevelopmentPlan && (
        <Textarea mb={10} withAsterisk minRows={3} label={t('child:Infos.Details')} {...form.getInputProps(`family.children.${childIndex}.specificDevelopmentPlanDetails`)} />
      )}
    </Box>
  );
}

function AuthorizationInfos({ legalAuthorizations, set, add, remove, count, getInputProps }: LegalAuthorizationInfosProps) {
  const { t } = useTranslation();
  const { classes } = useFormStyles();

  const drugs = useMemo(() => Object.values(Drugs).map((d) => ({ label: t(`parent:Drugs.Type.${d}`), value: d })), [t]);

  return (
    <Box className={classes.boxContainer}>
      <Radio.Group
        mb={10}
        label={t('parent:Infos.Drugs', { count })}
        required
        {...getInputProps('family.legalAuthorizations.canAdministerDrugs')}
        value={legalAuthorizations.canAdministerDrugs === undefined ? undefined : legalAuthorizations.canAdministerDrugs ? 'true' : 'false'}
        onChange={(e) => set('family.legalAuthorizations.canAdministerDrugs', e === 'true' ? true : false)}
      >
        <Radio value="true" label={t('Common.Yes')} />
        <Radio value="false" label={t('Common.No')} />
      </Radio.Group>
      {legalAuthorizations.canAdministerDrugs && (
        <>
          <MultiSelect mb={10} label={t('parent:Drugs.SelectWhich')} clearButtonLabel={t('Common.Clear') || ''} clearable data={drugs} {...getInputProps('family.legalAuthorizations.selectedDrugs')} />
          <Text mb={10} size="sm" fs="oblique">
            {t('parent:Drugs.Explanations')}
          </Text>
        </>
      )}

      {legalAuthorizations.selectedDrugs && legalAuthorizations.selectedDrugs.includes(Drugs.other) && (
        <Textarea mb={10} withAsterisk minRows={3} label={t('parent:Drugs.SpecifyOthers')} {...getInputProps('family.legalAuthorizations.otherDrugs')} />
      )}

      <Radio.Group
        mb={10}
        label={t('parent:Authorization.ChildCare', { count })}
        required
        {...getInputProps('family.legalAuthorizations.hasChildcare')}
        value={legalAuthorizations.hasChildcare === undefined ? undefined : legalAuthorizations.hasChildcare ? 'true' : 'false'}
        onChange={(e) => set('family.legalAuthorizations.hasChildcare', e === 'true' ? true : false)}
      >
        <Radio value="true" label={t('Common.Yes')} />
        <Radio value="false" label={t('Common.No')} />
      </Radio.Group>

      {legalAuthorizations.hasChildcare && (
        <Radio.Group mb={10} label={t('parent:Authorization.ChildcareTime', { count })} required {...getInputProps('family.legalAuthorizations.childcareTime')}>
          <Radio value={ChildCareTime.DAY} label={t('Common.Day')} />
          <Radio value={ChildCareTime.NIGHT} label={t('Common.Night')} />
          <Radio value={ChildCareTime.DAY_AND_NIGHT} label={t('Common.DayAndNight')} />
        </Radio.Group>
      )}

      <Radio.Group
        mb={10}
        label={t('parent:Authorization.ReturnHomeAlone', { count })}
        required
        {...getInputProps('family.legalAuthorizations.canReturnHomeAlone')}
        value={legalAuthorizations.canReturnHomeAlone === undefined ? undefined : legalAuthorizations.canReturnHomeAlone ? 'true' : 'false'}
        onChange={(e) => set('family.legalAuthorizations.canReturnHomeAlone', e === 'true' ? true : false)}
      >
        <Radio value="true" label={t('Common.Yes')} />
        <Radio value="false" label={t('Common.No')} />
      </Radio.Group>

      <Box mb={20}>
        <Text mb={10} size="sm">
          {t('parent:Authorization.PeopleAuthorized', { count: legalAuthorizations.authorizedPeople.length })}
        </Text>
        <Button mb={10} size="xs" onClick={() => add('family.legalAuthorizations.authorizedPeople', {})} disabled={legalAuthorizations.authorizedPeople.length >= 4}>
          {t('parent:Authorization.AddPeople')}
        </Button>

        {legalAuthorizations.authorizedPeople?.map((a, index) => (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <ActionIcon mr={10} onClick={() => remove('family.legalAuthorizations.authorizedPeople', index)}>
              <X size={20} />
            </ActionIcon>
            <SimpleGrid mb={10} cols={3}>
              <TextInput required label={t('Infos.FirstName')} {...getInputProps(`family.legalAuthorizations.authorizedPeople.${index}.firstName`)} />
              <TextInput required label={t('Infos.LastName')} {...getInputProps(`family.legalAuthorizations.authorizedPeople.${index}.lastName`)} />
              <Select
                required
                label={t('contact:Infos.LinkWithChildren', { count })}
                data={[
                  { label: t('parent:Link.GrandParent')!, value: LinkWithChildren.GRAND_PARENT },
                  { label: t('parent:Link.StepParent')!, value: LinkWithChildren.STEP_PARENT },
                  { label: t('parent:Link.UncleAunt')!, value: LinkWithChildren.UNCLE_AUNT },
                  { label: t('parent:Link.Sibling')!, value: LinkWithChildren.SIBLING },
                  { label: t('parent:Link.Friend')!, value: LinkWithChildren.FRIEND },
                  { label: t('parent:Link.Other')!, value: LinkWithChildren.OTHER },
                ]}
                {...getInputProps(`family.legalAuthorizations.authorizedPeople.${index}.link`)}
              />
            </SimpleGrid>
          </Box>
        ))}
      </Box>

      <Box mb={30}>
        <Text mb={10} size="sm">
          {t('parent:Authorization.PeopleUnauthorized', { count: legalAuthorizations.unauthorizedPeople.length })}
        </Text>
        <Button mb={10} size="xs" onClick={() => add('family.legalAuthorizations.unauthorizedPeople', {})} disabled={legalAuthorizations.unauthorizedPeople.length >= 4}>
          {t('parent:Authorization.AddPeople')}
        </Button>

        {legalAuthorizations.unauthorizedPeople?.map((a, index) => (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <ActionIcon mr={10} onClick={() => remove('family.legalAuthorizations.unauthorizedPeople', index)}>
              <X size={20} />
            </ActionIcon>
            <SimpleGrid cols={2}>
              <TextInput required label={t('Infos.FirstName')} {...getInputProps(`family.legalAuthorizations.unauthorizedPeople.${index}.firstName`)} />
              <TextInput required label={t('Infos.LastName')} {...getInputProps(`family.legalAuthorizations.unauthorizedPeople.${index}.lastName`)} />
            </SimpleGrid>
          </Box>
        ))}
      </Box>

      <Radio.Group
        mb={10}
        label={t('parent:Authorization.SharePhoto', { count })}
        required
        {...getInputProps('family.legalAuthorizations.isAuthorizedToSharePhotos')}
        value={legalAuthorizations.isAuthorizedToSharePhotos === undefined ? undefined : legalAuthorizations.isAuthorizedToSharePhotos ? 'true' : 'false'}
        onChange={(e) => set('family.legalAuthorizations.isAuthorizedToSharePhotos', e === 'true' ? true : false)}
      >
        <Radio value="true" label={t('Common.Accept')} />
        <Radio value="false" label={t('Common.Deny')} />
      </Radio.Group>
    </Box>
  );
}

function TermsAndConditions({ getInputProps, terms, count }: TermsAndConditionsProps) {
  const { t } = useTranslation();

  const { classes } = useFormStyles();

  return (
    <Box className={classes.boxContainer}>
      <Checkbox
        mb={10}
        label={<LabelWithAsterisk>{t('parent:Authorization.Modifications', { count })} </LabelWithAsterisk>}
        {...getInputProps('family.termsAndConditions.needsToCommunicateModificationsHealth')}
        checked={terms.needsToCommunicateModificationsHealth}
      />
      <Checkbox
        mb={10}
        label={<LabelWithAsterisk>{t('parent:Authorization.FirstCare', { count })} </LabelWithAsterisk>}
        {...getInputProps('family.termsAndConditions.isAuthorizedToAdministerFirstCare')}
        checked={terms.isAuthorizedToAdministerFirstCare}
      />
      <Checkbox
        mb={10}
        label={<LabelWithAsterisk>{t('parent:Authorization.Collaboration', { count })} </LabelWithAsterisk>}
        {...getInputProps('family.termsAndConditions.hasToCollaborateWithManagement')}
        checked={terms.hasToCollaborateWithManagement}
      />
      <Checkbox
        mb={10}
        label={
          <LabelWithAsterisk className={classes.terms}>
            <Text>{t('parent:Authorization.TermsAndConditions')}</Text>
            <Text
              ml={5}
              underline
              sx={{
                color: 'blue',
                cursor: 'pointer',
              }}
              onClick={() => window.open('https://campesa.ca/politiques-du-camp-esa/', '_blank')}
            >
              {t('parent:Authorization.TermsAndConditionsLink')}
            </Text>
          </LabelWithAsterisk>
        }
        {...getInputProps('family.termsAndConditions.hasReadAndAcceptedTermsAndConditions')}
        checked={terms.hasReadAndAcceptedTermsAndConditions}
      />
      <Checkbox
        mb={10}
        label={<LabelWithAsterisk>{t('parent:Authorization.AllInfos')}</LabelWithAsterisk>}
        {...getInputProps('family.termsAndConditions.allInfosAreTruthful')}
        checked={terms.allInfosAreTruthful}
      />
    </Box>
  );
}

function EmergencyContacts({ getInputProps, count, contactIndex }: EmergencyContactsProps) {
  const { t } = useTranslation();

  const { classes } = useFormStyles();

  return (
    <Box className={classes.boxContainer}>
      <SimpleGrid mb={10} cols={2}>
        <TextInput required label={t('Infos.FirstName')} {...getInputProps(`family.emergencyContacts.${contactIndex}.firstName`)} />
        <TextInput required label={t('Infos.LastName')} {...getInputProps(`family.emergencyContacts.${contactIndex}.lastName`)} />
      </SimpleGrid>

      <Select
        required
        mb={20}
        label={t('contact:Infos.LinkWithChildren', { count })}
        data={[
          { label: t('parent:Link.GrandParent')!, value: LinkWithChildren.GRAND_PARENT },
          { label: t('parent:Link.StepParent')!, value: LinkWithChildren.STEP_PARENT },
          { label: t('parent:Link.UncleAunt')!, value: LinkWithChildren.UNCLE_AUNT },
          { label: t('parent:Link.Sibling')!, value: LinkWithChildren.SIBLING },
          { label: t('parent:Link.Friend')!, value: LinkWithChildren.FRIEND },
          { label: t('parent:Link.Other')!, value: LinkWithChildren.OTHER },
        ]}
        {...getInputProps(`family.emergencyContacts.${contactIndex}.link`)}
      />
      <SimpleGrid mb={10} cols={2}>
        <PhoneInput required label={t('contact:Infos.Cellphone')} {...getInputProps(`family.emergencyContacts.${contactIndex}.cellphone`)} />
        <PhoneInput label={t('contact:Infos.Phone')} {...getInputProps(`family.emergencyContacts.${contactIndex}.phone`)} />
      </SimpleGrid>
    </Box>
  );
}
