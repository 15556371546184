/* eslint-disable @typescript-eslint/naming-convention */
import { createStyles } from '@mantine/core';

export const useNavbarStyles = createStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    height: '5.625rem',

    boxShadow: '0px 3px 5px -5px',

    ':after': {
      content: '""',
      position: 'absolute',
      width: '100%',
      bottom: '1px',
      zIndex: -1,
      transform: 'scale(.9)',
      boxShadow: '0px 3px 5px -5px',
    },
  },
  link: {
    cursor: 'pointer',
    textTransform: 'uppercase',
    fontSize: '.875rem',
    lineHeight: '1.5rem',
    letterSpacing: '1px',
    color: '#545f80',

    display: 'flex',
    alignItems: 'center',
    padding: '0 15px',

    ':hover': {
      textDecoration: 'none',
      color: '#3abb65',
    },
  },

  active: {
    textDecoration: 'none',
    color: '#3abb65',
  },
}));
