export interface RegistrationDto {
  id: string;
  familyId: string;
  campId: string;
  childrenChosenProducts: ChildrenChosenProductDto[];
  totalAmount: number;
  payments: PaymentDto[];
  taxCreditInfos: TaxCreditInfoDto[];
  billingInfo?: BillingInfoDto;
  registeredAt?: string;
}

export interface UpdateRegistrationDto {
  id: string;
  familyId: string;
  campId: string;
  childrenChosenProducts: ChildrenChosenProductDto[];
  taxCreditInfos?: TaxCreditInfoDto[];
  billingInfo?: BillingInfoDto;
  note?: string;
  hasManagementFees?: boolean;
}

export interface ChildrenChosenProductDto {
  childId: string;
  chosenProduct: ChosenProductDto;
  menuOverrides?: MenuOverrideDto[];
  extras?: Record<string, number>;
}

export interface ChosenProductDto {
  productId: string;
  weeksRange: WeekRangeDto[];
}

export interface WeekRangeDto {
  days: string[];
  concentrationId?: string;
}

export interface PaymentDto {
  invoiceId?: string;
  type: PaymentType;
  status: PaymentStatus;
  amount: number;
  paidOn?: string;
  createdOn: string;
  refundAmount?: number;
  note?: string;
  hasManagementFees: boolean;
}

export enum PaymentStatus {
  IN_PROGRESS = 'PENDING',
  COMPLETED = 'PAID',
}

export enum PaymentType {
  // When creating a registration with partial amount (only for registration fees).
  INITIAL_PARTIAL = 'INITIAL_PARTIAL',
  // When creating a registration with total amount.
  INITIAL_TOTAL = 'INITIAL_TOTAL',
  // An invoice. Normally, 3 invoices are created for a registration with partial amount (INITIAL_PARTIAL).
  INVOICE = 'INVOICE',
  // When updating a registration and apply the amount to the upcoming invoices.
  UPDATE_UPCOMING_INVOICE = 'UPDATE_UPCOMING_INVOICE',
  // When updating a registration and apply the amount to a new invoice.
  UPDATE_NEW_INVOICE = 'UPDATE_NEW_INVOICE',
  // When updating a registration and apply the amount to a refund.
  UPDATE_REFUND = 'UPDATE_REFUND',
  // When updating a registration and there is no diff amount.
  UPDATE_NO_DIFF = 'UPDATE_NO_DIFF',
  // Custom payment.
  CUSTOM = 'CUSTOM',
  // Custom payment applied to the upcoming invoices.
  CUSTOM_UPCOMING_INVOICE = 'CUSTOM_UPCOMING_INVOICE',
}

export interface TaxCreditInfoDto {
  firstName: string;
  lastName: string;
  socialNumber: string;
  email: string;
  address: string;
  city: string;
  province: string;
  postalCode: string;
  cellphone: string;
  phone?: string;
}

export interface BillingInfoDto {
  firstName: string;
  lastName: string;
  email: string;
  stripeCustomerId: string;
}

export interface MenuOverrideDto {
  date: string;
  mainCourse: string;
}
