import { useEffect, useState } from 'react';
import { useForm } from '@mantine/form';
import { Box, Button, Group, LoadingOverlay, Stepper } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { nanoid } from 'nanoid';
import { useGetCamps } from 'api/queries';
import { useAppStore } from 'store/store';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { environment } from 'config/environment';
import isNumber from 'lodash/isNumber';
import { validateStep, FullInformation, Schedule, TaxCredit, Payment, Menu } from 'component/registration-step';
import { useMediaQuery } from '@mantine/hooks';

const stripe = loadStripe(environment.stripePublicKey);

export function NewRegistration() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [active, setActive] = useState(0);
  const { currentCamp, selectCamp } = useAppStore((a) => ({
    selectCamp: a.selectCamp,
    currentCamp: a.camp,
  }));
  const { data: camps, isLoading } = useGetCamps();
  const [overlayVisible, setOverlayVisible] = useState(true);
  const [canScrollToError, setCanScrollToError] = useState(true);
  const [params] = useSearchParams();
  const matches = useMediaQuery('(min-width: 764px)');

  const form = useForm({
    initialValues: {
      family: {
        parents: [{}, {}], // at least 2 parents
        children: [{ tempId: nanoid() }],
        legalAuthorizations: {
          authorizedPeople: [],
          unauthorizedPeople: [],
        },
        termsAndConditions: {},
        emergencyContacts: [{}, {}], // at least 2 external contact
      },
      schedule: {
        items: {},
      },
      menu: [],
      taxCreditInfos: [{}],
    },
    validate: (values) => validateStep(active, values),
  });

  const setStep = (index?: number) =>
    setActive((current) => {
      if (isNumber(index) && index < current) {
        return index;
      }

      setCanScrollToError(true);

      // if (form.validate().hasErrors && environment.isProd) {
      if (form.validate().hasErrors) {
        return current;
      }

      window.scrollTo({
        top: 100,
        behavior: 'smooth',
      });

      if (isNumber(index)) {
        return index;
      }

      return current < 5 ? current + 1 : current;
    });

  const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));

  useEffect(() => {
    if (isLoading || !overlayVisible) {
      return;
    }

    setOverlayVisible(false);

    if (camps && camps.length === 0) {
      navigate('/registration/closed');
    }

    if (camps && !params.get('bypass') && camps.every((c) => c.isInactive)) {
      navigate('/registration/closed');
    }
  }, [overlayVisible, camps, isLoading, navigate, params]);

  useEffect(() => {
    if (isLoading || currentCamp) {
      return;
    }

    const chosenCampId = window.localStorage.getItem('chosen-camp');

    if (chosenCampId) {
      const camp = camps?.find((c) => c.id === chosenCampId);

      if (camp) {
        selectCamp(camp);
        form.setFieldValue('camp', camp.id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCamp, camps, isLoading, selectCamp]);

  useEffect(() => {
    if (form.errors && canScrollToError) {
      const firstInvalidElement = document.querySelector('[aria-invalid="true"], .mantine-InputWrapper-error');
      if (firstInvalidElement) {
        firstInvalidElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }

    setCanScrollToError(false);
  }, [form.errors, canScrollToError]);

  useEffect(() => {
    if (environment.isLocal) {
      const storedValue = window.localStorage.getItem('user-form');
      if (storedValue) {
        try {
          form.setValues(JSON.parse(window.localStorage.getItem('user-form') || '{}'));
        } catch (e) {
          /* empty */
        }
      }
    }
    // we want it just once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // each time the form changes, save it
  useEffect(() => {
    // if (environment.isLocal) {
    window.localStorage.setItem('user-form', JSON.stringify(form.values));
    // }
  }, [form.values]);

  return (
    <Elements stripe={stripe}>
      <LoadingOverlay visible={overlayVisible} />
      <Box p={matches ? 40 : 20} sx={{ position: 'relative' }}>
        {environment.isDev && (
          <Button
            sx={{
              position: 'absolute',
              top: 120,
              right: 150,
            }}
            size="sm"
            color="blue"
            variant="outline"
            onClick={() => form.validate()}
          >
            Validate form
          </Button>
        )}
        <Stepper
          onStepClick={(i) => {
            setStep(i);
          }}
          active={active}
          breakpoint="md"
        >
          <Stepper.Step label={t('Form.Steps.Informations')}>
            <FullInformation form={form as any} />
          </Stepper.Step>
          <Stepper.Step label={t('Form.Steps.Schedule')}>
            <Schedule form={form as any} showPartialTotal />
          </Stepper.Step>
          <Stepper.Step label={t('Form.Steps.Menu')}>
            <Menu form={form as any} />
          </Stepper.Step>
          <Stepper.Step label={t('Form.Steps.Billing')}>
            <TaxCredit form={form as any} />
          </Stepper.Step>
          <Stepper.Step label={t('Form.Steps.Payment')}>
            <Payment form={form as any} />
          </Stepper.Step>
        </Stepper>

        <Group position="right" mr="5%" ml="5%" mt={70}>
          {active !== 0 && (
            <Button color="gray" variant="outline" onClick={prevStep}>
              {t('Common.Back')}
            </Button>
          )}
          {active !== 4 && (
            <Button
              variant="outline"
              onClick={() => {
                setStep();
              }}
            >
              {t('Common.NextStep')}
            </Button>
          )}
        </Group>
      </Box>
    </Elements>
  );
}
