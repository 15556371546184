import { useEffect, useMemo, useState } from 'react';
import { Box, Table as MantineTable, Text, TextInput, Select } from '@mantine/core';
import {
  useReactTable,
  Row,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  ColumnDef,
  getFilteredRowModel,
  getPaginationRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  FilterFn,
  TableState,
  ColumnFiltersState,
  Table as ReactTable,
} from '@tanstack/react-table';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useTableStyles } from './table.styles';
import { useDebouncedState } from '@mantine/hooks';
import { ArrowUp, ArrowDown, Pencil, Search } from 'tabler-icons-react';

export interface CustomColumnSizing {
  width?: string;
  minWidth?: string;
  maxWidth?: string;
}

interface ITableProps<T extends object> {
  columns: (ColumnDef<T> & CustomColumnSizing)[];
  data: T[];
  selectedRow?: string;
  withExport?: boolean;
  setFilteredRows?: (rows: T[]) => void;
  defaultSort?: { id: string; desc: boolean };
  hiddenColumns?: Record<string, boolean>;
  actionButtons?: ReactNode[];
  onRowActionClick?: (row: T) => void;
  searchable?: boolean;
  hidePagination?: boolean;
}

interface GlobalFilterProps<T extends object> {
  filteredRows: Row<T>[];
  globalFilter: string | number | readonly string[] | undefined;
  setGlobalFilter: (value: string) => void;
}

// Define a default UI for filtering
function GlobalFilter<T extends object>(props: GlobalFilterProps<T>) {
  const { t } = useTranslation();
  const { classes } = useTableStyles({ hasFilters: false });
  const { globalFilter, setGlobalFilter, filteredRows: preGlobalFilteredRows } = props;
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = useState(globalFilter);
  const [debounced, setDebouncedVal] = useDebouncedState('', 200, undefined);

  useEffect(() => {
    setGlobalFilter(debounced);
  }, [setGlobalFilter, debounced]);

  return (
    <Box className={classes.search}>
      <Box className={classes.searchInput}>
        <Search color="#cecece" />
        <TextInput
          value={value || ''}
          onChange={(e) => {
            setValue(e.target.value);
            setDebouncedVal(e.target.value);
          }}
          placeholder={t('Common.Search')!}
        />
      </Box>
      <Box className={classes.result}>
        <Text>{count}</Text>
        <Text ml={3}>{t('Common.Results')}</Text>
      </Box>
    </Box>
  );
}

export function Table<T extends object>(props: ITableProps<T>) {
  const { t } = useTranslation();
  const [globalFilter, setGlobalFilter] = useState('');
  const [filters, setFilters] = useState<Record<string, string[]>>({});
  const { classes, cx } = useTableStyles({ hasFilters: Object.keys(filters).length > 0 });
  const { columns, data, actionButtons, defaultSort, hiddenColumns, onRowActionClick, searchable = true, hidePagination = false } = props;
  const currentData = useMemo(() => data || [], [data]);

  const globalFilterFn: FilterFn<T> = (row, columnId, filterValue: string) => {
    const search = filterValue.toLowerCase();

    let value = row.getValue(columnId);
    if (typeof value === 'number') value = String(value);

    return (value as string)?.toLowerCase().includes(search);
  };

  const formattedFilters = useMemo(() => {
    if (!Object.keys(filters)?.length) return [];
    const keys = Object.keys(filters);
    return keys.reduce((acc, item) => {
      const obj = { id: item, value: filters[item] };
      acc.push(obj);
      return acc;
    }, [] as ColumnFiltersState);
  }, [filters]);

  const table = useReactTable<T>({
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: !hidePagination ? getPaginationRowModel() : undefined,
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    onGlobalFilterChange: setGlobalFilter,
    columns: columns as any,
    data: currentData,
    autoResetPageIndex: false,
    state: {
      globalFilter,
      columnFilters: formattedFilters,
      // columnFilters: [{ id: 'userType', value: 'participant,partner'}],
    },
    globalFilterFn: globalFilterFn,
    initialState: { pagination: !hidePagination ? { pageSize: 50 } : undefined, columnVisibility: hiddenColumns, sorting: defaultSort ? [defaultSort] : [] },
    enableColumnFilters: true,
  });

  const { getRowModel, getHeaderGroups, getFilteredRowModel: filteredRows, getCanPreviousPage, getCanNextPage, nextPage, previousPage, getState, setPageSize } = table;

  const columnWithSize = useMemo(
    () =>
      columns
        .filter((c) => !!c.id)
        .map((c) => ({ [c.id!]: c }))
        .reduce((prev, val) => Object.assign(prev, val), {}),
    [columns]
  );

  return (
    <>
      {searchable && (
        <Box
          sx={{
            display: 'flex',
          }}
        >
          <GlobalFilter filteredRows={filteredRows().rows} globalFilter={getState().globalFilter} setGlobalFilter={setGlobalFilter} />
        </Box>
      )}

      {actionButtons && <div className="table-buttons">{actionButtons}</div>}

      <Box data-testid="test-table" className={classes.container}>
        <MantineTable highlightOnHover striped>
          <thead>
            {getHeaderGroups().map((headerGroup) => (
              <tr className={classes.header} key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    {...{
                      key: header.id,
                      colSpan: header.colSpan,
                      style: {
                        width: columnWithSize[header.id].width,
                        minWidth: columnWithSize[header.id].minWidth,
                        maxWidth: columnWithSize[header.id].maxWidth,
                      },
                    }}
                  >
                    <Box sx={{ display: 'flex' }}>
                      <Box
                        {...{
                          style: header.column.getCanSort() ? { display: 'flex', alignItems: 'center', cursor: 'pointer', userSelect: 'none' } : {},
                          className: classes.headerText,
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: <ArrowUp style={{ marginLeft: '.375rem' }} />,
                          desc: <ArrowDown style={{ marginLeft: '.375rem' }} />,
                        }[header.column.getIsSorted() as string] ?? null}
                      </Box>
                    </Box>
                  </th>
                ))}
                {onRowActionClick && <th></th>}
              </tr>
            ))}
          </thead>
          <tbody>
            {getRowModel().rows.map((row) => {
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td
                        key={cell.id}
                        className={classes.text}
                        style={{
                          verticalAlign: 'middle',
                        }}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    );
                  })}
                  {onRowActionClick && (
                    <td
                      style={{
                        verticalAlign: 'middle',
                        cursor: 'pointer',
                      }}
                      onClick={() => onRowActionClick(row.original)}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Pencil />
                      </Box>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </MantineTable>
      </Box>

      {!props.hidePagination && (
        <Box className={classes.pagination}>
          <Box className={cx(classes.footerBox, 'start')}>
            <Box className={classes.footerBox}>
              <Text className={classes.footerText}>{`${getRowPosition(true, getState(), filteredRows().rows.length)}`}</Text>
              <Text ml={3} className={classes.footerText}>
                {t('Common.To')}
              </Text>
              <Text ml={3} className={classes.footerText}>{`${getRowPosition(false, getState(), filteredRows().rows.length)}`}</Text>
              <Text ml={3} className={classes.footerText}>
                {t('Common.Of')}
              </Text>
              <Text ml={3} className={classes.footerText}>
                {filteredRows().rows.length}
              </Text>
            </Box>
            <Box>
              <Select
                size="sm"
                value={getState().pagination.pageSize.toString()}
                data={[
                  { value: '50', label: '50' },
                  { value: '75', label: '75' },
                  { value: '100', label: '100' },
                ]}
                onChange={(e) => setPageSize(Number(e))}
              />
            </Box>
          </Box>
          <Box className={classes.footerBox}>
            <Text
              className={cx(classes.footerText, classes.footerTextButton, getCanPreviousPage() ? 'active' : 'inactive')}
              sx={getCanPreviousPage() ? { color: 'blue' } : { color: 'gray', cursor: 'default' }}
              onClick={() => {
                if (!getCanPreviousPage()) return;
                previousPage();
              }}
            >
              {t('Common.Previous')}
            </Text>
            <Text
              ml={21}
              className={cx(classes.footerText, classes.footerTextButton, getCanNextPage() ? 'active' : 'inactive')}
              sx={getCanNextPage() ? { color: 'blue' } : { color: 'gray', cursor: 'default' }}
              onClick={() => {
                if (!getCanNextPage()) return;
                nextPage();
              }}
            >
              {t('Common.Next')}
            </Text>
          </Box>
        </Box>
      )}
    </>
  );
}

function getRowPosition(firstRow: boolean, tableState: TableState, totalRowCount: number) {
  const {
    pagination: { pageIndex, pageSize },
  } = tableState;

  const index = +pageIndex + 1;

  if (index === 1) {
    if (totalRowCount === 0) {
      return 0;
    }

    return firstRow ? 1 : index * pageSize > totalRowCount ? totalRowCount : index * pageSize;
  }

  return firstRow ? pageIndex * pageSize : index * pageSize > totalRowCount ? totalRowCount : index * pageSize;
}
