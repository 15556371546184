import { showNotification } from '@mantine/notifications';
import { Camp } from 'api/models/camp';
import { Child } from 'api/models/child';
import { ChildrenChosenProduct } from 'api/models/children-chosen-product';
import { Family } from 'api/models/family';
import { IWeek, Week } from 'api/models/product';
import { Registration } from 'api/models/registration';
import { addFamily, generatePayment } from 'api/queries';
import { updateRegistration } from 'api/queries/registration';
import { CompleteForm, FullInfoFormStep, InvoiceSchedule, Item, Menu, ProductExtra, ScheduleFormStep, TaxCreditStep } from 'component/registration-step/types';
import dayjs from 'dayjs';
import { nanoid } from 'nanoid';
import { campStore } from 'store/store';
import { encryptData } from 'utils/crypto';
import { getWeek, toDateWithoutTimezone } from 'utils/date';

export class RegistrationService {
  static async register(form: FullInfoFormStep & ScheduleFormStep, payRegistrationFees?: boolean) {
    const camp = campStore.getState().camp;

    if (!camp) {
      showNotification({ title: 'Error', message: 'Missing camp', color: 'red' });
      return;
    }

    const items = Object.values(form.values.schedule.items);
    const menus = form.values.menu;

    const products = camp.products;
    const taxCreditInfos = form.values.taxCreditInfos;
    const partTime = products.find((p) => p.isPartTimeProduct);
    const fullTime = products.find((p) => p.isFullTimeProduct);

    if (!partTime || !fullTime) {
      showNotification({ title: 'Error', message: 'Missing part time or full time product', color: 'red' });
      return;
    }

    const addedFamily = await addFamily(form.values.family as any);

    const productsByChildren: ChildrenChosenProduct[] = this.mapItemsIntoChosenProducts(items, camp, addedFamily.children, menus);

    if (addedFamily.id && camp) {
      await generatePayment({ campId: camp.id, familyId: addedFamily.id, taxCreditInfos, childrenChosenProducts: productsByChildren, onlyRegistrationFees: payRegistrationFees });
    }
  }

  static convertRegistrationToForm(camp: Camp, registration: Registration, family: Family): CompleteForm {
    const items: Record<string, Item> = {};
    const menus: Menu[] = [];

    registration.childrenChosenProducts.forEach((childrenChosenProduct) => {
      const child = family.children.find((child) => child.id === childrenChosenProduct.childId);

      const childMenu =
        childrenChosenProduct.menuOverrides?.map((m) => ({
          date: m.date,
          mainCourse: m.mainCourse,
          child: child!,
        })) || [];

      menus.push(...childMenu);

      if (child) {
        if (!items[child.tempId]) {
          items[child.tempId] = {
            id: child.tempId,
            child: child,
            weeks: {},
            extras: childrenChosenProduct.extras,
          };
        }

        const weeks: Record<string, IWeek> = {};
        childrenChosenProduct.chosenProduct.weeksRange.forEach((w) => {
          weeks[nanoid()] = {
            startDate: dayjs(w.days[0]).startOf('week').format('YYYY-MM-DD'),
            endDate: dayjs(w.days[0]).endOf('week').format('YYYY-MM-DD'),
            days: w.days,
            concentrationId: w.concentrationId,
          };
        });

        items[child.tempId].weeks = { ...items[child.tempId].weeks, ...weeks };
      }
    });

    registration.taxCreditInfos.forEach((tax) => {
      tax.socialNumber = '';
    });

    return {
      camp: camp.id,
      family: family,
      schedule: {
        items,
      },
      menu: menus,
      taxCreditInfos: registration.taxCreditInfos,
      modifications: {},
    };
  }

  static async updateTaxCreditInfos(form: TaxCreditStep, registration: Registration) {
    const taxCreditInfos = form.values.taxCreditInfos;
    for (const taxCreditInfo of taxCreditInfos) {
      taxCreditInfo.socialNumber = encryptData(taxCreditInfo.socialNumber);
    }
    await updateRegistration({ ...registration, taxCreditInfos });
  }

  static async updateRegistrationWithMappedProduct(camp: Camp, registration: Registration, form: InvoiceSchedule) {
    const note = form.values.modifications?.note;
    const hasManagementFees = form.values.modifications?.hasManagementFees;
    const chosenProducts: Item[] = Object.values(form.values.schedule.items);
    const menus: Menu[] = form.values.menu;
    const family = form.values.family as Family;

    const childrenChosenProducts = this.mapItemsIntoChosenProducts(chosenProducts, camp, family.children, menus);
    const childrenChosenProductsDto = childrenChosenProducts.map((c) => ChildrenChosenProduct.toDto(c));
    await updateRegistration({ id: registration.id, familyId: family.id!, campId: camp.id, childrenChosenProducts: childrenChosenProductsDto, note, hasManagementFees: hasManagementFees });
  }

  static mapItemsIntoChosenProducts(items: Item[], camp: Camp, children: Child[], menus: Menu[]): ChildrenChosenProduct[] {
    const closedOn = camp.closedOn;
    const products = camp.products;
    const productsByChildren: ChildrenChosenProduct[] = [];

    const partTime = products.find((p) => p.isPartTimeProduct);
    const fullTime = products.find((p) => p.isFullTimeProduct);

    if (!partTime || !fullTime) {
      throw new Error('Missing part time or full time product');
    }

    items.forEach((p) => {
      const firstName = p.child.firstName;
      const lastName = p.child.lastName;
      const foundChildren = children.find((c) => c.firstName === firstName && c.lastName === lastName);
      const item = Object.values(items).find((i) => i.child.firstName === firstName && i.child.lastName === lastName);

      if (foundChildren && item) {
        const fullWeeks = [];
        const partTimeWeeks = [];

        for (const week of Object.values(item.weeks).map((w) => new Week(w))) {
          if (week.isFullWeek(camp.startDate, camp.endDate, closedOn)) {
            fullWeeks.push(week.toDto());
          } else {
            partTimeWeeks.push(week.toDto());
          }
        }

        const allOverrides = menus.filter((m) => m.child.firstName === foundChildren.firstName && m.child.lastName === foundChildren.lastName);

        if (fullWeeks.length) {
          const days = fullWeeks.map((w) => w.days).flat();
          const overrides = allOverrides.filter((o) => days.includes(o.date));

          productsByChildren.push({
            child: foundChildren,
            chosenProduct: {
              productId: fullTime.id,
              weeksRange: fullWeeks.map((w) => ({
                days: w.days,
                concentrationId: w.concentrationId,
              })),
            },
            childId: foundChildren.id,
            menuOverrides: overrides,
            extras: p.extras,
          });
        }

        if (partTimeWeeks.length) {
          const days = partTimeWeeks.map((w) => w.days).flat();
          const overrides = allOverrides.filter((o) => days.includes(o.date));

          productsByChildren.push({
            child: foundChildren,
            chosenProduct: {
              productId: partTime.id,
              weeksRange: partTimeWeeks.map((w) => ({
                days: w.days,
                concentrationId: w.concentrationId,
              })),
            },
            childId: foundChildren.id,
            menuOverrides: overrides,
            extras: p.extras,
          });
        }
      }
    });
    return productsByChildren;
  }

  static calculateTotal(camp: Camp, items: Record<string, Item>, closedOn?: string[], registration?: Registration) {
    let total = camp.registrationFees; // inscription
    Object.values(items).forEach((item) => {
      total += this.calculateSubtotal(camp, item, closedOn);
    });

    if (registration) {
      total += registration.calculateCustomPayments() + registration.calculateManagementFees(camp.managementFees || 0);
    }

    return total; // registration;
  }

  static calculateSubtotal(camp: Camp, item: Partial<Item>, closedOn?: string[]) {
    return Object.values(item.weeks || {}).reduce((acc, week) => acc + this.calculateWeek(week, camp, closedOn) + this.calculateExtraTotal(week, item.extras), 0);
  }

  static calculateWeek(week: IWeek, camp: Camp, closedOn?: string[], extras?: Partial<Record<ProductExtra, number>>) {
    const { days, concentrationId } = week;
    const products = camp.products;
    const concentrations = camp.concentrations;

    const numberOfDaysInWeek = getWeek(toDateWithoutTimezone(days[0]), camp.startDate, camp.endDate, closedOn).days.length;
    const partTime = products.find((p) => p.isPartTimeProduct)?.pricePerDay || 0;
    const fullTime = products.find((p) => p.isFullTimeProduct)?.pricePerDay || 0;

    let total = days.length === numberOfDaysInWeek ? fullTime * days.length : partTime * days.length;

    const concentration = concentrations.find((c) => c.id === concentrationId);

    if (concentration) {
      total = total + concentration.price;
    }

    if (extras) {
      if (extras.glutenFree) {
        total += extras.glutenFree * days.length;
      }

      if (extras.vegetarian) {
        total += extras.vegetarian * days.length;
      }
    }

    return total;
  }

  static calculateExtraTotal(week: IWeek, extras?: Partial<Record<ProductExtra, number>>) {
    const { days } = week;

    let total = 0;

    if (extras) {
      if (extras.glutenFree) {
        total += extras.glutenFree * days.length;
      }

      if (extras.vegetarian) {
        total += extras.vegetarian * days.length;
      }
    }

    return total;
  }
}
