import { ConcentrationDto } from 'api/dto';

export class Concentration {
  id: string;
  campId: string;
  nameEn: string;
  nameFr: string;
  price: number;

  constructor(concentration: ConcentrationDto) {
    this.id = concentration.id;
    this.campId = concentration.campId;
    this.nameEn = concentration.nameEn;
    this.nameFr = concentration.nameFr;
    this.price = concentration.price;
  }
}
