/* eslint-disable @typescript-eslint/naming-convention */
import { createStyles } from '@mantine/core';

export const useFormStyles = createStyles(() => ({
  boxContainer: {
    borderRadius: '10px',
    boxShadow: 'rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;',

    label: {
      marginBottom: '5px',
    },

    '.mantine-InputWrapper-label': {
      marginBottom: '5px',
    },

    padding: 20,
    marginBottom: 30,
  },
  header: {
    textAlign: 'center',
  },
  terms: {
    display: 'flex',
  },
}));
