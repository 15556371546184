/* eslint-disable func-names */
import { resetRequestInterceptor, setRequestInterceptor } from '../axios';
import { HubCallback, HubCapsule } from '@aws-amplify/core';

export const hubCallback = (logout: () => Promise<void>): HubCallback => {
  return function ({ payload: { event } }: HubCapsule) {
    switch (event) {
      case 'signOut': {
        // reset so we can stop any request
        resetRequestInterceptor();

        // we need it back so we can make request with a new bearer
        // setRequestInterceptor(logout);
        break;
      }
      case 'cognitoHostedUI_failure': {
        break;
      }
      default: {
        break;
      }
    }
  };
};
