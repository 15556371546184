import { Button } from '@mantine/core';
import { FamilyDto } from 'api/dto';
import { Camp } from 'api/models/camp';
import { Registration } from 'api/models/registration';
import { sendInvoice } from 'api/queries/invoice';
import { InvoiceSchedule } from 'component/registration-step/types';
import { useTranslation } from 'react-i18next';

interface UpdateButtonProps {
  registration?: Registration;
  camp?: Camp;
  form: InvoiceSchedule;
  close?: () => void;
  withSendInvoice?: boolean;
  color?: string;

  isLoading: boolean;
  toggleLoading: () => void;

  updateFunction: () => Promise<void>;
}

export function UpdateButton({ registration, camp, form, close, withSendInvoice, color, isLoading, toggleLoading, updateFunction }: UpdateButtonProps) {
  const { t } = useTranslation();

  async function update() {
    toggleLoading();
    // TODO fix typings

    const family: FamilyDto = form.values.family as any;

    if (family && registration && camp) {
      await updateFunction();

      if (withSendInvoice && registration.billingInfo) {
        await sendInvoice(registration.id, registration.campId, registration.billingInfo.email);
      }
    }
    toggleLoading();
  }

  return (
    <Button
      loaderPosition="right"
      loading={isLoading}
      size="xs"
      color={color || 'green'}
      onClick={async () => {
        await update();
        close?.();
        form.setFieldValue('modifications', { amount: 0 });
      }}
    >
      {withSendInvoice ? t('admin:Registration.UpdateWithSendInvoice') : t('admin:Registration.Update')}
    </Button>
  );
}
