export enum ProductType {
  WEEKLY = 'WEEKLY',
  ALL_SUMMER = 'ALL_SUMMER',
  PART_TIME = 'PART_TIME',
}

export interface ProductDto {
  id: string;
  campId: string;
  nameEn: string;
  nameFr: string;
  pricePerDay: number;
  type: ProductType;
}
