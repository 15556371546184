import { useEffect, useMemo, useState } from 'react';
import { ActionIcon, Box, Button, Center, Group, Loader, LoadingOverlay, Tabs, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useGetCamps, useGetFamily } from 'api/queries';
import { useAppStore } from 'store/store';
import { useNavigate, useParams } from 'react-router-dom';
import { environment } from 'config/environment';
import { useMediaQuery } from '@mantine/hooks';
import { useGetRegistration } from 'api/queries/registration';
import { useForm } from '@mantine/form';
import { Schedule, Menu as MenuComponent, TaxCredit, FullInformation } from 'component/registration-step';
import { ArrowLeft } from 'tabler-icons-react';
import { Modification } from './modification';
import { Payments } from './payments';
import { PreviewModal } from './preview';
import { Invoices } from './invoices';
import { RegistrationService } from 'api/service/registration-service';

const stripe = loadStripe(environment.stripePublicKey);

export function Registration() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const matches = useMediaQuery('(min-width: 764px)');

  const { familyId, registrationId } = useParams();

  const { camp: currentCamp, selectCamp } = useAppStore();

  const { data: camps, isLoading: isLoadingCamps } = useGetCamps();
  const { data: family, isLoading: isLoadingFamily } = useGetFamily(familyId!);
  const { data: registration, isLoading: isLoadingRegistration } = useGetRegistration(familyId!, registrationId!, family);

  const [overlayVisible, setOverlayVisible] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [previewModalOpened, setPreviewModalOpened] = useState(false);

  const familyName = useMemo(() => {
    if (!family || !registration) {
      return '';
    }

    const childId = registration.childrenChosenProducts.length > 0 ? registration.childrenChosenProducts[0].childId : undefined;

    if (childId) {
      const child = family.children.find((c) => c.id === childId);
      if (child) {
        return child?.lastName;
      }
    }

    return '';
  }, [family, registration]);

  const form = useForm<any>({
    validate: {
      ['modifications.note']: (value) => {
        if (!value) {
          return 'Une note est requise';
        }
        return null;
      },
    },
  });

  const currentFormRegistrationAmount = useMemo(
    () => (currentCamp && form.values.schedule ? RegistrationService.calculateTotal(currentCamp, form.values.schedule.items, currentCamp.closedOn, registration) : 0),
    [form, currentCamp, registration]
  );

  const diff = useMemo(() => (registration?.totalAmount || 0) - currentFormRegistrationAmount, [registration, currentFormRegistrationAmount]);

  useEffect(() => {
    if (isLoadingCamps || !camps || currentCamp) {
      return;
    }

    const camp = camps.find((c) => c.id === registration?.campId);
    if (camp) {
      selectCamp(camp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [camps, isLoadingCamps, registration?.campId]);

  useEffect(() => {
    if (isLoaded || isLoadingCamps || isLoadingRegistration || isLoadingFamily) {
      return;
    }

    if (registration && family && currentCamp) {
      const converted = RegistrationService.convertRegistrationToForm(currentCamp, registration, family);
      form.setValues(converted);

      setIsLoaded(true);
      setOverlayVisible(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [camps, selectCamp, isLoadingCamps, isLoadingFamily, isLoadingRegistration, registration, family, currentCamp, isLoaded]);

  if (!isLoaded) {
    return (
      <Group
        sx={{
          height: '60vh',
          justifyContent: 'center',
        }}
      >
        <Center>
          <Loader size="xl" />
        </Center>
      </Group>
    );
  }

  return (
    <Elements stripe={stripe}>
      <LoadingOverlay visible={overlayVisible} />
      <Box component="h2" ml={matches ? 40 : 20} display="flex" sx={{ alignItems: 'center' }}>
        <ActionIcon radius={6} p={3} color="blue" variant="filled" onClick={() => navigate(-1)}>
          <ArrowLeft />
        </ActionIcon>
        <Text ml={10} display="flex">
          {t('admin:Infos.Family')}
          <Text italic color="blue" ml={5} sx={{ fontFamily: 'Happy Monkey' }}>
            {familyName}
          </Text>
        </Text>
      </Box>
      <Box p={matches ? 40 : 20} pt={10} sx={{ position: 'relative' }}>
        <Tabs variant="outline" radius={10} defaultValue="registration">
          <Tabs.List>
            <Tabs.Tab p={10} value="registration">
              {t('admin:Tabs.Registration')}
            </Tabs.Tab>
            {environment.isLocal && (
              <Tabs.Tab p={10} value="infos">
                {t('admin:Tabs.Infos')}
              </Tabs.Tab>
            )}

            <Tabs.Tab p={10} value="billing">
              {t('admin:Tabs.Billing')}
            </Tabs.Tab>
            <Tabs.Tab p={10} value="menu">
              {t('admin:Tabs.Menu')}
            </Tabs.Tab>
            <Tabs.Tab p={10} value="invoices">
              {t('admin:Tabs.Invoices')}
            </Tabs.Tab>
            <Tabs.Tab p={10} value="payments">
              {t('admin:Tabs.Payments')}
            </Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value="registration">
            <Schedule form={form} registration={registration}>
              <Box mt={20}>
                <Modification form={form} diff={diff} />
              </Box>
              <Box mt={20} display="flex" sx={{ justifyContent: 'flex-end' }}>
                <Button
                  size="xs"
                  variant="outline"
                  color="green"
                  onClick={() => {
                    form.validate();
                    if (form.isValid()) {
                      setPreviewModalOpened(true);
                    }
                  }}
                >
                  {t('admin:Registration.ModifyRegistration')}
                </Button>
              </Box>
            </Schedule>
          </Tabs.Panel>
          {environment.isLocal && (
            <Tabs.Panel value="infos">
              <FullInformation form={form} />
            </Tabs.Panel>
          )}
          <Tabs.Panel value="menu">
            <MenuComponent form={form}>
              <Box mt={20}>
                <Modification form={form} diff={diff} />
              </Box>
              <Box mt={20} display="flex" sx={{ justifyContent: 'flex-end' }}>
                <Button
                  size="xs"
                  variant="outline"
                  color="green"
                  onClick={() => {
                    form.validate();
                    if (form.isValid()) {
                      setPreviewModalOpened(true);
                    }
                  }}
                >
                  {t('admin:Registration.ModifyRegistration')}
                </Button>
              </Box>
            </MenuComponent>
          </Tabs.Panel>
          <Tabs.Panel value="payments">
            <Box mt={20}>
              <Payments form={form} registration={registration!} />
            </Box>
          </Tabs.Panel>
          <Tabs.Panel value="billing">
            <TaxCredit form={form as any} withUpdate={{ registration }} />
          </Tabs.Panel>
          <Tabs.Panel value="invoices">
            <Box mt={20}>
              <Invoices registration={registration!} />
            </Box>
          </Tabs.Panel>
        </Tabs>
        <PreviewModal form={form} opened={previewModalOpened} registration={registration} toggle={() => setPreviewModalOpened(false)} />
      </Box>
    </Elements>
  );
}
