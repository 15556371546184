/* eslint-disable @typescript-eslint/naming-convention */
import { Box, Modal } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { addCustomPayment } from 'api/queries';
import { useGetInvoiceTemplate } from 'api/queries/invoice';
import { queryClient } from 'api/query-client';
import { useCallback, useEffect, useState } from 'react';
import { useAppStore } from 'store/store';
import { Invoice, Modification } from 'utils/invoice';
import { UpdateButton } from './update-button';
import { Registration } from 'api/models/registration';

interface PreviewModalProps {
  form: UseFormReturnType<any, (values: any) => any>;
  registration?: Registration;
  modification?: Modification;
  opened: boolean;
  toggle: () => void;
  toggleFirstModal: () => void;
}

export function CustomPreviewModal({ form, modification, registration, opened, toggle, toggleFirstModal }: PreviewModalProps) {
  const { camp } = useAppStore();
  const { data: template } = useGetInvoiceTemplate();
  const [preview, setPreview] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const toggleIsLoading = () => setIsLoading((l) => !l);

  const addPaymentToRegistration = useCallback(
    async (values: Modification) => {
      setIsLoading(true);
      if (camp && registration && values) {
        await addCustomPayment(camp.id, registration.id, values);

        await queryClient.invalidateQueries({ queryKey: [`registrations-${registration.familyId}-${registration.id}`] });
      }

      toggle();
      setIsLoading(false);
    },
    [toggle, camp, registration]
  );

  const updateFunction = useCallback(async () => {
    if (modification) {
      await addPaymentToRegistration(modification);
      toggleFirstModal();
    }
  }, [modification, addPaymentToRegistration, toggleFirstModal]);

  useEffect(() => {
    if (camp && registration && template) {
      const newPreview = new Invoice(camp, registration, template, camp.concentrations, form, modification, camp.closedOn);

      newPreview.preview = newPreview.preview.replaceAll('<td>', '<td style="white-space: pre-line">');

      setPreview(newPreview.preview);
    }
  }, [template, registration, camp, form, modification]);

  return (
    <Modal size="calc(100vw - 15rem)" opened={opened} withCloseButton onClose={toggle}>
      <div id="preview" dangerouslySetInnerHTML={{ __html: preview }} />
      <Box mt={20} display="flex" sx={{ justifyContent: 'flex-end' }}>
        {registration?.billingInfo && (
          <Box mr={10}>
            <UpdateButton
              updateFunction={updateFunction}
              isLoading={isLoading}
              toggleLoading={toggleIsLoading}
              form={form}
              camp={camp}
              registration={registration}
              close={toggle}
              withSendInvoice
              color="blue"
            />
          </Box>
        )}
        <UpdateButton updateFunction={updateFunction} isLoading={isLoading} toggleLoading={toggleIsLoading} form={form} camp={camp} registration={registration} close={toggle} />
      </Box>
    </Modal>
  );
}
