/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { Box, Button, Text } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { ColumnDef } from '@tanstack/react-table';
import { PaymentDto } from 'api/dto/registration';
import { CustomColumnSizing, Table } from 'component/table/table';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime, DateTimeFormat } from 'utils/datetime';
import { Modification } from 'utils/invoice';
import { getTotalPaidAmount } from 'utils/payment';
import { CustomPayment } from './custom-payment';
import { CustomPreviewModal } from './custom-preview';
import { Registration } from 'api/models/registration';

export function Payments({ registration, form }: { registration: Registration; form: UseFormReturnType<any, (values: any) => any> }) {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);
  const [previewOpened, setPreviewOpened] = useState(false);
  const [modification, setModification] = useState<Modification>({ amount: 0, note: '' });

  const columns: (ColumnDef<PaymentDto, string | number | unknown> & CustomColumnSizing)[] = useMemo(
    () => [
      {
        id: 'type',
        accessorKey: 'type',
        header: t('admin:Payment.Columns.Type') || '',
        cell: (r) => t(`admin:Payment.Types.${r.row.original.type}`),
      },
      {
        id: 'amount',
        accessorKey: 'amount',
        header: t('admin:Payment.Columns.Amount') || '',
        cell: (r) => r.row.original.amount.toFixed(2) + '$',
      },
      {
        id: 'status',
        accessorKey: 'status',
        header: t('admin:Payment.Columns.Status') || '',
        cell: (r) => t(`admin:Payment.Status.${r.row.original.status}`),
      },
      {
        id: 'note',
        accessorKey: 'note',
        header: t('admin:Payment.Columns.Note') || '',
      },
      {
        id: 'createdOn',
        accessorKey: 'createdOn',
        header: t('admin:Payment.Columns.CreatedAt') || '',
        cell: (r) => (r.row.original.createdOn ? DateTime.fromISOString(r.row.original.createdOn).toFormat(DateTimeFormat.YearMonthDayAndTime) : undefined),
      },
      {
        id: 'paidOn',
        accessorKey: 'paidOn',
        header: t('admin:Payment.Columns.ProcessedOn') || '',
        cell: (r) => (r.row.original.paidOn ? DateTime.fromISOString(r.row.original.paidOn).toFormat(DateTimeFormat.YearMonthDayAndTime) : undefined),
      },
    ],
    [t]
  );

  return (
    <>
      <Box mb={20} sx={{ display: 'flex', justifyContent: 'space-between', padding: 15, border: '1px solid #cecece', borderRadius: '10px' }}>
        <Box display="flex">
          <Text>{t('admin:Payment.FollowUp')}</Text>
          <Text ml={10}>{getTotalPaidAmount(registration.payments).toFixed(2)}$</Text>
          <Text ml={4} mr={4}>
            /
          </Text>
          <Text>{registration.totalAmount.toFixed(2)}$</Text>
        </Box>
        <Box>
          <Button size="xs" variant="outline" color="green" onClick={() => setOpened(true)}>
            {t('admin:Payment.AddPayment')}
          </Button>
        </Box>
      </Box>
      <Table<PaymentDto> defaultSort={{ id: 'createdOn', desc: false }} columns={columns} data={registration.payments} />
      <CustomPayment
        modification={modification}
        setModification={setModification}
        showPreview={() => setPreviewOpened(true)}
        opened={opened}
        toggle={() => setOpened(false)}
        registration={registration}
      />
      <CustomPreviewModal form={form} opened={previewOpened} toggleFirstModal={() => setOpened(false)} toggle={() => setPreviewOpened(false)} modification={modification} registration={registration} />
    </>
  );
}
