/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { ActionIcon, Box, Button, LoadingOverlay, Menu, Text } from '@mantine/core';
import { ColumnDef } from '@tanstack/react-table';
import { CampDto } from 'api/dto';
import { PaymentType, RegistrationDto } from 'api/dto/registration';
import { useGetCamps } from 'api/queries';
import { generateDayCareTaxReport, generateDaycareReport, generateRegistrationReport, generateTaxReport, useGetRegistrations } from 'api/queries/registration';
import { CustomColumnSizing, Table } from 'component/table/table';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Pencil, Trash } from 'tabler-icons-react';
import { DateTime, DateTimeFormat } from 'utils/datetime';
import { getTotalPaidAmount } from 'utils/payment';
import { DeleteRegistrationModal } from './delete-registration-modal';
import { SendInvoiceModal } from './send-invoice-modal';
import { MenuModal } from 'component/modal/menu-modal';
import { toDateWithoutTimezone } from 'utils/date';
import { useAppStore } from 'store/store';

type AugmentedRegistration = RegistrationDto & { fullName: string | null; camp: string; paid: number; nextInstalment: string };
type AugmentedColumn = ColumnDef<AugmentedRegistration, string | number | unknown> & CustomColumnSizing;

export default function Registrations() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: camps, isFetching: isFetchingCamps } = useGetCamps();
  const { data: registrations, isFetching } = useGetRegistrations();
  const [selectedCampId, setSelectedCampId] = useState<string | undefined>(undefined);
  const [menuModalOpened, setMenuModalOpened] = useState(false);
  const { selectCamp } = useAppStore();

  const augmentedRegistrations = useMemo(() => {
    if (isFetchingCamps) {
      return [];
    }

    const campsMap = camps!.reduce((map, obj) => {
      map[obj.id] = obj;
      return map;
    }, {} as Record<string, CampDto>);

    const augmented =
      registrations?.map((r) => {
        const hasInvoices = r.payments.filter((r) => r.type === PaymentType.INVOICE).length > 0;
        const currentPayments = r.payments.filter((r) => r.type === PaymentType.INVOICE && r.paidOn != null);
        const currentCamp = campsMap[r.campId];

        const instalmentDates = currentCamp.invoiceDates.map((d) => toDateWithoutTimezone(d)).sort((a, b) => a.getTime() - b.getTime());
        const nextInstalment = hasInvoices ? (currentPayments.length < instalmentDates.length ? instalmentDates[currentPayments.length] : '') : '';

        return {
          ...r,
          email: r.billingInfo?.email || '',
          fullName: `${r.billingInfo?.firstName} ${r.billingInfo?.lastName}`,
          registeredAt: r.registeredAt || '',
          camp: campsMap[r.campId].nameFr || 'NA',
          paid: getTotalPaidAmount(r.payments),
          nextInstalment: typeof nextInstalment == 'string' ? nextInstalment : DateTime.fromISOString(nextInstalment.toISOString(), true).toFormat(DateTimeFormat.YearMonthAndDay),
        };
      }) || [];

    return augmented;
  }, [camps, isFetchingCamps, registrations]);

  const [selectedRegistration, setSelectedRegistration] = useState<RegistrationDto | undefined>();
  const [sendInvoiceModalOpened, setSendInvoiceModalOpened] = useState(false);
  const [deleteRegistrationModalOpened, setDeleteRegistrationModalOpened] = useState(false);

  const deleteRegistration = (registration: RegistrationDto) => {
    setSelectedRegistration(registration);
    setDeleteRegistrationModalOpened(true);
  };

  const columns: AugmentedColumn[] = useMemo(
    () => [
      {
        id: 'familyId',
        accessorKey: 'familyId',
        cell: (r) => r.row.original.familyId,
      },
      {
        id: 'fullName',
        header: t('admin:Registration.Columns.FullName') || '',
        cell: (r) => r.row.original.fullName,
        accessorKey: 'fullName',
      },
      {
        id: 'infos',
        header: t('admin:Registration.Columns.Email') || '',
        cell: (r) => r.row.original.billingInfo?.email,
        accessorKey: 'email',
      },
      {
        id: 'camp',
        header: t('admin:Registration.Columns.Camp') || '',
        cell: (r) => r.row.original.camp,
        accessorKey: 'camp',
      },
      {
        id: 'nextInstalment',
        header: t('admin:Registration.Columns.NextInstalment') || '',
        accessorKey: 'nextInstalment',
        cell: (r) => {
          const hasLateInstalment = dayjs().isAfter(r.row.original.nextInstalment);

          return <Text color={hasLateInstalment ? 'red' : 'black'}>{r.row.original.nextInstalment}</Text>;
        },
        maxWidth: '100px',
      },
      {
        id: 'paid',
        header: t('admin:Registration.Columns.Paid') || '',
        accessorKey: 'paid',
        cell: (r) => {
          return <Text color={r.row.original.paid >= r.row.original.totalAmount ? 'green' : 'black'}>{r.row.original.paid.toFixed(2)}$</Text>;
        },
        maxWidth: '100px',
      },
      {
        id: 'totalAmount',
        header: t('admin:Registration.Columns.TotalAmount') || '',
        accessorKey: 'totalAmount',
        cell: (r) => r.row.original.totalAmount.toFixed(2) + '$',
        maxWidth: '100px',
      },
      {
        id: 'registeredAt',
        header: t('admin:Registration.Columns.RegisteredAt') || '',
        accessorKey: 'registeredAt',
        cell: (r) => dayjs(r.row.original.registeredAt).format('YYYY-MM-DD'),
      },
      {
        id: 'actions',
        cell: (r) => (
          <Box display="flex">
            <ActionIcon
              color="dark"
              onClick={() => {
                selectCamp(camps?.find((x) => x.id === r.row.original.campId));
                navigate(`/admin/registrations/${r.row.original.familyId}/${r.row.original.id}`);
              }}
            >
              <Pencil />
            </ActionIcon>
            <ActionIcon color="dark" ml={5} onClick={() => deleteRegistration(r.row.original)}>
              <Trash />
            </ActionIcon>
          </Box>
        ),
      },
    ],
    [t, navigate]
  );

  return (
    <Box mr="2%" ml="2%" p={30}>
      <Box>
        <Text mb={20} size="lg">
          {t('admin:Registrations')}
        </Text>
        <Menu>
          <Menu.Target>
            <Button size="sm" variant="outline" mb={20}>
              {t('admin:Report.GenerateConcentrationReport')}
            </Button>
          </Menu.Target>
          <Menu.Dropdown>
            {camps?.map((c) => (
              <Menu.Item onClick={() => generateRegistrationReport(c.id)}>{c.nameFr}</Menu.Item>
            ))}
          </Menu.Dropdown>
        </Menu>
        <Menu>
          <Menu.Target>
            <Button size="sm" variant="outline" ml={20}>
              {t('admin:Report.GenerateMenuReport')}
            </Button>
          </Menu.Target>
          <Menu.Dropdown>
            {camps?.map((c) => (
              <Menu.Item
                onClick={() => {
                  setSelectedCampId(c.id);
                  setMenuModalOpened(true);
                }}
              >
                {c.nameFr}
              </Menu.Item>
            ))}
          </Menu.Dropdown>
        </Menu>
        <Menu>
          <Menu.Target>
            <Button size="sm" variant="outline" ml={20}>
              {t('admin:Report.GenerateTaxReport')}
            </Button>
          </Menu.Target>
          <Menu.Dropdown>
            {camps?.map((c) => (
              <Menu.Item onClick={() => generateTaxReport(c.id)}>{c.nameFr}</Menu.Item>
            ))}
            <Menu.Item onClick={() => generateDayCareTaxReport()}>{t('admin:Report.GenerateTaxReportDaycare')}</Menu.Item>
          </Menu.Dropdown>
        </Menu>
        <Button size="sm" variant="outline" ml={20} onClick={() => generateDaycareReport()}>
          {t('admin:Report.GenerateDaycareReport')}
        </Button>
      </Box>

      <Box>
        <LoadingOverlay visible={isFetching || isFetchingCamps} />
        <Table<AugmentedRegistration>
          hiddenColumns={{ familyId: false }}
          columns={columns}
          defaultSort={{
            id: 'registeredAt',
            desc: true,
          }}
          data={augmentedRegistrations}
        />
        <SendInvoiceModal opened={sendInvoiceModalOpened} toggle={() => setSendInvoiceModalOpened(false)} registration={selectedRegistration} />
        <DeleteRegistrationModal opened={deleteRegistrationModalOpened} toggle={() => setDeleteRegistrationModalOpened(false)} registration={selectedRegistration} />
        <MenuModal opened={menuModalOpened} toggle={() => setMenuModalOpened(false)} campId={selectedCampId} />
      </Box>
    </Box>
  );
}
