import { Box, Button, Group, Modal, NumberInput, Switch, Text, Textarea } from '@mantine/core';
import { RegistrationDto } from 'api/dto/registration';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modification } from 'utils/invoice';

interface CustomPaymentProps {
  registration: RegistrationDto;
  opened: boolean;
  toggle: () => void;
  showPreview: () => void;
  modification: Modification;
  setModification: Dispatch<SetStateAction<Modification>>;
}

export function CustomPayment({ opened, toggle, showPreview, setModification }: CustomPaymentProps) {
  const { t } = useTranslation();

  const [isReimbursing, setIsReimbursing] = useState(true);

  useEffect(() => {
    if (isReimbursing) {
      setModification?.((m) => ({ ...m, amount: -m.amount }));
    } else {
      setModification?.((m) => ({ ...m, amount: Math.abs(m.amount) }));
    }
  }, [isReimbursing, setModification]);

  return (
    <Modal size="xl" opened={opened} onClose={toggle} title={t('admin:Payment.CustomPayment')}>
      <Switch label={isReimbursing ? t('admin:Payment.Custom.ToRefund') : t('admin:Payment.Custom.ToNotice')} checked={isReimbursing} onChange={(e) => setIsReimbursing(e.currentTarget.checked)} />
      <Textarea required minRows={4} label={t('admin:Payment.Custom.Note')} onChange={(e) => setModification?.((m) => ({ ...m, note: e.target.value }))} />
      <Box mt={20} display="flex" sx={{ justifyContent: 'flex-end' }}>
        <NumberInput
          min={0}
          rightSection={<Text>$</Text>}
          w={100}
          precision={2}
          label={t('admin:Payment.Custom.Amount')}
          onChange={(e) => e && setModification?.((m) => ({ ...m, amount: isReimbursing ? e - e * 2 : e || 0 }))}
        />
      </Box>

      <Group mt={20} position="right">
        <Button size="xs" color="green" onClick={() => showPreview()}>
          {t('admin:Preview.ShowPreview')}
        </Button>
      </Group>
    </Modal>
  );
}
