export function deleteEmptyStringProperties(obj: Record<string, any>) {
  for (const [key, value] of Object.entries(obj)) {
    if (typeof value === 'string' && value === '') {
      delete obj[key];
    } else if (Array.isArray(value)) {
      value.forEach((item, index) => {
        if (typeof item === 'string' && item === '') {
          obj[key][index] = null;
        } else if (typeof item === 'object' && item !== null) {
          deleteEmptyStringProperties(item);
        }
      });
      obj[key] = obj[key].filter((item: any) => item !== null);
    } else if (typeof value === 'object' && value !== null) {
      deleteEmptyStringProperties(value);
      if (Object.keys(value).length === 0) {
        delete obj[key];
      }
    }
  }
}
