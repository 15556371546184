import { Box, Select, Text } from '@mantine/core';
import { ColumnDef } from '@tanstack/react-table';
import { Child, IChild } from 'api/models/child';
import { IWeek } from 'api/models/product';
import { RegistrationService } from 'api/service/registration-service';
import { ScheduleFormStep } from 'component/registration-step/types';
import { Table } from 'component/table/table';
import dayjs from 'dayjs';
import capitalize from 'lodash/capitalize';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppStore } from 'store/store';

export function ScheduleTable({ form, child, isEditable, isFromAdmin }: { form: ScheduleFormStep; child: IChild; isEditable?: boolean; isFromAdmin?: boolean }) {
  const { t } = useTranslation();
  const items = form.values.schedule.items;
  const item = items[child.tempId];

  const camp = useAppStore((a) => a.camp);
  const concentrations = useMemo(() => camp?.concentrations || [], [camp]);

  const columns: (ColumnDef<IWeek> & { width?: string; minWidth?: string })[] = [
    {
      id: 'date',
      accessorKey: 'startDate',
      header: t('schedule:StartDate')!,
      cell: (props) => {
        return <Text>{dayjs(props.getValue() as string).format('YYYY-MM-DD')}</Text>;
      },
      width: '100px',
    },
    {
      id: 'days',
      accessorKey: 'days',
      header: t('schedule:Days')!,
      cell: (props) => {
        const days = props.getValue() as string[];
        return (
          <Box display="flex">
            {days.map((d, i) => (
              <Text mr={5}>
                {capitalize(dayjs(d).format('dddd'))}
                {days.length > 1 && i < days.length - 1 ? ',' : ''}
              </Text>
            ))}
          </Box>
        );
      },
      width: '30%',
    },
    {
      id: 'concentration',
      accessorKey: 'concentrationId',
      header: t('schedule:Concentration')!,
      cell: (props) => {
        const concentrationId = props.getValue() as string;
        const currentChild = new Child(child);
        return (
          <Box pr={20}>
            {(isEditable && currentChild.canHaveConcentration()) || isFromAdmin ? (
              <Select
                value={concentrationId || '-1'}
                maw={300}
                onChange={(value) => {
                  const items = form.values.schedule.items;
                  const item = items[child.tempId];
                  const weekDatesMap: Record<string, IWeek> = item?.weeks ? { ...item?.weeks } : {};
                  const foundWeek = Object.values(weekDatesMap).find((w) => w.startDate === props.row.original.startDate);
                  if (foundWeek) {
                    foundWeek.concentrationId = value ?? undefined;
                  } else {
                    weekDatesMap[props.row.original.startDate] = {
                      ...weekDatesMap[props.row.original.startDate],
                      concentrationId: value,
                    } as IWeek;
                  }

                  const newItems = { ...items, [child.tempId]: { ...item, weeks: weekDatesMap } };
                  form.setFieldValue('schedule.items', newItems);
                }}
                data={[...concentrations, { nameFr: 'Aucune', id: '-1' }].map((a) => ({ label: a.nameFr, value: a.id }))}
                sx={{
                  label: {
                    fontSize: '.75rem',
                    marginBottom: '.125rem !important',
                  },
                }}
              />
            ) : (
              <Text>{concentrations.find((c) => c.id === concentrationId)?.nameFr || 'Aucune'}</Text>
            )}
          </Box>
        );
      },
      width: isEditable ? '35%' : '250px',
    },
    {
      id: 'price',
      accessorKey: 'price',
      header: t('schedule:Price')!,
      width: '200px',
      cell: (props) => {
        const days = props.row.original.days;

        if (days.length === 0) {
          return;
        }

        const total = RegistrationService.calculateWeek(props.row.original, camp || ({} as any), camp?.closedOn || []);

        return <Text>{total.toFixed(2)}$</Text>;
      },
    },
  ];

  return <Table searchable={false} defaultSort={{ id: 'date', desc: false }} data={Object.values(item?.weeks || []) || []} columns={columns} hidePagination />;
}
