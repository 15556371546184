import { Tabs } from '@mantine/core';
import { useEffect, useMemo } from 'react';
import { CampCalendar } from './camp-calendar';
import { ScheduleTable } from './schedule-table';
import { ScheduleFormStep } from 'component/registration-step/types';
import { TotalBox } from 'component/payment/total-box';
import { Registration } from 'api/models/registration';

interface ScheduleTabsProps {
  form: ScheduleFormStep;
  isEditable?: boolean;
  showPartialTotal?: boolean;
  registration?: Registration;
}

export function ScheduleTabs({ form, isEditable, showPartialTotal, registration }: ScheduleTabsProps) {
  const children = useMemo(() => form.values.family.children, [form]);

  useEffect(() => {
    if (children.length > 0) {
      children.forEach((child) => {
        if (!form.values.schedule.items[child.tempId]) {
          form.setFieldValue(`schedule.items.${child.tempId}`, {
            child,
            weeks: {},
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children]);

  return (
    <Tabs variant="outline" defaultValue={children[0].tempId}>
      <Tabs.List>
        {children.map((c, i) => (
          <Tabs.Tab
            sx={{
              minWidth: 170,
              height: 40,
              borderRadius: '4px',
              fontSize: 18,
              color: form.errors[`schedule.items.${c.tempId}.weeks`] ? 'red' : 'inherit',
            }}
            key={`tab${i}`}
            value={c.tempId}
          >
            {`${c.firstName} ${c.lastName}`}
          </Tabs.Tab>
        ))}
      </Tabs.List>
      {children.map((c) => (
        <Tabs.Panel value={c.tempId}>
          {isEditable ? <CampCalendar form={form} child={c} /> : null}
          <ScheduleTable form={form} child={c} isEditable={isEditable} isFromAdmin={!!registration} />
          {isEditable ? <TotalBox form={form} showPartialTotal={showPartialTotal} registration={registration} /> : null}
        </Tabs.Panel>
      ))}
    </Tabs>
  );
}
