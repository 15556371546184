import { ChildDto } from 'api/dto/child';
import { EnglishLevel, Gender } from 'component/registration-step/types';
import dayjs from 'dayjs';
import { nanoid } from 'nanoid';

export interface IChild {
  id: string;
  tempId: string;
  firstName: string;
  lastName: string;

  birthDate: string;
  age: number;
  healthInsuranceNumber: string;
  healthInsuranceExpiryDate: string;
  englishLevel: EnglishLevel;
  gender: Gender;

  hasAllergies: boolean;
  allergiesExplanations?: string;

  hasEpipen?: boolean;

  takesDrugs: boolean;
  drugsExplanations?: string;

  hasRestrictedConcentrations: boolean;
  restrictedConcentrationsExplanations?: string;

  hasSpecificDevelopmentPlan: boolean;
  specificDevelopmentPlanDetails?: string;

  epipenAuthorization?: boolean;
}

export class Child {
  id: string;
  tempId: string;
  firstName: string;
  lastName: string;

  birthDate: string;
  age: number;
  healthInsuranceNumber: string;
  healthInsuranceExpiryDate: string;
  englishLevel: EnglishLevel;
  gender: Gender;

  hasAllergies: boolean;
  allergiesExplanations?: string;

  hasEpipen?: boolean;

  takesDrugs: boolean;
  drugsExplanations?: string;

  hasRestrictedConcentrations: boolean;
  restrictedConcentrationsExplanations?: string;

  hasSpecificDevelopmentPlan: boolean;
  specificDevelopmentPlanDetails?: string;

  epipenAuthorization?: boolean;

  constructor(child: ChildDto | IChild) {
    if (!child) {
      this.id = '';
      this.tempId = '';
      this.firstName = '';
      this.lastName = '';
      this.birthDate = '';
      this.age = 0;
      this.healthInsuranceNumber = '';
      this.healthInsuranceExpiryDate = '';
      this.englishLevel = EnglishLevel.ADVANCED;
      this.takesDrugs = false;
      this.hasAllergies = false;
      this.hasEpipen = false;
      this.hasRestrictedConcentrations = false;
      this.hasSpecificDevelopmentPlan = false;
      this.epipenAuthorization = false;
      this.gender = Gender.GENDER_NEUTRAL;
      return;
    }

    this.id = child.id;
    this.tempId = nanoid();
    this.firstName = child.firstName;
    this.lastName = child.lastName;
    this.birthDate = child.birthDate;
    // Age as of June 24
    const june = dayjs().set('month', 5).set('day', 24);
    this.age = dayjs(june).diff(child.birthDate, 'year');
    this.healthInsuranceNumber = child.healthInsuranceNumber;
    this.healthInsuranceExpiryDate = child.healthInsuranceExpiryDate;
    this.englishLevel = child.englishLevel;
    this.gender = child.gender;
    this.hasAllergies = child.hasAllergies;
    this.allergiesExplanations = child.allergiesExplanations;
    this.hasEpipen = child.hasEpipen;
    this.takesDrugs = child.takesDrugs;
    this.drugsExplanations = child.drugsExplanations;
    this.hasRestrictedConcentrations = child.hasRestrictedConcentrations;
    this.restrictedConcentrationsExplanations = child.restrictedConcentrationsExplanations;
    this.hasSpecificDevelopmentPlan = child.hasSpecificDevelopmentPlan;
    this.specificDevelopmentPlanDetails = child.specificDevelopmentPlanDetails;
    this.epipenAuthorization = child.epipenAuthorization;
  }

  public canHaveConcentration() {
    return this.age >= 5;
  }
}
