import { createStyles } from '@mantine/core';

export const useHeaderStyles = createStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f5f5f5',
    color: '#555',
    letterSpacing: '.0313rem',
    fontSize: 14,
    lineHeight: '1.5rem',
  },
  box: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 'auto',
    width: '90%',
  },
  infoBox: {
    display: 'flex',
    alignItems: 'center',
  },
  socials: {
    display: 'flex',
  },
  link: {
    cursor: 'pointer',
    marginLeft: '5px',
    textDecoration: 'underline',

    // eslint-disable-next-line @typescript-eslint/naming-convention
    ':hover': {
      color: 'blue',
      textDecoration: 'underline',
    },
  },
}));
