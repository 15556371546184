import { Box } from '@mantine/core';
import React, { ReactNode } from 'react';

interface LabelWithAsteriskProps {
  children: ReactNode;
  className?: string;
}

/**
 * Hack component to copy the asterisk from the Mantine library.
 * Unfortunately, Checkbox component does not have a 'withAsterisk' prop.
 *
 * @export
 * @param {LabelWithAsteriskProps} { children }
 * @return {*}
 */
export function LabelWithAsterisk({ children, className }: LabelWithAsteriskProps) {
  return (
    <Box className={className}>
      {children}
      <span className="mantine-u5apz8 mantine-InputWrapper-required mantine-TextInput-required" aria-hidden="true">
        {' *'}
      </span>
    </Box>
  );
}
