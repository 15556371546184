import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
// import detector from 'i18next-browser-languagedetector';
import transFr from './trans/fr/trans.json';
import parentFr from './trans/fr/parent.json';
import childFr from './trans/fr/child.json';
import contactFr from './trans/fr/contact.json';
import scheduleFr from './trans/fr/schedule.json';
import menuFr from './trans/fr/menu.json';
import paymentFr from './trans/fr/payment.json';
import termsFr from './trans/fr/terms.json';
import campFr from './trans/fr/camp.json';
import adminFr from './trans/fr/admin.json';

export enum Language {
  EN = 'en',
  FR = 'fr',
}

export enum NS {
  translation = 'translation',
  parent = 'parent',
  child = 'child',
  contact = 'contact',
  schedule = 'schedule',
  menu = 'menu',
  payment = 'payment',
  terms = 'terms',
  camp = 'camp',
  admin = 'admin',
}

void i18next
  // .use(detector)
  .use(initReactI18next)
  .init({
    fallbackLng: Language.FR,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      [Language.FR]: {
        [NS.translation]: transFr,
        [NS.parent]: parentFr,
        [NS.child]: childFr,
        [NS.contact]: contactFr,
        [NS.schedule]: scheduleFr,
        [NS.menu]: menuFr,
        [NS.payment]: paymentFr,
        [NS.terms]: termsFr,
        [NS.camp]: campFr,
        [NS.admin]: adminFr,
      },
    },
  });

export default i18next;
