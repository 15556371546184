/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { ColumnDef } from '@tanstack/react-table';
import { InvoiceDto } from 'api/dto';
import { Registration } from 'api/models/registration';
import { useGetInvoices } from 'api/queries/invoice';
import { CustomColumnSizing, Table } from 'component/table/table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime, DateTimeFormat } from 'utils/datetime';

export function Invoices({ registration }: { registration: Registration }) {
  const { t } = useTranslation();
  const { data: invoices } = useGetInvoices(registration.id);

  const columns: (ColumnDef<InvoiceDto, string | number | unknown> & CustomColumnSizing)[] = useMemo(
    () => [
      {
        id: 'date',
        accessorKey: 'date',
        header: t('admin:Invoice.Column.DueDate') || '',
        cell: (r) => DateTime.fromISOString(r.row.original.date, true).toFormat(DateTimeFormat.YearMonthAndDay),
      },
      {
        id: 'sentOn',
        accessorKey: 'sentOn',
        header: t('admin:Invoice.Column.SentOn') || '',
        cell: (r) => (r.row.original.sentOn ? DateTime.fromISOString(r.row.original.sentOn).toFormat(DateTimeFormat.YearMonthDayAndTime) : ''),
      },
      {
        id: 'amount',
        accessorKey: 'amount',
        header: t('admin:Invoice.Column.Amount') || '',
        cell: (r) => `${r.row.original.amount.toFixed(2)}$`,
      },
      {
        id: 'stripeCustomerId',
        accessorKey: 'stripeCustomerId',
        header: t('admin:Invoice.Column.StripeCustomerId') || '',
        cell: (r) => r.row.original.stripeCustomerId,
      },
      {
        id: 'stripeInvoiceId',
        accessorKey: 'stripeInvoiceId',
        header: t('admin:Invoice.Column.StripeInvoiceId') || '',
        cell: (r) => r.row.original.stripeInvoiceId,
      },
    ],
    [t]
  );

  return <Table<InvoiceDto> defaultSort={{ id: 'date', desc: false }} columns={columns} data={invoices || []} />;
}
