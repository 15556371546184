import { environment } from 'config/environment';
import forge from 'node-forge';

export const encryptData = (data: string) => {
  const publicKeyForge = forge.pki.publicKeyFromPem(environment.publicKeyEncryption);
  const encryptedData = publicKeyForge.encrypt(data, 'RSA-OAEP', {
    md: forge.md.sha256.create(),
  });
  const encryptedDataBase64 = forge.util.encode64(encryptedData);

  return encryptedDataBase64;
};
