export interface ITaxCreditInfo {
  firstName: string;
  lastName: string;
  socialNumber: string;
  email: string;
  address: string;
  city: string;
  province: string;
  postalCode: string;
  cellphone: string;
  phone?: string;
}

export class TaxCreditInfo {
  firstName: string;
  lastName: string;
  socialNumber: string;
  email: string;
  address: string;
  city: string;
  province: string;
  postalCode: string;
  cellphone: string;
  phone?: string;

  constructor(data: ITaxCreditInfo) {
    if (!data) {
      this.firstName = '';
      this.lastName = '';
      this.socialNumber = '';
      this.email = '';
      this.address = '';
      this.city = '';
      this.province = '';
      this.postalCode = '';
      this.cellphone = '';
      return;
    }

    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.socialNumber = data.socialNumber;
    this.email = data.email;
    this.address = data.address;
    this.city = data.city;
    this.province = data.province;
    this.postalCode = data.postalCode;
    this.cellphone = data.cellphone;
    this.phone = data.phone;
  }
}
