import dayjs, { Dayjs, ManipulateType, OpUnitType } from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/fr';
import { toDateWithoutTimezone } from './date';

dayjs.locale('fr');
dayjs.extend(relativeTime);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(utc);

export enum DateTimeFormat {
  Human = 'DD MMMM YYYY',
  YearMonthAndDay = 'YYYY-MM-DD',
  YearMonthDayAndTime = 'YYYY-MM-DD HH:mm',
}

export class DateTime {
  date: Dayjs;

  constructor(date: Dayjs) {
    this.date = date;
  }

  public static fromISOString = (isoString: string, withoutUtcOffset?: boolean): DateTime => {
    let currentDate = dayjs(isoString);
    if (withoutUtcOffset) {
      currentDate = currentDate.utcOffset(0);
    }

    return new DateTime(currentDate);
  };

  public static fromDate = (date: Date): DateTime => {
    return new DateTime(dayjs(date));
  };

  public static now = (): DateTime => {
    return new DateTime(dayjs());
  };

  public static startOfTime = (): DateTime => new DateTime(dayjs(0));

  /**
   * i.e. Du 26 juin au 27 juin
   */
  public static getWeekRangeString(dates: string[]): string {
    const sortedDates = dates.sort((a, b) => dayjs(a).diff(dayjs(b)));
    const startDate = dayjs(sortedDates[0]).format('DD MMMM');
    const endDate = dayjs(sortedDates[sortedDates.length - 1]).format('DD MMMM');
    return `Du ${startDate} au ${endDate}`;
  }

  /**
   * i.e. lundi - mardi - jeudi
   */
  public static getDayRangeString(dates: string[]): string {
    const sortedDates = dates.sort((a, b) => dayjs(a).diff(dayjs(b)));
    return sortedDates.map((date) => dayjs(date).format('dddd')).join(' - ');
  }

  public static currentYear = (): number => {
    return dayjs().year();
  };

  public isAfter = (other: DateTime): boolean => {
    return this.date.isAfter(other.date);
  };

  public isBefore = (other: DateTime): boolean => {
    return this.date.isBefore(other.date);
  };

  public isSame = (other: DateTime): boolean => {
    return this.date.isSame(other.date);
  };

  public isSameOrAfter = (other: DateTime): boolean => {
    return this.date.isSameOrAfter(other.date);
  };

  public isSameOrBefore = (other: DateTime): boolean => {
    return this.date.isSameOrBefore(other.date);
  };

  public addMinutes = (value: number): DateTime => {
    return new DateTime(this.date.add(value, 'minutes'));
  };

  public startOf = (unit: OpUnitType): DateTime => new DateTime(this.date.utcOffset(0).startOf(unit));

  public endOf = (unit: OpUnitType): DateTime => new DateTime(this.date.utcOffset(0).endOf(unit));

  public setHour = (hour: number): string => this.date.hour(hour).toISOString();

  public subtract = (number: number, unit: ManipulateType): DateTime => new DateTime(this.date.subtract(number, unit));

  public diff = (dateTime: DateTime, unit: ManipulateType): number => {
    return this.date.diff(dateTime.date, unit, true);
  };

  public toFormat = (format: DateTimeFormat): string => this.date.format(format);

  public toISOString = (): string => {
    return this.date.toISOString();
  };

  public toDate = (): Date => {
    return this.date.toDate();
  };

  public toUTCDate = (): Date => {
    const date = this.date.toDate();
    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    return new Date(date.getTime() + userTimezoneOffset);
  };
}

export function getDatesInRange(start: Date, end: Date, closedOn?: string[]): Date[] {
  const dates: Date[] = [];
  let current = dayjs.utc(start);

  const closedOnDates = closedOn?.map((c) => dayjs(toDateWithoutTimezone(c)).format('YYYY-MM-DD')) || [];

  while (current.isSameOrBefore(end)) {
    if (current.day() !== 0 && current.day() !== 6) {
      const newDate = current.format('YYYY-MM-DD');
      if (!closedOnDates?.includes(newDate)) {
        dates.push(toDateWithoutTimezone(current.format('YYYY-MM-DD')));
      }
    }
    current = current.add(1, 'day');
  }

  return dates;
}
