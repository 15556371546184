import { Box, Text } from '@mantine/core';
import { Band } from 'component/band/band';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default function TermsAndConditions() {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <>
      <Band text={t('terms:ImportantInfo')} />

      <Box ml="10%" mr="10%" mt={40}>
        <Text mb={20} size="lg" weight={700} sx={{ fontFamily: 'Happy Monkey' }}>
          {t('terms:Politics')}
        </Text>
        <Box mb={20}>
          <Text mb={10} size="md" underline sx={{ fontFamily: 'Happy Monkey' }}>
            {t('terms:Reimbursement.Title')}
          </Text>
          <Text mb={5} size="sm" sx={{ fontFamily: 'Happy Monkey' }}>
            {t('terms:Reimbursement.1')}
          </Text>
          <Text mb={5} size="sm" sx={{ fontFamily: 'Happy Monkey' }}>
            {t('terms:Reimbursement.2')}
          </Text>
          <Text size="sm" sx={{ fontFamily: 'Happy Monkey' }}>
            {t('terms:Reimbursement.3')}
          </Text>
        </Box>

        <Box mb={20}>
          <Text mb={10} size="md" underline sx={{ fontFamily: 'Happy Monkey' }}>
            {t('terms:Registration.Title')}
          </Text>

          <Text mb={5} size="sm" sx={{ fontFamily: 'Happy Monkey' }}>
            {t('terms:Registration.1')}
          </Text>
          <Text mb={5} size="sm" sx={{ fontFamily: 'Happy Monkey' }}>
            {t('terms:Registration.2')}
          </Text>
        </Box>

        <Box mb={20}>
          <Text mb={10} size="md" underline sx={{ fontFamily: 'Happy Monkey' }}>
            {t('terms:LateMorning.Title')}
          </Text>
          <Text mb={5} size="sm" sx={{ fontFamily: 'Happy Monkey' }}>
            {t('terms:LateMorning.1')}
          </Text>
        </Box>

        <Box mb={20}>
          <Text mb={10} size="md" underline sx={{ fontFamily: 'Happy Monkey' }}>
            {t('terms:LateEvening.Title')}
          </Text>
          <Text mb={5} size="sm" sx={{ fontFamily: 'Happy Monkey' }}>
            {t('terms:LateEvening.1')}
          </Text>
        </Box>

        <Box mb={20}>
          <Text mb={10} size="md" underline sx={{ fontFamily: 'Happy Monkey' }}>
            {t('terms:Absence.Title')}
          </Text>

          <Text mb={5} size="sm" sx={{ fontFamily: 'Happy Monkey' }}>
            {t('terms:Absence.1')}
          </Text>
          <Text mb={5} size="sm" sx={{ fontFamily: 'Happy Monkey' }}>
            {t('terms:Absence.2')}
          </Text>
        </Box>

        <Box mb={50}>
          <Text mb={10} size="md" underline sx={{ fontFamily: 'Happy Monkey' }}>
            {t('terms:Reprise.Title')}
          </Text>

          <Text mb={5} size="sm" sx={{ fontFamily: 'Happy Monkey' }}>
            {t('terms:Reprise.1')}
          </Text>
          <Text mb={5} size="sm" sx={{ fontFamily: 'Happy Monkey' }}>
            {t('terms:Reprise.2')}
          </Text>
          <Text size="sm" sx={{ fontFamily: 'Happy Monkey' }}>
            {t('terms:Reprise.3')}
          </Text>
        </Box>
      </Box>
    </>
  );
}
