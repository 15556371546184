import { ConcentrationDto } from './concentration';
import { MenuItemDto } from './menu-item';
import { ProductDto } from './product';

export interface CampDto {
  id: string;
  nameEn: string;
  nameFr: string;

  descriptionEn?: string;
  descriptionFr?: string;

  products: ProductDto[];
  menu: MenuItemDto[];
  concentrations: ConcentrationDto[];
  startDate: string;
  endDate: string;
  registrationStartDate: string;
  closedOn: string[];
  status: CampStatus;
  invoiceDates: string[];
  menuOverrideOptions: string[];
  waitingListUrl?: string;

  registrationFees: number;
  managementFees: number;
}

export enum CampStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  FULL = 'FULL',
}
