import { Anchor, Box, Button, Group, Text } from '@mantine/core';
import { Band } from 'component/band/band';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export function SchoolDaycareExplanations() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <Band text={t('DaycareRegistration.Registration')} />
      <Box ml="10%" mr="10%" mt={40} sx={{ display: 'flex', flexFlow: 'column' }}>
        <Text mb={30} sx={{ fontFamily: 'Happy Monkey', fontWeight: 600, lineHeight: '28px', fontSize: 30, textAlign: 'center' }}>
          {t('DaycareRegistration.New!')}
          <Text span ml={10} color="red" inherit>
            {t('DaycareRegistration.Where')}!
          </Text>
        </Text>
        <Text mb={20} sx={{ fontFamily: 'Happy Monkey', fontWeight: 600, lineHeight: '28px', fontSize: 20, textAlign: 'justify' }}>
          {t('DaycareRegistration.Explanation')}
        </Text>
        <Text color="red" mb={30} sx={{ fontFamily: 'Happy Monkey', fontWeight: 600, lineHeight: '28px', fontSize: 18 }}>
          {t('DaycareRegistration.Lunch')}
        </Text>
        <Text sx={{ fontFamily: 'Rum Raisin', fontWeight: 700, lineHeight: '28px', fontSize: 22 }}>{t('DaycareRegistration.Rate')}</Text>
        <Text mt={20} sx={{ fontFamily: 'Happy Monkey', fontWeight: 600, lineHeight: '28px', fontSize: 18 }}>
          {t('DaycareRegistration.RateExplanation')}
        </Text>
        <Text mt={20} sx={{ fontFamily: 'Happy Monkey', fontWeight: 600, lineHeight: '28px', fontSize: 18 }}>
          {t('DaycareRegistration.RateExplanationPeriod')}
        </Text>
        <Text mt={30} sx={{ fontFamily: 'Rum Raisin', fontWeight: 700, lineHeight: '28px', fontSize: 22 }}>
          {t('DaycareRegistration.Schedule.Schedule')}
        </Text>
        <Text mt={20} sx={{ fontFamily: 'Happy Monkey', fontWeight: 600, lineHeight: '28px', fontSize: 18 }}>
          {t('DaycareRegistration.Schedule.Pedago')}
        </Text>
        <Text mt={20} sx={{ fontFamily: 'Happy Monkey', fontWeight: 600, lineHeight: '28px', fontSize: 18 }}>
          {t('DaycareRegistration.Schedule.Saturdays.Hours')}
        </Text>
        {/* <Text mt={20} sx={{ fontFamily: 'Happy Monkey', fontWeight: 600, lineHeight: '28px', fontSize: 18 }}>
          {t('DaycareRegistration.Schedule.Saturdays.AM')}
        </Text>
        <Text mt={20} sx={{ fontFamily: 'Happy Monkey', fontWeight: 600, lineHeight: '28px', fontSize: 18 }}>
          {t('DaycareRegistration.Schedule.Saturdays.PM')}
        </Text> */}
        <Text mt={30} sx={{ fontFamily: 'Happy Monkey', fontWeight: 600, lineHeight: '28px', fontSize: 18 }}>
          <Trans
            i18nKey="DaycareRegistration.ContactInformations"
            components={{
              link1: <Anchor href={'mailto:info@campesa.ca'} title="My link1" />,
              link2: <Anchor href={'https://www.facebook.com/englishsummeracademy'} title="Another link" />,
            }}
          />
        </Text>
        <Text mt={20} sx={{ fontFamily: 'Happy Monkey', fontWeight: 600, lineHeight: '28px', fontSize: 18 }}>
          <Trans
            i18nKey="DaycareRegistration.HowToAddNewDays"
            components={{
              link1: <Anchor href={'mailto:info@campesa.ca'} title="My link1" />,
            }}
          />
        </Text>
        <Group position="center">
          <Button maw={300} onClick={() => navigate('/daycare-registration/process')} mt={60} mb={60} size="lg" color="red">
            <Text sx={{ fontFamily: 'Happy Monkey', fontWeight: 600, lineHeight: '28px', fontSize: 18 }}>{t('Inscription.Start')}</Text>
          </Button>
        </Group>
      </Box>
    </>
  );
}
