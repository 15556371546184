import { BillingInfoDto } from 'api/dto';

export class BillingInfo {
  firstName: string;
  lastName: string;
  email: string;
  stripeCustomerId: string;

  constructor(billingInfo: BillingInfoDto) {
    this.firstName = billingInfo.firstName;
    this.lastName = billingInfo.lastName;
    this.email = billingInfo.email;
    this.stripeCustomerId = billingInfo.stripeCustomerId;
  }
}
