import { TextInput } from '@mantine/core';
import { useWebMask } from 'react-hook-mask';

const postalCode = new Map([
  ['G', /[A-Za-z]/],
  ['6', /[0-9]/],
]);

const createMaskGenerator = (mask: any) => ({
  rules: postalCode,
  generateMask: () => mask,
});

const maskGenerator = createMaskGenerator('G6G 6G6');

export function PostalCodeInput({ label, required, value: outerValue, onChange: outerOnChange, ...rest }: any) {
  const mask = useWebMask({
    maskGenerator,
    value: outerValue,
    onChange: outerOnChange,
  });

  return <TextInput {...rest} label={label} required={required} {...mask} />;
}
