import { AppShell, Box, Burger, MediaQuery, Navbar as MantineNavbar, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import Footer from 'component/footer/footer';
import { Header } from 'component/header/header';
import Logo from 'component/logo/logo';
import { Navbar } from 'component/navbar/navbar';
import { Sidebar } from 'component/navbar/sidebar';
import { useEffect, useState } from 'react';
import { Outlet } from 'react-router';

export function AppLayout() {
  const matches = useMediaQuery('(min-width: 764px)');
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    if (matches) {
      setOpened(false);
    }
  }, [matches]);

  return (
    <AppShell
      sx={{
        main: {
          paddingTop: 'var(--mantine-header-height, 0px)',
          paddingLeft: 0,
          paddingRight: 0,
        },
      }}
      navbar={
        <MantineNavbar p="md" hiddenBreakpoint={4000} hidden={!opened}>
          <Sidebar onClick={() => setOpened(false)} />
        </MantineNavbar>
      }
      header={
        <Header
          height={55}
          phones={['Victo: 873-665-1030', 'Drummond 819-991-1766']}
          emails={['info.victo@campesa.ca', 'info.drummond@campesa.ca']}
          facebook="https://www.facebook.com/englishsummeracademy"
          instagram="https://www.instagram.com/camp.esa/"
        >
          <MediaQuery largerThan={950} styles={{ display: 'none' }}>
            <Box display="flex" sx={{ alignItems: 'center' }}>
              <Burger opened={opened} onClick={() => setOpened((o) => !o)} size="sm" mr="xl" />
            </Box>
          </MediaQuery>
        </Header>
      }
      footer={<Footer />}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          height: '180px',
          marginTop: '10px',
        }}
      >
        <Logo width="170px" />
      </Box>
      <MediaQuery smallerThan={950} styles={{ display: 'none' }}>
        <Box>
          <Navbar />
        </Box>
      </MediaQuery>
      <Outlet />
    </AppShell>
  );
}
