export interface IUserToken {
  id: string;
  email: string;
  firstName?: string;
  lastName?: string;
  role: UserRole;
}

export enum UserRole {
  user = 'user',
  admin = 'admin',
}

export class UserToken implements IUserToken {
  id: string;
  email: string;
  firstName?: string | undefined;
  lastName?: string | undefined;
  role: UserRole;

  constructor(userToken: IUserToken) {
    this.id = userToken.id;
    this.email = userToken.email;
    this.firstName = userToken.firstName;
    this.lastName = userToken.lastName;
    this.role = userToken.role;
  }

  get isAdmin() {
    return this.role === UserRole.admin;
  }
}
