import { EmergencyContactDto } from 'api/dto';
import { LinkWithChildren } from 'component/registration-step/types';

export class EmergencyContact {
  id?: string;
  familyId?: string;
  firstName: string;
  lastName: string;
  link: LinkWithChildren;
  cellphone: string;
  phone?: string;

  constructor(emergencyContact: EmergencyContactDto) {
    if (!emergencyContact) {
      this.id = '';
      this.familyId = '';
      this.firstName = '';
      this.lastName = '';
      this.link = {} as any;
      this.cellphone = '';
      this.phone = '';
      return;
    }

    this.id = emergencyContact.id;
    this.familyId = emergencyContact.familyId;
    this.firstName = emergencyContact.firstName;
    this.lastName = emergencyContact.lastName;
    this.link = emergencyContact.link as LinkWithChildren;
    this.cellphone = emergencyContact.cellphone;
    this.phone = emergencyContact.phone;
  }
}
