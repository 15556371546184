import { useEffect, useState } from 'react';
import { useForm } from '@mantine/form';
import { Box, Button, Group, Stepper } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { nanoid } from 'nanoid';
import { environment } from 'config/environment';
import isNumber from 'lodash/isNumber';
import { TaxCredit, validateDaycareStep } from 'component/registration-step';
import { useMediaQuery } from '@mantine/hooks';
import { DaycareFullInformation } from 'component/daycare-registration-step/daycare-full-info';
import { DaycareCart } from 'component/daycare-registration-step/daycare-cart';
import { useNavigate } from 'react-router-dom';
import { Gender } from 'component/daycare-registration-step/types';

const stripe = loadStripe(environment.stripePublicKey);

export function NewDaycareRegistration() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const matches = useMediaQuery('(min-width: 764px)');

  const form = useForm({
    initialValues: {
      family: {
        parents: [{}, {}], // at least 2 parents
        children: [{ tempId: nanoid(), gender: Gender.GENDER_NEUTRAL }],
        legalAuthorizations: {
          canAdministerDrugs: false,
          hasChildcare: false,
          authorizedPeople: [],
          unauthorizedPeople: [],
        },
        termsAndConditions: {
          needsToCommunicateModificationsHealth: false,
        },
        emergencyContacts: [{}, {}], // at least 2 external contact
      },
      taxCreditInfos: [{}],
    },
    validate: (values) => validateDaycareStep(activeStep, values),
  });

  useEffect(() => {
    const storedValue = window.localStorage.getItem('daycare-user-form');
    if (storedValue) {
      try {
        form.setValues(JSON.parse(window.localStorage.getItem('daycare-user-form') || '{}'));
      } catch (e) {
        /* empty */
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.localStorage.setItem('daycare-user-form', JSON.stringify(form.values));
  }, [form.values]);

  const nextStep = (index?: number) =>
    setActiveStep((currentStep) => {
      if (form.validate().hasErrors) {
        return currentStep;
      }

      window.scrollTo({
        top: 100,
        behavior: 'smooth',
      });

      if (isNumber(index)) {
        return index;
      }

      return currentStep < 2 ? currentStep + 1 : currentStep;
    });

  const prevStep = () => setActiveStep((currentStep) => (currentStep > 0 ? currentStep - 1 : currentStep));

  return (
    <Elements stripe={stripe}>
      <Box p={matches ? 40 : 20} sx={{ position: 'relative' }}>
        <Button
          sx={{
            position: 'absolute',
            top: 140,
            right: '8%',
          }}
          size="xs"
          color="blue"
          variant="outline"
          onClick={() => {
            form.reset();
            navigate(0);
          }}
        >
          Réinitialiser
        </Button>
        <Stepper onStepClick={(i) => nextStep(i)} active={activeStep} breakpoint="md">
          <Stepper.Step label={t('Form.Steps.Informations')}>
            <DaycareFullInformation form={form as any} />
          </Stepper.Step>
          <Stepper.Step label={t('Form.Steps.Billing')}>
            <TaxCredit form={form as any} />
          </Stepper.Step>
          <Stepper.Step label={t('Form.Steps.Availability')}>
            <DaycareCart form={form as any} />
          </Stepper.Step>
        </Stepper>

        <Group position="right" mr="5%" ml="5%" mt={70}>
          {activeStep !== 0 && (
            <Button color="gray" variant="outline" onClick={prevStep}>
              {t('Common.Back')}
            </Button>
          )}
          {activeStep !== 2 && (
            <Button variant="outline" onClick={() => nextStep()}>
              {t('Common.NextStep')}
            </Button>
          )}
        </Group>
      </Box>
    </Elements>
  );
}
