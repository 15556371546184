import { PaymentDto, PaymentStatus, PaymentType } from 'api/dto/registration';
import { DateTime } from './datetime';

export function getTotalPaidAmount(payments: PaymentDto[]): number {
  const nbPaidInvoices = payments.filter((p) => p.type === PaymentType.INVOICE).filter((p) => p.status === PaymentStatus.COMPLETED).length;

  const paidPayments = payments.filter((p) => p.status === PaymentStatus.COMPLETED);
  return paidPayments.reduce((total, payment) => {
    let amount: number = payment.amount;

    // If the payment is for an upcoming invoice, we need to calculate the amount paid for each invoice.
    if (payment.type === PaymentType.UPDATE_UPCOMING_INVOICE || payment.type === PaymentType.CUSTOM_UPCOMING_INVOICE) {
      const invoiceDates = getInvoiceDates();
      const paymentDate = DateTime.fromISOString(payment.createdOn);

      const nbInvoicesAfterPayment = invoiceDates.filter((date) => date.isAfter(paymentDate)).length;
      const nbInvoicesBeforePayment = invoiceDates.length - nbInvoicesAfterPayment;
      amount = (payment.amount / nbInvoicesAfterPayment) * Math.max(0, nbPaidInvoices - nbInvoicesBeforePayment);
    }

    return total + amount;
  }, 0);
}

function getInvoiceDates(): DateTime[] {
  // TODO: We should get information from the database.
  const currentYear: number = DateTime.currentYear();
  return [DateTime.fromISOString(`${currentYear}-05-01`), DateTime.fromISOString(`${currentYear}-06-01`), DateTime.fromISOString(`${currentYear}-07-01`)];
}
