import { Text } from '@mantine/core';
import { useNavbarStyles } from './navbar.styles';

interface LinkProps {
  name: string;
  active?: boolean;
  href: string;
}

export function Link({ name, active, href }: LinkProps) {
  const { cx, classes } = useNavbarStyles();
  return (
    <Text
      display="flex"
      sx={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}
      maw={120}
      weight={600}
      className={cx(classes.link, active ? classes.active : '')}
      onClick={() => window.open(href, '_self')}
    >
      {name}
    </Text>
  );
}
