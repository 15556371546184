import { Image } from '@mantine/core';
import { default as LogoBlack } from 'assets/img/logo-black.svg';

interface LogoProps {
  width?: string;
  height?: string;
}

// TODO change logo
export default function Logo({ width, height }: LogoProps) {
  return <Image width={width} height={height} src={LogoBlack} />;
}
