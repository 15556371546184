import { useEffect, useState, useContext, createContext, useCallback, useMemo, ReactNode } from 'react';
import { CognitoUser, CognitoIdToken } from 'amazon-cognito-identity-js';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Auth } from '@aws-amplify/auth';
import axios, { resetAllRequestInterceptor, setRequestInterceptor } from 'config/axios';
import { IUserToken, UserRole, UserToken } from 'api/dto';
import { CognitoInfos, configureAmplify } from 'config/amplify/auth';
import awsConfig from 'config/amplify/aws-exports';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Hub } from '@aws-amplify/core';
import { hubCallback } from 'config/amplify/hub';
import { useLocalStorage } from '@mantine/hooks';

// TODO Fix any errors by type checking with the right typing from cognito
const authContext = createContext<ReturnType<typeof useProvideAuth>>({} as never);

export function ProvideAuth({ children, infos }: { children: ReactNode; infos?: CognitoInfos }): JSX.Element {
  const currentAuth = useProvideAuth();
  if (infos) {
    configureAmplify(infos, awsConfig);
  }
  return <authContext.Provider value={currentAuth}>{children}</authContext.Provider>;
}

export const useAuth = () => useContext(authContext);

const useProvideAuth = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [rememberSession, setRememberMe] = useLocalStorage<boolean | undefined>({
    key: 'remember-me',
    defaultValue: undefined,
  });
  const [user, setUser] = useState<UserToken | undefined>();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isHydrated, setIsHydrated] = useState(false);
  const [tempCognitoUser, setTempCognitoUser] = useState<CognitoUser>();
  const appIsHydrated = useMemo(() => isHydrated, [isHydrated]);
  const [firstLoad, setFirstLoad] = useState(true);

  const logout = useCallback(async (): Promise<void> => {
    await Auth.signOut();
    setIsAuthenticated(false);
    setRememberMe(false);
    resetAllRequestInterceptor();
    navigate('/session/login');
  }, [navigate, setRememberMe]);

  const extractAndRedirect = useCallback(async () => {
    const session = await Auth.currentSession();

    if (!session) {
      return;
    }

    setRequestInterceptor(logout);
    setUser(extractUserFromToken(session.getIdToken()));

    setIsAuthenticated(true);

    if (location.pathname.includes('/session/login')) {
      navigate('/admin/registrations');
    }
  }, [location, logout, navigate]);

  const hydrateUser = useCallback(async () => {
    // on first load our state is an empty string
    if (firstLoad) {
      setFirstLoad(false);
      return;
    }

    setFirstLoad(false);

    try {
      await extractAndRedirect();
    } catch (error) {
      await Auth.signOut();
    }

    if (location.pathname.includes('/change-password') && !tempCognitoUser) {
      navigate('/session/login');
    }

    setIsHydrated(true);
  }, [firstLoad, extractAndRedirect, tempCognitoUser, location, navigate]);

  const login = async (email: string, password: string, rememberMe?: boolean): Promise<void> => {
    const cognitoUser: CognitoUser = await Auth.signIn({ username: email, password });

    if (cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
      setTempCognitoUser(cognitoUser);
      navigate('/change-password');
      return;
    }

    const session = await Auth.currentSession();

    if (session) {
      setRequestInterceptor(logout);
      setUser(extractUserFromToken(session.getIdToken()));
      setIsAuthenticated(true);
    } else {
      // error no session
    }

    if (rememberMe) {
      setRememberMe(true);
    } else {
      setRememberMe(false);
    }
  };

  const completeNewPassword = useCallback(
    async (newPassword: string): Promise<void> => {
      if (!tempCognitoUser) {
        return;
      }

      try {
        await Auth.completeNewPassword(tempCognitoUser, newPassword, {});
        toast.success(t('Password changed'));
        await extractAndRedirect();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        if (error.code === 'Code mismatch') {
          toast.error(t('Wrong code'), {
            pauseOnHover: true,
            delay: 5000,
          });
        }
        toast.error(t('Change password failed'));
      }
    },
    [tempCognitoUser, extractAndRedirect, t]
  );

  const forgotPassword = useCallback(async (email: string): Promise<void> => {
    await Auth.forgotPassword(email);
  }, []);

  const resetPassword = async (email: string, code: string, newPassword: string): Promise<void> => {
    await Auth.forgotPasswordSubmit(email, code, newPassword);
  };

  useEffect(() => {
    void hydrateUser();
  }, [hydrateUser]);

  useEffect(() => {
    const callback = hubCallback(logout);
    Hub.listen('auth', callback);

    return () => Hub.remove('auth', callback);
  }, [logout]);

  return {
    user,
    login,
    completeNewPassword,
    logout,
    appIsHydrated,
    forgotPassword,
    resetPassword,
    isAuthenticated,
  };
};

export function extractUserFromToken(token: CognitoIdToken) {
  const payload = token.payload as Record<string, string>;

  const newToken = {
    id: payload.userId,
    email: payload.email,
    userType: payload.userType,
    // TODO Change with real payload role
    role: UserRole.admin,
  } as IUserToken;

  return new UserToken(newToken);
}
