import { Box, Text } from '@mantine/core';
import { Band } from 'component/band/band';
import { useTranslation } from 'react-i18next';

export default function Success() {
  const { t } = useTranslation();

  return (
    <>
      <Band text={t('payment:RegisteredSuccess')} />

      <Box ml="10%" mr="10%" mt={40} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexFlow: 'column' }}>
        <Text
          size="lg"
          sx={{
            fontFamily: 'Rum Raisin',
          }}
        >
          {t('payment:RegisteredSuccessfully')}
        </Text>
      </Box>
    </>
  );
}
