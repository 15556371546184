import axios from 'config/axios';
import { useQuery } from '@tanstack/react-query';
import { environment } from '../../config/environment';
import { RegistrationDto, UpdateRegistrationDto } from 'api/dto/registration';
import { queryClient } from 'api/query-client';
import { ReportType } from 'api/enum/report-type';
import { Registration } from 'api/models/registration';
import { Family } from 'api/models/family';

export const useGetRegistration = (familyId: string, registrationId: string, family?: Family) =>
  useQuery<RegistrationDto, unknown, Registration>({
    queryKey: ['registrations', familyId, registrationId],
    queryFn: async () => {
      const { data } = await axios.get<RegistrationDto>(`${environment.restApi.resourceUrl.families}/${familyId}${environment.restApi.resourceUrl.registrations}/${registrationId}`);
      return data;
    },
    enabled: registrationId != null && familyId != null && family != null,
    select: (data) => new Registration(data, family!.children),
  });

export const useGetRegistrations = () =>
  useQuery<RegistrationDto[]>(['registrations'], async () => {
    const { data } = await axios.get<RegistrationDto[]>(`${environment.restApi.resourceUrl.registrations}`);
    return data;
  });

export const updateRegistration = async (registration: UpdateRegistrationDto) => {
  const { data } = await axios.put<RegistrationDto>(`camps/${registration.campId}/registrations/${registration.id}`, registration, {
    params: {
      hasManagementFees: registration.hasManagementFees,
    },
  });

  await queryClient.invalidateQueries({ queryKey: ['registrations', registration.familyId, registration.id] });

  return data;
};

export const deleteRegistration = async (registration: RegistrationDto) => {
  await axios.delete<RegistrationDto>(`camps/${registration.campId}/registrations/${registration.id}`);
  await queryClient.invalidateQueries({ queryKey: ['registrations'] });
};

export const generateMenuReport = async (campId: string, startDate: string, endDate: string) => {
  const { data, headers } = await axios.get(`camps/${campId}/reports/${ReportType.MENU}`, {
    params: {
      startDate,
      endDate,
    },
  });
  const contentDisposition = headers['content-disposition'];
  const filename = getFilenameFromContentDisposition(contentDisposition);

  download(data, filename);
};

export const generateRegistrationReport = async (campId: string) => {
  const { data, headers } = await axios.get(`camps/${campId}/reports/${ReportType.CONCENTRATION}`);
  const contentDisposition = headers['content-disposition'];
  const filename = getFilenameFromContentDisposition(contentDisposition);

  download(data, filename);
};

export const generateDaycareReport = async () => {
  const { data, headers } = await axios.get(`daycare/reports/${ReportType.REGISTRATION}`);
  const contentDisposition = headers['content-disposition'];
  const filename = getFilenameFromContentDisposition(contentDisposition);

  download(data, filename);
};

export const generateTaxReport = async (campId: string) => {
  const { data, headers } = await axios.get(`camps/${campId}/reports/${ReportType.TAX}`);
  const contentDisposition = headers['content-disposition'];
  const filename = getFilenameFromContentDisposition(contentDisposition);

  download(data, filename);
};

export const generateDayCareTaxReport = async () => {
  const { data, headers } = await axios.get(`daycare/reports/${ReportType.TAX}`);
  const contentDisposition = headers['content-disposition'];
  const filename = getFilenameFromContentDisposition(contentDisposition);

  download(data, filename);
};

export function download(data: any, filename: string | null) {
  const blob = new Blob([data], { type: 'text/csv' });
  const downloadUrl = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = downloadUrl;

  if (filename) {
    link.download = filename;
  }

  link.click();
  URL.revokeObjectURL(downloadUrl);
}

function getFilenameFromContentDisposition(contentDisposition: string | undefined): string | null {
  if (!contentDisposition) {
    return null;
  }

  const matches = /filename=(?:"|')(.+?)(?:"|')/gi.exec(contentDisposition);
  if (matches && matches[1]) {
    return decodeURIComponent(matches[1]);
  }

  return null;
}
