import { IWeek } from 'api/models/product';
import capitalize from 'lodash/capitalize';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import utcPlugin from 'dayjs/plugin/utc';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
dayjs.extend(utcPlugin);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.locale('fr');

export function isWithinMultipleWeekRanges(date: Date, weekRanges: IWeek[]): boolean {
  const currentDay = dayjs(date);
  for (const week of weekRanges) {
    const startOfWeek = dayjs(week.days[0]).startOf('week');
    const endOfWeek = dayjs(week.days[0]).endOf('week');
    if ((currentDay.isAfter(startOfWeek) || currentDay.isSame(startOfWeek)) && (currentDay.isBefore(endOfWeek) || currentDay.isSame(endOfWeek))) {
      return true;
    }
  }
  return false;
}

export function startOfWeek(date: string): string {
  return dayjs(date).startOf('week').format('YYYY-MM-DD');
}

export function formatWeekRange(date: string): string {
  let startOfWeek = dayjs(date).startOf('week');
  let endOfWeek = dayjs(date).endOf('week');
  while (startOfWeek.day() === 6 || startOfWeek.day() === 0) {
    startOfWeek = startOfWeek.add(1, 'day');
  }
  while (endOfWeek.day() === 6 || endOfWeek.day() === 0) {
    endOfWeek = endOfWeek.subtract(1, 'day');
  }
  const startDay = startOfWeek.format('D MMMM');
  const endDay = endOfWeek.format('D MMMM');
  return `${startDay} - ${endDay}`;
}

export function getWeekDates(date: string): string[] {
  const startOfWeek = dayjs(date).startOf('week');
  const endOfWeek = dayjs(date).endOf('week');
  let currentDate = startOfWeek;
  const weekDates: string[] = [];
  while (!currentDate.isAfter(endOfWeek)) {
    if (currentDate.day() !== 6 && currentDate.day() !== 0) {
      weekDates.push(currentDate.format('YYYY-MM-DD'));
    }
    currentDate = currentDate.add(1, 'day');
  }

  return weekDates;
}

export function getWeekDayName(date: string): string {
  const dayjsDate = dayjs(date);
  return capitalize(dayjsDate.format('dddd'));
}

export function removeDates(dates: string[], datesToRemove: string[]): string[] {
  return dates.filter((date) => !datesToRemove.includes(date));
}

export function getWeeksBetween(startDate: Date, endDate: Date, excludedDates?: string[]): { days: string[] }[] {
  let currentDate = dayjs(startDate);
  const end = dayjs(endDate);
  const weeks: { days: string[] }[] = [];
  const week: { days: string[] } = { days: [] };

  while (currentDate.isBefore(end) || currentDate.isSame(end)) {
    const utcDate = toUTC(currentDate.toISOString());
    const isWeekend = currentDate.day() === 6 || currentDate.day() === 0;
    const isExcludedDate = excludedDates?.includes(utcDate.toISOString());

    if (!isWeekend && !isExcludedDate) {
      week.days.push(currentDate.format('YYYY-MM-DD'));
    }

    if (week.days.length === 5 || (currentDate.day() === 6 && week.days.length > 0)) {
      weeks.push({ days: week.days });
      week.days = [];
    }
    currentDate = currentDate.add(1, 'day');
  }

  if (week.days.length > 0) {
    weeks.push(week);
  }

  return weeks;
}

export function getWeek(date: Date, startDate: string, endDate: string, excludedDates: string[] = []): { days: string[] } {
  const dates = getWeekDates(date.toISOString());
  const week: { days: string[] } = { days: [] };
  const startDateWithoutTimezone = toUTC(new Date(startDate).toISOString());
  const endDateWithoutTimezone = toUTC(new Date(endDate).toISOString());

  dates.forEach((date) => {
    const currentDate = toUTC(date);

    const isWeekend = currentDate.day() === 6 || currentDate.day() === 0;
    const isExcludedDate = excludedDates.includes(currentDate.toISOString());
    if (!isWeekend && !isExcludedDate && currentDate.isSameOrAfter(startDateWithoutTimezone) && currentDate.isSameOrBefore(endDateWithoutTimezone)) {
      week.days.push(currentDate.format('YYYY-MM-DD'));
    }
  });

  return week;
}

export function toDateWithoutTimezone(date?: string) {
  if (!date) {
    return new Date();
  }

  const currentDate = new Date(date);
  const userTimezoneOffset = currentDate.getTimezoneOffset() * 60000;
  const withOffset = currentDate.getTime() + userTimezoneOffset * Math.sign(userTimezoneOffset);
  return new Date(withOffset);
}

export function toUTC(date?: string) {
  return dayjs(date).utcOffset(0).set('h', 0);
}
