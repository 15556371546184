import { Box, Button, Group, Modal, Text } from '@mantine/core';
import { RegistrationDto } from 'api/dto/registration';
import { deleteRegistration as deleteRegistrationQuery } from 'api/queries/registration';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface DeleteRegistrationModalProps {
  registration?: RegistrationDto;
  opened: boolean;
  toggle: () => void;
}

export function DeleteRegistrationModal({ opened, registration, toggle }: DeleteRegistrationModalProps) {
  const { t } = useTranslation();
  const [isDeleting, setIsDeleting] = useState(false);
  const deleteRegistration = async () => {
    setIsDeleting(true);
    if (registration) {
      await deleteRegistrationQuery(registration);
    }

    toggle();
    setIsDeleting(false);
  };

  return (
    <Modal title="Suppression" opened={opened} withCloseButton onClose={toggle}>
      <Box>
        <Text>
          Êtes-vous sûr de vouloir supprimer l'inscription de {registration?.billingInfo?.firstName} {registration?.billingInfo?.lastName}?
        </Text>
      </Box>

      <Group mt={10} position="right">
        <Button loading={isDeleting} color="red" onClick={() => deleteRegistration()}>
          {t('Common.Delete')}
        </Button>
      </Group>
    </Modal>
  );
}
