/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/naming-convention */
import { Box, Group, Navbar, Text, ThemeIcon, UnstyledButton } from '@mantine/core';
import Logo from 'component/logo/logo';
import { environment } from 'config/environment';
import { useAuth } from 'hooks/useAuth';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Home, MoodKid, Phone, BallVolleyball, Files, Campfire, Apple, ServerCog } from 'tabler-icons-react';

const iconSize = 26;

const links = (t: (key: string) => string | undefined) => [
  { icon: <Home size={iconSize} />, color: 'blue', label: t('Pages.Home'), href: 'https://campesa.ca' },
  { icon: <Campfire size={iconSize} />, color: 'teal', label: t('Pages.Victoriaville'), href: 'https://campesa.ca/ete_2021' },
  { icon: <Campfire size={iconSize} />, color: 'violet', label: t('Pages.Drummondville'), href: 'https://campesa.ca/drummondville' },
  { icon: <MoodKid size={iconSize} />, color: 'grape', label: t('Pages.Inscriptions'), href: '/#/registration' },
  { icon: <MoodKid size={iconSize} />, color: 'grape', label: t('Pages.DaycareRegistrations'), href: '/#/daycare-registration' },
  { icon: <Apple size={iconSize} />, color: 'grape', label: t('Pages.Menu'), href: 'https://campesa.ca/menu' },
  { icon: <Files size={iconSize} />, color: 'grape', label: t('Pages.TermsAndConditions'), href: 'https://campesa.ca/politiques-du-camp-esa/' },
  { icon: <BallVolleyball size={iconSize} />, color: 'grape', label: t('Pages.TeamESA'), href: 'https://campesa.ca/notre-superbe-equipe/' },
  { icon: <Phone size={iconSize} />, color: 'grape', label: t('Pages.Contact'), href: 'https://campesa.ca/contactez-nous/' },
];

export function Sidebar({ onClick }: { onClick: () => void }) {
  const { isAuthenticated } = useAuth();
  const { t } = useTranslation();
  const navLinks = links(t).map((link) => <MainLink onClick={onClick} {...link} key={link.label} />);

  // TODO check if admin
  if (isAuthenticated) {
    navLinks.push(<MainLink key={t('Pages.Admin')} onClick={onClick} icon={<ServerCog size={iconSize} />} color="grape" label={t('Pages.Admin')!} href={'/#/admin'} />);
  }

  return (
    <>
      <Navbar.Section mt="xs">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '30px',
          }}
        >
          <Logo width="170px" height="170px" />
        </Box>
      </Navbar.Section>

      <Navbar.Section mx="-xs" px="xs">
        {navLinks}
      </Navbar.Section>

      <Navbar.Section>{/* Footer with user */}</Navbar.Section>
    </>
  );
}

interface MainLinkProps {
  icon: ReactNode;
  color: string;
  label?: string;
  href: string;
  onClick: () => void;
}

function MainLink({ icon, onClick, color, label, href }: MainLinkProps) {
  const navigate = useNavigate();
  return (
    <UnstyledButton
      sx={(theme) => ({
        display: 'block',
        width: '100%',
        padding: theme.spacing.xs,
        borderRadius: theme.radius.sm,
        color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
        marginBottom: '5px',

        '&:hover': {
          backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
        },
      })}
      onClick={() => {
        if (href.includes(environment.frontendBaseURL)) {
          navigate(href);
        } else {
          window.open(href, '_self');
        }
        onClick();
      }}
    >
      <Group>
        <ThemeIcon color="blue" variant="light">
          {icon}
        </ThemeIcon>

        <Text size="md">{label}</Text>
      </Group>
    </UnstyledButton>
  );
}
