/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Checkbox, createStyles, Text, Textarea } from '@mantine/core';
import { InvoiceCart } from 'component/daycare-registration-step/types';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppStore } from 'store/store';

interface ModificationProps {
  form: InvoiceCart;
  diff: number;
}

const useBoxStyles = createStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid #cecece',
    borderRadius: '.5rem',
    padding: 15,
    width: 530,
  },
}));

export function Modification({ form, diff }: ModificationProps) {
  const { t } = useTranslation();
  const camp = useAppStore((a) => a.camp);
  const { classes } = useBoxStyles();
  const hasManagementFees = useMemo(() => form.values.modifications?.hasManagementFees, [form.values.modifications?.hasManagementFees]);

  const amount = useMemo(() => {
    let currentDiff = diff;
    if (hasManagementFees && camp) {
      currentDiff -= camp.managementFees;
    }

    return currentDiff;
  }, [diff, hasManagementFees, camp]);

  useEffect(() => {
    if (!form.values.modifications && diff != 0) {
      form.setFieldValue('modifications', { amount: 0 });
    }

    form.setFieldValue('modifications.amount', diff - diff * 2);
  }, [diff, hasManagementFees]);

  return (
    <Box className={classes.container}>
      <Box className={classes.box}>
        <Checkbox
          label={t('admin:Registration.ManagementFees')}
          checked={form.values.modifications?.hasManagementFees}
          onChange={(e) => {
            form.setFieldValue('modifications.hasManagementFees', e.target.checked);
          }}
        />
        <Box display="flex" mt={20}>
          <Textarea
            withAsterisk
            w={400}
            minRows={4}
            label={t('admin:Registration.Modification')}
            {...form.getInputProps('modifications.note')}
            value={form.values.modifications?.note}
            onChange={(e) => {
              form.setFieldValue('modifications.note', e.target.value);
            }}
          />
          <Box>
            <Text ml={30}>{amount > 0 ? t('admin:Payment.ToRefund') : t('admin:Payment.ToNotice')}</Text>
            <Text ml={30}>{Math.abs(amount).toFixed(2)}$</Text>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
