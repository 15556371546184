import axios from 'config/axios';
import { useQuery } from '@tanstack/react-query';
import { CognitoInfos } from '../../config/amplify/auth';
import { environment } from '../../config/environment';

export const useCognitoInfo = () =>
  useQuery<CognitoInfos>(['infos'], async () => {
    const { data } = await axios.get<CognitoInfos>(environment.restApi.resourceUrl.infos);
    return data;
  });
