import { Box, Checkbox, createStyles, Select, SimpleGrid, Tabs, Text } from '@mantine/core';
import { StepLayout } from 'layout/step.layout';
import { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormStyles } from './form.styles';
import { MenuFormStep } from './types';
import { useAppStore } from 'store/store';
import { useAuth } from 'hooks/useAuth';
import { IChild } from 'api/models/child';

interface MenuProps {
  form: MenuFormStep;
  children?: ReactNode;
}

const useMenuStyles = createStyles(() => ({
  container: {
    border: '1px solid #cecece',
    borderRadius: '6px',
    padding: '10px 15px',
  },
}));

export function Menu({ form, children }: MenuProps) {
  const { t } = useTranslation();
  const formChildren = useMemo(() => form.values.family.children, [form]);

  return (
    <StepLayout>
      <Text mb={20} size="lg">
        {t('menu:Title')}
      </Text>

      <Text mb={20} color="red" italic>
        {t('menu:Description')}
      </Text>

      <Text mb={20} color="red" italic>
        {t('menu:PleaseChoose')}
      </Text>

      <Tabs variant="outline" defaultValue={formChildren[0].tempId}>
        <Tabs.List>
          {formChildren.map((c, i) => (
            <Tabs.Tab
              sx={{
                minWidth: 170,
                height: 40,
                borderRadius: '4px',
                fontSize: 18,
              }}
              key={`tab${i}`}
              value={c.tempId}
            >
              {`${c.firstName} ${c.lastName}`}
            </Tabs.Tab>
          ))}
        </Tabs.List>
        {formChildren.map((c) => (
          <Tabs.Panel value={c.tempId}>
            <MenuBoxes form={form} child={c} />
          </Tabs.Panel>
        ))}
      </Tabs>
      {children}
    </StepLayout>
  );
}

function MenuBoxes({ form, child }: { form: MenuFormStep; child: IChild }) {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { classes: formClasses } = useFormStyles();
  const camp = useAppStore((a) => a.camp);
  const menu = useMemo(() => camp?.menu || [], [camp]);
  const newMenus = useMemo(() => form.values.menu, [form]);

  const menuMap = menu.reduce((map, obj) => {
    map[obj.date] = obj;
    return map;
  }, {} as Record<string, any>);

  const childItem = form.values.schedule.items[child.tempId];

  if (!childItem) {
    return null;
  }

  return (
    <>
      <Checkbox
        mt={30}
        mb={10}
        disabled={childItem.extras?.vegetarian != null}
        label={<Text italic>{t('menu:GlutenFreeMenu')}</Text>}
        onChange={(e) => {
          if (!childItem.extras) {
            form.setFieldValue(`schedule.items.${child.tempId}.extras`, {});
          }

          form.setFieldValue(`schedule.items.${child.tempId}.extras.glutenFree`, e.target.checked ? 1 : undefined);
        }}
        checked={childItem.extras?.glutenFree === 1}
      />
      {user?.isAdmin && (
        <Checkbox
          mb={10}
          disabled={childItem.extras?.glutenFree != null}
          label={<Text italic>{t('menu:VegetarianMenu')}</Text>}
          onChange={(e) => {
            if (!childItem.extras) {
              form.setFieldValue(`schedule.items.${child.tempId}.extras`, {});
            }

            form.setFieldValue(`schedule.items.${child.tempId}.extras.vegetarian`, e.target.checked ? 0 : undefined);
          }}
          checked={childItem.extras?.vegetarian === 0}
        />
      )}
      <Box p={20} className={formClasses.boxContainer}>
        <SimpleGrid
          cols={5}
          breakpoints={[
            { maxWidth: 2000, cols: 5, spacing: 'xl' },
            { maxWidth: 1400, cols: 3, spacing: 'xl' },
            { maxWidth: 990, cols: 2, spacing: 'xs' },
          ]}
        >
          {Object.values(childItem.weeks)
            ?.map((w) => w.days)
            .reduce((prev, curr) => prev.concat(curr), [])
            .map((d, i) => (
              <MenuBox
                key={`menu${i}${d}${child.firstName}${child.lastName}`}
                date={d}
                disabled={!!childItem.extras?.glutenFree || childItem.extras?.vegetarian != null}
                menuMap={menuMap}
                newMenu={newMenus.find((n) => n.date === d && n.child.tempId === child.tempId)}
                addRemoveNewMenu={(d, newMenu) => {
                  const currentMenuIndex = newMenus.findIndex((c) => c.date === d && c.child.tempId === child.tempId);
                  if (currentMenuIndex !== -1 && newMenu != '0') {
                    const currentMenu = newMenus[currentMenuIndex];
                    currentMenu.mainCourse = newMenu;
                    currentMenu.child = child;
                    form.setFieldValue(`menu.${currentMenuIndex}`, currentMenu);
                  }

                  if (currentMenuIndex === -1 && newMenu != '0') {
                    form.insertListItem('menu', { date: d, mainCourse: newMenu, child: child });
                  }

                  if (currentMenuIndex !== -1 && newMenu == '0') {
                    form.removeListItem('menu', currentMenuIndex);
                  }
                }}
              />
            ))}
        </SimpleGrid>
      </Box>
    </>
  );
}

interface MenuBoxProps {
  menuMap: Record<string, any>;
  date: string;

  newMenu?: { date: string; mainCourse: string };
  addRemoveNewMenu: (date: string, mainCourse: string) => void;

  disabled: boolean;
}

function MenuBox({ date, menuMap, newMenu, addRemoveNewMenu, disabled }: MenuBoxProps) {
  const { classes } = useMenuStyles();
  const camp = useAppStore((a) => a.camp);
  const menuOverridesOptions = useMemo(() => camp?.menuOverrideOptions || [], [camp]);
  const dailyMenu = useMemo(() => menuMap[date], [menuMap, date]);

  const menuSelectData = useMemo(() => {
    const data = menuOverridesOptions.map((c) => ({ label: c, value: c }));

    if (dailyMenu) {
      data.unshift({ label: dailyMenu.mainCourse, value: '0' });
    }

    return data;
  }, [menuOverridesOptions, dailyMenu]);

  if (!dailyMenu) {
    return null;
  }

  return (
    <Box className={classes.container}>
      <Text mb={6} size="xs" color="gray">
        {date}
      </Text>
      <Select
        mb={8}
        disabled={disabled}
        data={menuSelectData}
        label="Menu principal"
        defaultValue={newMenu ? newMenu.mainCourse : '0'}
        sx={{
          label: {
            fontSize: '12px',
            marginBottom: '2px !important',
          },
        }}
        onChange={(e) => e && addRemoveNewMenu(date, e)}
        styles={() => ({
          input: {
            color: newMenu ? undefined : 'red',
          },
        })}
      />
    </Box>
  );
}
