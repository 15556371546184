import { Box, SimpleGrid, Text, TextInput, Checkbox, Button, Group } from '@mantine/core';
import { StepLayout } from 'layout/step.layout';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormStyles } from './form.styles';
import { TaxCreditStep } from './types';
import { PhoneInput } from 'component/input/phone-input';
import { PostalCodeInput } from 'component/input/postal-code-input';
import { SocialSecurityNumberInput } from 'component/input/social-security-number-input';
import { Registration } from 'api/models/registration';
import { showNotification } from '@mantine/notifications';
import { RegistrationService } from 'api/service/registration-service';

interface TaxCreditProps {
  form: TaxCreditStep;
  withUpdate?: {
    registration?: Registration;
  };
}

export function TaxCredit({ form, withUpdate }: TaxCreditProps) {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const taxCreditInfos = useMemo(() => form.values.taxCreditInfos.map((_, index) => <TaxCreditBox form={form} index={index} />), [form]);

  async function handleUpdate() {
    setIsSubmitting(true);
    if (withUpdate && withUpdate.registration) {
      try {
        await RegistrationService.updateTaxCreditInfos(form, withUpdate.registration);
        showNotification({ title: 'Success', message: 'Les informations ont été mises à jour', color: 'blue' });
      } catch (error) {
        showNotification({ title: 'Error', message: 'Une erreur est survenue', color: 'red' });
      }
    }
    setIsSubmitting(false);
  }

  return (
    <StepLayout>
      <Text mb={20} size="lg">
        {t('payment:Title')}
      </Text>
      <Text mb={20}>{t('payment:Description')}</Text>

      <Box mb={20} sx={{ display: 'flex', alignItems: 'center' }}>
        <Checkbox
          label={t('payment:Infos.Divided')}
          checked={taxCreditInfos.length > 1}
          onChange={(e) => {
            if (e.target.checked) {
              form.insertListItem('taxCreditInfos', {});
            } else {
              form.removeListItem('taxCreditInfos', 1);
            }
          }}
        />
      </Box>
      {taxCreditInfos}

      {withUpdate && (
        <Group mb={20} position="right">
          <Button loading={isSubmitting} onClick={handleUpdate} variant="outline" color="blue">
            {t('payment:Update')}
          </Button>
        </Group>
      )}
    </StepLayout>
  );
}

interface TaxCreditBoxProps extends TaxCreditProps {
  index: number;
}

function TaxCreditBox({ form, index }: TaxCreditBoxProps) {
  const { t } = useTranslation();
  const { classes } = useFormStyles();
  return (
    <Box className={classes.boxContainer}>
      <SimpleGrid mb={10} cols={2}>
        <TextInput required label={t('Infos.FirstName')} {...form.getInputProps(`taxCreditInfos.${index}.firstName`)} />
        <TextInput required label={t('Infos.LastName')} {...form.getInputProps(`taxCreditInfos.${index}.lastName`)} />
      </SimpleGrid>

      <SimpleGrid mb={10} cols={2}>
        <TextInput required type="email" label={t('payment:Infos.Email')} {...form.getInputProps(`taxCreditInfos.${index}.email`)} />
        <SocialSecurityNumberInput required label={t('payment:Infos.SocialNumberParent')} {...form.getInputProps(`taxCreditInfos.${index}.socialNumber`)} />
      </SimpleGrid>

      <SimpleGrid mb={10} cols={2}>
        <TextInput label={t('payment:Infos.Address')} placeholder={t('payment:Infos.AddressHint') || ''} {...form.getInputProps(`taxCreditInfos.${index}.address`)} />
        <TextInput required label={t('payment:Infos.City')} {...form.getInputProps(`taxCreditInfos.${index}.city`)} />
        <TextInput required label={t('payment:Infos.Province')} {...form.getInputProps(`taxCreditInfos.${index}.province`)} />
        <PostalCodeInput label={t('payment:Infos.Zip')} {...form.getInputProps(`taxCreditInfos.${index}.postalCode`)} />
      </SimpleGrid>

      <SimpleGrid mb={10} cols={2}>
        <PhoneInput required label={t('payment:Infos.Cellphone')} {...form.getInputProps(`taxCreditInfos.${index}.cellphone`)} />
        <PhoneInput label={t('payment:Infos.Phone')} {...form.getInputProps(`taxCreditInfos.${index}.phone`)} />
      </SimpleGrid>
    </Box>
  );
}
