import { Box, Button, Group } from '@mantine/core';
import { Calendar } from '@mantine/dates';
import { useMediaQuery } from '@mantine/hooks';
import { Child, IChild } from 'api/models/child';
import { IWeek } from 'api/models/product';
import { ScheduleFormStep } from 'component/registration-step/types';
import dayjs from 'dayjs';
import utcPlugin from 'dayjs/plugin/utc';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppStore } from 'store/store';
import { toDateWithoutTimezone } from 'utils/date';
import { getDatesInRange } from 'utils/datetime';
dayjs.extend(utcPlugin);

interface CampCalendarProps {
  form: ScheduleFormStep;
  child: IChild;
}

export function CampCalendar({ form, child }: CampCalendarProps) {
  const { t } = useTranslation();
  const camp = useAppStore((a) => a.camp);

  const start = useMemo(() => {
    if (!camp) {
      return toDateWithoutTimezone(new Date().toISOString());
    }

    if (!camp.startDate) {
      return toDateWithoutTimezone(new Date().toISOString());
    }

    return toDateWithoutTimezone(camp.startDate);
  }, [camp]);

  const end = toDateWithoutTimezone(camp?.endDate);

  const [dates, setDates] = useState<Date[]>([]);
  const mediumDisplay = useMediaQuery('(min-width: 824px)');
  const largeDisplay = useMediaQuery('(min-width: 1195px)');

  useEffect(() => {
    const currentDates: Date[] = [];
    Object.values(form.values.schedule.items[child.tempId]?.weeks || {})
      .map((w) => w.days)
      .forEach((d) => currentDates.push(...d.map((d) => toDateWithoutTimezone(d))));
    setDates(currentDates);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = useCallback(
    (newDates: Date[]) => {
      const items = form.values.schedule.items;
      const item = items[child.tempId];
      const currentWeeks = item ? Object.values(item.weeks) : [];

      const weekDatesMap: Record<string, IWeek> = extractDates(newDates, currentWeeks);
      const newItems = { ...items, [child.tempId]: { ...item, child, weeks: weekDatesMap } };
      setDates(newDates);
      form.setFieldValue('schedule.items', newItems);
    },
    [child, form]
  );

  const selectWholeCamp = useCallback(() => {
    const datesInRange = getDatesInRange(start, end, camp?.closedOn);
    const items = form.values.schedule.items;
    const item = items[child.tempId];
    const currentWeeks = item ? Object.values(item.weeks) : [];

    const weekDatesMap: Record<string, IWeek> = extractDates(datesInRange, currentWeeks);
    const newItems = { ...items, [child.tempId]: { ...item, child, weeks: weekDatesMap } };
    setDates(datesInRange);
    form.setFieldValue('schedule.items', newItems);
  }, [camp?.closedOn, child, end, form, start]);

  const deselectWholeCamp = useCallback(() => {
    setDates([]);
    const items = form.values.schedule.items;
    const item = items[child.tempId];
    const newItems = { ...items, [child.tempId]: { ...item, child, weeks: {} } };
    form.setFieldValue('schedule.items', newItems);
  }, [child, form]);

  return (
    <>
      <Group display="flex" position="center" sx={{ flexDirection: 'column' }}>
        <Box mt={20}>
          <Button onClick={selectWholeCamp} variant="outline" color="blue">
            {t('schedule:SelectWholeCamp')}
          </Button>
          <Button onClick={deselectWholeCamp} ml={20} variant="outline" color="red">
            {t('schedule:DeselectWholeCamp')}
          </Button>
        </Box>

        <Calendar
          multiple
          mt={5}
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
          }}
          firstDayOfWeek="sunday"
          minDate={start}
          maxDate={end}
          size="md"
          amountOfMonths={largeDisplay ? 3 : mediumDisplay ? 2 : 1}
          allowLevelChange={false}
          value={dates}
          onChange={onChange}
          initialMonth={start}
          excludeDate={(d) => {
            if (camp?.closedOn?.includes(dayjs(d).utc(true).toISOString())) {
              return true;
            }

            return d.getDay() === 0 || d.getDay() === 6;
          }}
        />
      </Group>
    </>
  );
}

function extractDates(dates: Date[], currentWeeks?: IWeek[]) {
  const weekDatesMap: Record<string, IWeek> = {};

  dates.forEach((d) => {
    const date = toDateWithoutTimezone(d.toISOString());
    const weekStartDate = dayjs(date).startOf('week').format('YYYY-MM-DD');
    const week = weekDatesMap[weekStartDate];
    const currentWeek = currentWeeks?.find((w) => w.startDate === weekStartDate);
    const formatted = dayjs(date).format('YYYY-MM-DD');

    if (week && !week.days.includes(formatted)) {
      week.days.push(formatted);
      week.days = week.days.sort();
    } else {
      weekDatesMap[weekStartDate] = {
        days: [formatted],
        startDate: weekStartDate,
        endDate: dayjs(date).endOf('week').format('YYYY-MM-DD'),
        concentrationId: currentWeek?.concentrationId,
      } as IWeek;
    }
  });

  return weekDatesMap;
}
