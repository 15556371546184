import { LegalAuthorization, TermsAndConditions } from 'component/registration-step/types';
import { Child } from './child';
import { Parent } from './parent';
import { FamilyDto } from 'api/dto';
import { EmergencyContact } from './emergency-contact';

export class Family {
  id?: string;
  parents: Parent[];
  children: Child[];
  legalAuthorizations: LegalAuthorization;
  termsAndConditions: TermsAndConditions;
  emergencyContacts: EmergencyContact[];

  constructor(family: FamilyDto) {
    if (!family) {
      this.legalAuthorizations = {} as any;
      this.termsAndConditions = {} as any;
      this.parents = [] as any;
      this.children = [] as any;
      this.emergencyContacts = [] as any;
      return;
    }

    this.id = family.id;
    this.parents = family.parents.map((parent) => new Parent(parent));
    this.children = family.children.map((child) => new Child(child));
    this.legalAuthorizations = family.legalAuthorizations;
    this.termsAndConditions = family.termsAndConditions;
    this.emergencyContacts = family.emergencyContacts.map((contact) => new EmergencyContact(contact));
  }
}
