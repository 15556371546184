import { Box, Grid, MediaQuery, Text } from '@mantine/core';
import { Registration } from 'api/models/registration';
import { RegistrationService } from 'api/service/registration-service';
import { InvoiceSchedule, ScheduleFormStep } from 'component/registration-step/types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppStore } from 'store/store';

export function TotalBox({ form, registration, showPartialTotal }: { form: ScheduleFormStep | InvoiceSchedule; showPartialTotal?: boolean; registration?: Registration }) {
  const { t } = useTranslation();
  const camp = useAppStore((a) => a.camp);

  const items = Object.values(form.values.schedule.items);
  const subtotals = items.map((i) => ({
    child: i.child,
    extra: Object.values(i.weeks).reduce((acc, week) => acc + RegistrationService.calculateExtraTotal(week, i.extras), 0),
    subtotal: Object.values(i.weeks).reduce((acc, week) => acc + RegistrationService.calculateWeek(week, camp || ({} as any), camp?.closedOn), 0),
  }));
  const modifications = useMemo(() => {
    if (!registration || !camp) {
      return 0;
    }
    return registration.calculateCustomPayments() + registration.calculateManagementFees(camp.managementFees || 0);
  }, [registration, camp]);

  return (
    <Box
      mt={30}
      display="flex"
      sx={{
        justifyContent: 'flex-end',
      }}
    >
      <MediaQuery largerThan={764} styles={{ maxWidth: '500px' }}>
        <Box
          p={30}
          sx={() => ({
            width: '100%',
            borderRadius: '.5rem',
            border: '.0625rem solid #cecece',
            display: 'flex',
            flexDirection: 'column',
          })}
        >
          {!showPartialTotal && (
            <Grid>
              <Grid.Col span={8}>
                <Box display="flex" sx={{ justifyContent: 'flex-end' }}>
                  <Text>{t('schedule:InitialFees')}:</Text>
                </Box>
              </Grid.Col>
              <Grid.Col span={4}>
                <Box display="flex" sx={{ justifyContent: 'flex-end' }}>
                  <Text>{formatPrice(camp?.registrationFees || 0)}</Text>
                </Box>
              </Grid.Col>
            </Grid>
          )}

          {subtotals.map((s) => (
            <>
              <Grid>
                <Grid.Col span={8}>
                  <Box display="flex" sx={{ justifyContent: 'flex-end' }}>
                    <Text>{t('schedule:ChildSubtotal').replace('{0}', s.child.firstName + ' ' + s.child.lastName)}:</Text>
                  </Box>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Box display="flex" sx={{ justifyContent: 'flex-end' }}>
                    <Text>{formatPrice(s.subtotal)}</Text>
                  </Box>
                </Grid.Col>
              </Grid>
              {s.extra != 0 && !showPartialTotal && (
                <Grid>
                  <Grid.Col span={8}>
                    <Box display="flex" sx={{ justifyContent: 'flex-end' }}>
                      <Text>{t('schedule:ChildExtras').replace('{0}', s.child.firstName + ' ' + s.child.lastName)}:</Text>
                    </Box>
                  </Grid.Col>
                  <Grid.Col span={4}>
                    <Box display="flex" sx={{ justifyContent: 'flex-end' }}>
                      <Text>{formatPrice(s.extra)}</Text>
                    </Box>
                  </Grid.Col>
                </Grid>
              )}
            </>
          ))}

          {!showPartialTotal && (
            <>
              {modifications != 0 && (
                <Grid>
                  <Grid.Col span={8}>
                    <Box display="flex" sx={{ justifyContent: 'flex-end' }}>
                      <Text>{t('admin:Registration.Modifications')}:</Text>
                    </Box>
                  </Grid.Col>
                  <Grid.Col span={4}>
                    <Box display="flex" sx={{ justifyContent: 'flex-end' }}>
                      <Text>{formatPrice(modifications || 0)}</Text>
                    </Box>
                  </Grid.Col>
                </Grid>
              )}

              <Grid>
                <Grid.Col span={8}>
                  <Box display="flex" sx={{ justifyContent: 'flex-end' }}>
                    <Text>{t('schedule:Total')}:</Text>
                  </Box>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Box display="flex" sx={{ justifyContent: 'flex-end' }}>
                    <Text>{formatPrice(subtotals.reduce((acc, s) => acc + s.subtotal + s.extra, 0) + (camp?.registrationFees || 0) + (modifications || 0))}</Text>
                  </Box>
                </Grid.Col>
              </Grid>
            </>
          )}
        </Box>
      </MediaQuery>
    </Box>
  );
}

function formatPrice(price: number) {
  return price.toLocaleString('fr-ca', { style: 'currency', currency: 'CAD' });
}
