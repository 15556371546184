import axios from 'config/axios';
import { useQuery, useMutation } from '@tanstack/react-query';
import { environment } from 'config/environment';
import { FamilyDto } from '../dto';
import { deleteEmptyStringProperties } from 'api/utils/object';
import { Family } from 'api/models/family';

export const useGetFamily = (familyId: string) =>
  useQuery<FamilyDto, unknown, Family>({
    queryKey: ['family', familyId],
    queryFn: async () => {
      const { data } = await axios.get<FamilyDto>(`${environment.restApi.resourceUrl.families}/${familyId}`);
      return data;
    },
    enabled: familyId != null,
    select: (data) => new Family(data),
  });

export const useAddFamily = () => {
  return useMutation<Family, unknown, Family>(['add-family'], async (family: Family) => {
    return await addFamily(family);
  });
};

export const addFamily = async (family: Family) => {
  deleteEmptyStringProperties(family);
  const { data: newFamily } = await axios.post<FamilyDto>(`${environment.restApi.resourceUrl.families}`, family);
  return new Family(newFamily);
};
