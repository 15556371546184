/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { Box } from '@mantine/core';
import { environment } from 'config/environment';
import { useAuth } from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Link } from './link';
import { useNavbarStyles } from './navbar.styles';

const allowedDomains = ['campesa.ca', 'fyreflex.com'];

export function Navbar() {
  const location = useLocation();
  const { isAuthenticated, user } = useAuth();
  const { classes } = useNavbarStyles();
  const { t } = useTranslation();

  // TODO active link
  return (
    <Box className={classes.container}>
      <Link name={t('Pages.Home')} href="https://campesa.ca" />
      <Link name={t('Pages.Victoriaville')} href="https://campesa.ca/ete_2021" />
      <Link name={t('Pages.Drummondville')} href="https://campesa.ca/drummondville" />
      <Link active={location.pathname.split('/').includes('registration')} name={t('Pages.Inscriptions')} href={environment.frontendBaseURL + '/#/registration'} />
      <Link active={location.pathname.split('/').includes('daycare-registration')} name={t('Pages.DaycareRegistrations')} href={environment.frontendBaseURL + '/#/daycare-registration'} />
      <Link name={t('Pages.Menu')} href="https://campesa.ca/menu" />
      <Link name={t('Pages.TermsAndConditions')} href="https://campesa.ca/politiques-du-camp-esa/" />
      <Link name={t('Pages.TeamESA')} href="https://campesa.ca/notre-superbe-equipe/" />
      <Link name={t('Pages.Contact')} href="https://campesa.ca/contactez-nous/" />
      {isAuthenticated && allowedDomains.some((a) => user?.email.indexOf(a) != -1) && (
        <Link active={location.pathname.split('/').includes('admin')} name={t('Pages.Admin')} href={`${environment.frontendBaseURL}/#/admin`} />
      )}
    </Box>
  );
}
