/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { InvoiceSchedule, ProductExtra } from 'component/registration-step/types';
import { DateTime, DateTimeFormat } from './datetime';
import Handlebars from 'handlebars';
import { Product } from 'api/models/product';
import { Concentration } from 'api/models/concentration';
import { Registration } from 'api/models/registration';
import { Camp } from 'api/models/camp';
import { RegistrationService } from 'api/service/registration-service';
import { PaymentType } from 'api/dto';

interface MailProduct {
  title: string;
  weekRanges: string;
  total: string;
}

export type Modification = { note: string; amount: number; hasManagementFees?: boolean };

// TODO redo invoice
export class Invoice {
  preview = '';
  constructor(
    private camp: Camp,
    registration: Registration,
    source: string,
    private concentrations: Concentration[],
    private invoiceSchedule: InvoiceSchedule,
    customModification?: Modification,
    private closedOn?: string[]
  ) {
    let total = RegistrationService.calculateTotal(camp, invoiceSchedule.values.schedule.items, closedOn, registration);

    if (customModification) {
      total += customModification.amount;
    }

    if (invoiceSchedule.values.modifications) {
      const newPayment = invoiceSchedule.values.modifications;
      if (newPayment.hasManagementFees) {
        total += camp.managementFees;
      }
    }

    const data = {
      fullName: registration.billingInfo?.firstName + ' ' + registration.billingInfo?.lastName,
      products: this.generateMailProducts(),
      total: total.toFixed(2) + '$',
      invoiceDate: DateTime.now().toFormat(DateTimeFormat.YearMonthAndDay),
      payments: registration.payments
        .filter((p) => p.type === PaymentType.CUSTOM || p.type.startsWith('UPDATE'))
        .map((p) => {
          if (p.type === PaymentType.CUSTOM) {
            return { note: p.note, amount: `${p.amount.toFixed(2)}$` };
          } else {
            return { note: p.note, amount: p.hasManagementFees ? `${camp.managementFees.toFixed(2)}$` : '0.00$' };
          }
        }),
    };

    if (customModification) {
      data.payments.push({ ...customModification, amount: customModification.amount.toFixed(2) + '$' });
    } else if (invoiceSchedule.values.modifications) {
      const newPayment = invoiceSchedule.values.modifications;
      const amount = newPayment.amount ? (newPayment.hasManagementFees ? `${camp.managementFees.toFixed(2)}$` : `${newPayment.amount.toFixed(2)}$`) : '0.00$';

      data.payments.push({ note: newPayment.note || '', amount });
    }

    const template = Handlebars.compile(source);

    this.preview = template(data);
  }

  private generateMailProducts(): MailProduct[] {
    const mailProducts: MailProduct[] = [
      {
        // eslint-disable-next-line quotes
        title: "Frais d'inscription",
        total: this.camp.registrationFees.toFixed(2) + '$',
        weekRanges: '',
      },
    ];

    Object.values(this.invoiceSchedule.values.schedule.items).forEach((item) => {
      const subtotal = RegistrationService.calculateSubtotal(this.camp, item, this.closedOn);

      let productDescription = '';

      if (item.extras?.hasOwnProperty(ProductExtra.GLUTEN_FREE)) {
        productDescription = 'Menu sans gluten (+1$/jour) \n';
      }

      if (item.extras?.hasOwnProperty(ProductExtra.VEGETARIAN)) {
        productDescription = 'Menu végétarien (Gratuit) \n';
      }

      Object.values(item.weeks)?.forEach((range) => {
        let weekDescription = DateTime.getWeekRangeString(range.days);
        if (range.concentrationId && range.concentrationId != '-1') {
          const concentration = this.concentrations.find((c) => c.id === range.concentrationId);
          if (concentration) {
            weekDescription += ` -- ${concentration.nameFr} (+${concentration.price}$)`;
          }
        }

        productDescription += weekDescription += '\n';

        // if (item.type === ProductType.PART_TIME) {
        //   productDescription += DateTime.getDayRangeString(range.days) + '\n';
        // }
      });

      mailProducts.push({
        title: `${item.child.firstName} ${item.child.lastName} `,
        total: subtotal.toFixed(2) + '$',
        weekRanges: productDescription,
      });
    });

    return mailProducts;
  }
}
