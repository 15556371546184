import { MenuItemDto } from 'api/dto';

export class MenuItem {
  date: string;
  snackAm: string;
  mainCourse: string;
  snackPm: string;

  constructor(menuItem: MenuItemDto) {
    this.date = menuItem.date;
    this.snackAm = menuItem.snackAm;
    this.mainCourse = menuItem.mainCourse;
    this.snackPm = menuItem.snackPm;
  }
}
