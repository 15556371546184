import { Box, Button, LoadingOverlay, Text } from '@mantine/core';
import { StepLayout } from 'layout/step.layout';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { ScheduleFormStep, FullInfoFormStep } from './types';
import { TotalBox } from 'component/payment/total-box';
import { RegistrationService } from 'api/service/registration-service';
import { ScheduleTable } from 'component/schedule/schedule-table';

interface PaymentFormProps {
  form: FullInfoFormStep & ScheduleFormStep;
}

export function Payment({ form }: PaymentFormProps) {
  const { t } = useTranslation();
  const [overlayVisible, setOverlayVisible] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <StepLayout>
      <LoadingOverlay visible={overlayVisible} />
      <Text mb={20} size="lg">
        {t('payment:Summary')}
      </Text>

      <Box mb={20} p={20} sx={{ flex: 0, minHeight: '3.75rem', borderRadius: '.5rem', border: '.0625rem solid #cecece' }}>
        <Text mb={10} color="red" italic>
          {t('payment:Payment')}
        </Text>
        <Text mb={10} color="red" italic>
          {t('payment:EmailConfirmation')}
        </Text>
      </Box>

      {form.values.family.children.map((c) => (
        <Box mb={20}>
          <Box>
            <Text>{`${c.firstName} ${c.lastName}`}</Text>
          </Box>
          <ScheduleTable form={form} child={c} isEditable={false} />
        </Box>
      ))}

      <TotalBox form={form} />
      <Box mt={20} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
        <Button
          loading={isSubmitting}
          size="sm"
          variant="outline"
          color="cyan"
          onClick={async () => {
            setIsSubmitting(true);
            await RegistrationService.register(form, true);
            setIsSubmitting(false);
          }}
        >
          {t('payment:PayMinimum')}
        </Button>
        <Button
          loading={isSubmitting}
          ml={10}
          variant="outline"
          size="sm"
          color="green"
          onClick={async () => {
            setOverlayVisible(true);
            await RegistrationService.register(form);
            setOverlayVisible(false);
          }}
        >
          {t('payment:PayTotal')}
        </Button>
      </Box>
    </StepLayout>
  );
}
