import create from 'zustand';
import { createStore } from 'zustand/vanilla';
import { CampSlice, createCampSlice } from './app.store';

export const campStore = createStore<CampSlice>()((...a) => ({
  ...createCampSlice(...a),
}));

// todo remove deprecated
export const useAppStore = create(campStore);
