import axios from 'config/axios';
import { environment } from 'config/environment';
import { encryptData } from 'utils/crypto';
import { deleteEmptyStringProperties } from 'api/utils/object';
import { showNotification } from '@mantine/notifications';
import cloneDeep from 'lodash/cloneDeep';
import { Modification } from 'utils/invoice';
import { ChildrenChosenProduct } from 'api/models/children-chosen-product';
import { TaxCreditInfo } from 'api/models/tax-credit-info';

interface Payment {
  familyId: string;
  campId: string;
  childrenChosenProducts: ChildrenChosenProduct[];
  taxCreditInfos: TaxCreditInfo[];
  onlyRegistrationFees?: boolean;
}

export async function generatePayment(payment: Payment) {
  const paymentDto = { ...cloneDeep(payment), payments: [] };
  for (const taxCreditInfo of paymentDto.taxCreditInfos) {
    taxCreditInfo.socialNumber = encryptData(taxCreditInfo.socialNumber);
  }
  deleteEmptyStringProperties(paymentDto);

  try {
    const { data } = await axios.post(`/camps/${paymentDto.campId}/registrations`, paymentDto);
    const url = data;
    window.open(url, '_self');

    if (environment.isProd) {
      window.localStorage.removeItem('user-form');
    }
  } catch (error) {
    showNotification({
      title: 'Ooops!',
      // eslint-disable-next-line quotes
      message: "Une erreur s'est produite. Veuillez recommencer ou contacter le support",
      color: 'red',
      sx: {
        padding: 10,
        paddingLeft: 20,
      },
    });

    if (environment.isProd) {
      window.localStorage.removeItem('user-form');
    }
  }
}

export async function addCustomPayment(campId: string, registrationId: string, customPayment: Modification) {
  try {
    await axios.post(`/camps/${campId}/registrations/${registrationId}/payments`, customPayment);
  } catch (error) {
    showNotification({
      title: 'Ooops!',
      // eslint-disable-next-line quotes
      message: "Une erreur s'est produite. Veuillez recommencer ou contacter le support",
      color: 'red',
      sx: {
        padding: 10,
        paddingLeft: 20,
      },
    });
  }
}
