import { TextInput } from '@mantine/core';
import { createDefaultMaskGenerator, useWebMask } from 'react-hook-mask';

const maskGenerator = createDefaultMaskGenerator('999 999 999');

export function SocialSecurityNumberInput({ label, required, value: outerValue, onChange: outerOnChange, ...rest }: any) {
  const mask = useWebMask({
    maskGenerator,
    value: outerValue,
    onChange: outerOnChange,
  });

  return <TextInput {...rest} label={label} required={required} {...mask} />;
}
